import React, { ReactNode } from "react";
import { Card, CardMedia, Typography, Box } from "@mui/material";
import { FinanceModelDto, type FormulaEngine } from "@/entities/FinanceModel";
import defaultImg from "../assets/default.png";
import EyeIcon from "@/assets/icons/eye.svg?react";
import { formatDate } from "@/shared/config/dateFormat";
import { useTheme } from "@mui/material/styles";
import { SmallAvatar } from "@/shared/ui/SmallAvatar/ui/SmallAvatar";
import { ModelBadge } from "@/features/ModelBadges";
import { useFinanceModelPermissions } from "@/entities/FinanceModel";
import TableChartIcon from "@mui/icons-material/TableChart";
import CodeIcon from "@mui/icons-material/Code";

interface ModelCardProps {
  model: FinanceModelDto;
}

export const FinanceModelPreviewList: React.FC<ModelCardProps> = ({
  model,
}) => {
  const theme = useTheme();
  const { viewOnly } = useFinanceModelPermissions(model);

  const iconFormulaEngine: Record<FormulaEngine, string | ReactNode> = {
    python: <CodeIcon width={10} color="primary" />,
    R: <CodeIcon width={10} color="primary" />,
    hypersheet: <TableChartIcon width={10} color="primary" />,
  };

  return (
    <Card
      sx={{
        minWidth: "100%",
        minHeight: "0",
        display: "grid",
        gridTemplateColumns:
          "minmax(0,140px) minmax(0,256px) minmax(0,412px) minmax(0,212px) minmax(0,212px) minmax(0,212px)",
        columnGap: "24px",
        boxShadow: "none",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.01)",
          boxShadow: theme.palette.customShadows.lightBlue,
        },
        cursor: "pointer",
        position: "relative",
        padding: "10px",
        border: "6px",
      }}
    >
      <CardMedia
        component="img"
        height="128"
        image={model?.screenshotImage ? model.screenshotImage : defaultImg}
        alt={model.name}
        sx={{
          marginBottom: 0,
          border: "1px solid #cbdeef",
          borderRadius: "8px",
          maxWidth: "140px",
          maxHeight: "65px",
          minWidth: "140px",
        }}
      />

      <Typography
        gutterBottom
        variant="h5"
        component="div"
        sx={{
          fontWeight: 500,
          fontSize: "16px",
          lineHeight: "150%",
          color: "#000",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          alignSelf: "center",
        }}
      >
        {model.name}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "150%",
          color: "#000",
          mb: 0,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
          alignSelf: "center",
        }}
      >
        {model.description}
      </Typography>
      {model.ownerId && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <SmallAvatar
              avatarSrc={model.User.avatar}
              fullname={model.User.fullname}
            />
          </Box>
          <Box
            sx={{
              alignSelf: "center",
            }}
          >
            <Typography
              sx={{
                ml: 1,
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "150%",
                color: "#000",
                alignSelf: "center",
              }}
            >
              {formatDate(model.updatedAt)}
            </Typography>
          </Box>
        </>
      )}
      {
        <Box
          sx={{
            position: "absolute",
            top: "15px",
            left: "15px",
            width: "25px",
            height: "25px",
            padding: "4px",
            backgroundColor: "white",
            borderRadius: "50%",
            boxShadow:
              "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {model && iconFormulaEngine[model.formulaEngine]}
        </Box>
      }

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <ModelBadge
          model={model}
          customStyles={{ position: "static", transform: "0" }}
        />
        {viewOnly && (
          <Box
            sx={{
              width: "40px",
              height: "40px",
              padding: "4px",
              backgroundColor: "white",
              borderRadius: "50%",
            }}
          >
            <EyeIcon />
          </Box>
        )}
      </Box>
    </Card>
  );
};
