import { createQueryKeys } from "@lukemorales/query-key-factory";

const financeModelInputKeys = createQueryKeys("finance-model", {
  inputs: (financeModelId: number) => [financeModelId],
  tabs: (financeModelId: number) => [financeModelId],
  outputs: (financeModelId: number, tabName: string) => [
    financeModelId,
    tabName,
  ],
});

export { financeModelInputKeys };
