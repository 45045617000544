import { FC } from "react";
import { Box, Button, Typography, Avatar } from "@mui/material";
import { UserAvatar } from "@/features/User/ui/UserAvatar";
import { styled } from "@mui/material/styles";

import {
  AVATAR_ACCEPTED_FILE_TYPES,
  AVATAR_MAX_FILE_SIZE_IN_MB,
} from "../const";
import UserAvatarUploadContainer from "./UserAvatarUploadContainer";

interface UserAvatarUpdateProps {
  avatarUrl?: string;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  backgroundColor: theme.palette.customColors.colorLightBlue200,
  "& .MuiAvatar-fallback": {
    margin: 0,
  },
}));

const UserAvatarUpdate: FC<UserAvatarUpdateProps> = ({ avatarUrl }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
      {avatarUrl ? (
        <UserAvatar width={100} height={100} src={avatarUrl} />
      ) : (
        <StyledAvatar />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Typography variant="h6">Обновление аватара</Typography>

        <UserAvatarUploadContainer
          trigger={<Button variant="lightBlueV300">Выбрать файл</Button>}
        />

        <Typography variant="caption" sx={{ maxWidth: 300 }}>
          Максимальный размер файла: {AVATAR_MAX_FILE_SIZE_IN_MB} MB.
          Разрешенные форматы: {AVATAR_ACCEPTED_FILE_TYPES}
        </Typography>
      </Box>
    </Box>
  );
};

export default UserAvatarUpdate;
