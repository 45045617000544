import { api } from "@/shared/config/network";
import { FinanceModelOutput } from "../model";

export const fetchOutputMetaInfo = async (
  financeModelId: number,
  tabName: string,
  purpose: string
) => {
  const { data } = await api.get<FinanceModelOutput[]>(
    `/data-registry/finance-models/${financeModelId}/outputs/${tabName}`,
    {
      params: {
        purpose,
      },
    }
  );
  return data;
};
