import { FC } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { Input } from "@/shared/ui/Input/Input";
import { useChangePassword } from "@/entities/User/queries/useChangePassword";

interface ChangePasswordFormData {
  currentPassword: string;
  newPassword: string;
}

export const ChangePassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    reset,
  } = useForm<ChangePasswordFormData>({
    mode: "onChange",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
    },
  });

  const { mutate: saveNewPassword } = useChangePassword();

  const onSubmit = (data: ChangePasswordFormData) => {
    saveNewPassword(data, {
      onSuccess: () => reset(),
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Typography variant="h6" component="div">
        Изменение пароля
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Input
            placeholder="Текущий пароль"
            fullWidth
            {...register("currentPassword", { required: "Введите текущий пароль" })}
            error={!!errors.currentPassword}
            helperText={errors.currentPassword?.message}
          />

          <Input
            placeholder="Новый пароль"
            fullWidth
            {...register("newPassword", { required: "Введите новый пароль" })}
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
          />

          <Button
            color="primary"
            variant="lightBlueV300"
            type="submit"
            sx={{ width: "200px" }}
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Сохраняем..." : "Изменить пароль"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
