import { AxiosError } from "axios";
import { useQuery, useQueries } from "@tanstack/react-query";
import { ExternalUser } from "../model";
import { getExternalUserInfo } from "../api";
import { userKeys } from "../const";

export const useExternalUser = (id: string | number) => {
  return useQuery<ExternalUser, AxiosError>({
    queryKey: userKeys.externalUsers(id).queryKey,
    queryFn: () => getExternalUserInfo(id),
    enabled: !!id,
  });
};

export const useExternalUsers = (ids: (string | number)[]) => {
  const queries = useQueries({
    queries: ids.map((id) => ({
      queryKey: userKeys.externalUsers(id).queryKey,
      queryFn: () => getExternalUserInfo(id),
      enabled: !!id,
    })),
  });

  const isLoading = queries.some((query) => query.isLoading);
  const isError = queries.some((query) => query.isError);
  const data = queries
    .map((query) => query.data)
    .filter(Boolean) as ExternalUser[];

  return { data, isLoading, isError };
};
