export type LSCelldataType = {
  c: number;
  r: number;
  v: {
    f?: string;
    m: string;
    v: string | number | null;
    ct?: {
      t: string;
      fa: string;
    };
  } | null;
};

export function convertCellDataToSimpleValue(celldata: LSCelldataType[]) {
  const simpleValues: (string | number | null | undefined)[][] = [[]];

  celldata?.forEach((cell) => {
    const { r: row, c: column, v } = cell || {};
    const { m } = v || {};

    if (!simpleValues[row]) {
      simpleValues[row] = [];
    }
    simpleValues[row][column] = m;
  });

  const rowsLength = simpleValues.length;

  for (let ii = 0; ii < rowsLength; ii++) {
    if (!simpleValues[ii]) {
      simpleValues[ii] = new Array(rowsLength).fill(undefined);
    }
  }

  return fillMatrix(simpleValues);
}

function fillMatrix<T>(arr: (T | undefined | null)[][], filler = ""): T[][] {
  // Определяем максимальную длину вложенных массивов
  const maxLength = Math.max(...arr.map((row) => row.length));

  // Заполняем каждый массив недостающими элементами
  return arr.map((row) => [
    ...row,
    ...Array(maxLength - row.length).fill(filler),
  ]);
}
