import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Box, Grid, Typography, Tabs, Tab } from "@mui/material";
import Header from "@/widgets/Header";
import { BreadcrumbsNav } from "@/widgets/BreadcrumbsNav";
import { routePaths } from "@/shared/config/routePaths";
import { Link, useParams } from "react-router-dom";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";
import { useUserMenuGroups } from "@/features/User";
import { useFinanceModelByGridkey } from "@/entities/FinanceModel/queries/useFinanceModelByGridkey";
import DeleteIcon from "@/assets/icons/delete.svg?react";
import styles from "./ModelInfoPage.module.css";
import { ModelBadge } from "@/features/ModelBadges";
import { Button } from "@mui/material";
import { FinanceModelProfileCard } from "@/entities/FinanceModelProfile";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { SmallAvatar } from "@/shared/ui/SmallAvatar";
import { useFinanceModelInputsAll } from "@/entities/FinanceModelInput";
import HotTable, { HotTableClass } from "@handsontable/react";
import { useHotTable } from "@/shared/hooks/useHotTable";
import { useVersionById } from "@/entities/Version/queries";
import { useRecalculateModel } from "@/entities/FinanceModelInput/hooks/useRecalculateModel";
import { useFinanceModelOutputMetaInfo } from "@/entities/FinanceModelInput/queries/useFinanceModelOutputsMeta";
import {
  ExternalUserWithPermissions,
  ExternalUser,
  useExternalUsers,
} from "@/entities/User";
import globalImg from "@/assets/images/global.png";
import { SquareButtonWithIcon } from "@/shared/ui/SquareBtnWithIcon";
import NotificationIcon from "@/assets/icons/notification.svg?react";
import { FinanceModelEditModal } from "@/features/FinanceModel/FinanceModelEditModal";

interface RouteParams {
  gridkey: string;
  version: string;
}

const ModelInfoPage: FC = () => {
  const { data: user } = useUserProfile();
  const userMenuGroups = useUserMenuGroups();
  const { gridkey: gridkeyEncoded, version: versionFromParams } =
    useParams<RouteParams>();
  const { data: currentModel, isLoading: isFinanceModelLoading } =
    useFinanceModelByGridkey(gridkeyEncoded);

  const hotRef = useRef<HotTableClass>(null);

  const [version, setVersion] = useState<number | undefined>(
    versionFromParams ? Number(versionFromParams) : undefined
  );

  const [purposeOutput, setPurposeOutput] = useState<"biandforms" | "">("");
  const [outputTabName, setOutputTabName] = useState<string | null>(null);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  const [sharedUsersData, setSharedUsersData] = useState<
    ExternalUserWithPermissions[]
  >([]);

  const userIds =
    currentModel?.shares
      .map((share) => share.sharedWithUserId)
      .filter((id) => id !== null) || [];

  const {
    data: externalUsers,
    isLoading: loadingSharedUsersData,
    isError: errorSharedUsersData,
  } = useExternalUsers(userIds);

  useEffect(() => {
    const fetchSharedUsersData = async () => {
      try {
        if (!currentModel) {
          return;
        }

        const userIds = currentModel.shares
          .map((share) => share.sharedWithUserId)
          .filter((id) => id !== null);

        let usersData: ExternalUser[] = [];
        if (userIds.length > 0) {
          usersData = externalUsers;
        }

        const processedUsersData = usersData.map((data) => ({
          ...data,
          permissions:
            currentModel.shares.find(
              (share) => share.sharedWithUserId === data.id
            )?.permissions || "view",
        }));

        const globalUsers = currentModel.shares
          .filter((share) => share.sharedWithUserId === null)
          .map((share, index) => ({
            id: `global_${index}`,
            fullname: "Глобальный пользователь",
            avatar: globalImg,
            email: null,
            position: "",
            permissions: share.permissions,
          }));

        const combinedData = [...processedUsersData, ...globalUsers];
        setSharedUsersData(combinedData);
      } catch (error) {
        console.error(error);
      }
    };

    if (currentModel && currentModel.shares) {
      fetchSharedUsersData(); // Вызов функции загрузки
    }
  }, [currentModel]);

  const financeModelId = currentModel?.id;

  const financeModelInputsAllQuery = useFinanceModelInputsAll({
    financeModelId,
    enabled: Boolean(financeModelId),
    includeBiTabs: true,
  });

  const {
    data: outputMetaInfo,
    refetch: outputMetaInfoRefetch,
    isFetching: isOutputMetaInfoFetching,
  } = useFinanceModelOutputMetaInfo({
    financeModelId: financeModelId || 0,
    tabName: outputTabName || "Итоги",
    purpose: purposeOutput,
  });

  const {
    tableInputs,
    isInputsLoading,
    mapInputData,

    handleAfterChange,
    handleAfterRender,
    updateSettings,
    updatedCells,

    setSelectedSheet,
    selectedSheet,
    selectedType,
    selectedSheetData,
    values,
  } = useHotTable({ hotRef, inputsQuery: financeModelInputsAllQuery });

  const { data: versionData, isLoading: isVersionDataLoading } = useVersionById(
    +version!
  );

  const checkIfIsOutputTab = (name: string) => {
    const foundTab = tableInputs.find(({ tabName }) => tabName === name);
    return foundTab?.type === "output" || foundTab?.type === "biOutputTabs";
  };

  const isOutputTabSelected: boolean = selectedSheet
    ? checkIfIsOutputTab(selectedSheet)
    : false;

  const {
    name = "",
    description,
    backupsToKeep,
    backupFrequency,
    createdAt,
    updatedAt,
    User,
    formulaEngine,
  } = currentModel || {};

  const { isRecalculateModelPending, calculatedOutputData, mapOutputData } =
    useRecalculateModel({
      financeModelId,
      updatedCells,
      selectedSheet,
    });

  const allSheets = useMemo(() => {
    return tableInputs.filter(
      (v) =>
        v.type === "input" ||
        v.type === "output" ||
        v.type === "biOutput" ||
        v.type === "biInput"
    );
  }, [tableInputs]);

  useEffect(() => {
    if (!selectedSheet && allSheets.length > 0) {
      setSelectedSheet(allSheets[0].tabName);
    }
  }, [allSheets, selectedSheet, setSelectedSheet]);

  useEffect(() => {
    const v = versionFromParams ? Number(versionFromParams) : undefined;
    setVersion(v);
  }, [versionFromParams]);

  useEffect(() => {
    if (!financeModelId || !selectedSheet) {
      setOutputTabName(null);
    }
    if (selectedType?.toLowerCase()?.includes("output") && selectedSheet) {
      if (selectedType?.toLowerCase()?.includes("bi")) {
        setOutputTabName(selectedSheet.replace("bi_", ""));
        setPurposeOutput("biandforms");
      } else {
        setOutputTabName(selectedSheet);
        setPurposeOutput("");
      }
    } else {
      setOutputTabName(null);
    }
  }, [selectedSheet, financeModelId, outputMetaInfoRefetch]);

  useEffect(() => {
    if (outputTabName) {
      outputMetaInfoRefetch();
    }
  }, [outputTabName, purposeOutput, outputMetaInfoRefetch]);

  const tableData = useMemo(() => {
    if (version) {
      if (!isOutputTabSelected) {
        const inputTableData = mapInputData(
          versionData?.inputData.filter(
            (input) => input.tabName === selectedSheet
          ) ?? [],
          selectedSheet ? updatedCells[selectedSheet] : undefined
        );

        return inputTableData;
      }
    }

    return isOutputTabSelected ? calculatedOutputData : values;
  }, [
    version,
    selectedSheet,
    isOutputTabSelected,
    calculatedOutputData,
    values,
    mapOutputData,
    versionData,
    mapInputData,
    updatedCells,
  ]);

  useEffect(() => {
    updateSettings(selectedSheet, tableData, updatedCells, isOutputTabSelected);
  }, [
    selectedSheet,
    updateSettings,
    updatedCells,
    isOutputTabSelected,
    tableData,
  ]);

  const handleAfterInit = () => {
    updateSettings(selectedSheet, tableData, updatedCells, isOutputTabSelected);
  };

  const isReadonlyData = Boolean(version);

  const handleChangeSheet = (
    _event: React.SyntheticEvent,
    sheetName: string
  ) => {
    setSelectedSheet(sheetName);
  };

  const modelMenuGroups = useMemo(() => {
    return [
      {
        items: [
          {
            label: "Удалить модель",
            extraClasses: styles.trash,
            icon: <DeleteIcon />,
            onClick: () => console.log("delete model"),
          },
        ],
      },
    ];
  }, []);

  const handleEditClick = () => {
    setIsOpenEditModal(true);
  };

  // console.group("ModelInfoPage");
  // console.debug(outputMetaInfo, "outputMetaInfo");
  // console.debug(financeModelInputsAllQuery.data, "financeModelInputsAllQuery");
  // console.debug(selectedSheet, "selectedSheet");
  // console.debug(selectedSheetData, "selectedSheetData");
  // console.debug(selectedType, "selectedType");
  // console.debug(outputTabName, "outputTabName");
  // console.groupEnd();

  return (
    <Box
      sx={{
        paddingTop: "var(--headerHeight)",
        maxWidth: "1632px",
        margin: "0 auto",
        height: "100vh",
        display: "grid",
        width: "100%",
        columnGap: 0,
        rowGap: 0,
      }}
    >
      <Header
        leftSlot={
          <Box sx={{ marginLeft: "48px" }}>
            <BreadcrumbsNav
              backLink={`/grid/${gridkeyEncoded}`}
              breadcrumbs={[
                {
                  label: `${currentModel?.id}`,
                  path: `/grid/${gridkeyEncoded}`,
                },
                { label: name, path: `/grid/${gridkeyEncoded}` },
              ]}
              currentPage={{
                label: "Информация",
              }}
            />
          </Box>
        }
        UserMenuGroups={{
          menuGroups: userMenuGroups,
          alignment: "right",
          profileImage: user?.avatar,
        }}
        modelMenuProps={{
          menuGroups: modelMenuGroups,
          alignment: "right",
        }}
        rightSlot={
          <SquareButtonWithIcon
            onClick={() => {}}
            endIcon={<NotificationIcon width={17} height={18} />}
          />
        }
      />
      {isFinanceModelLoading ? (
        <div className={styles.loader}>Загрузка...</div>
      ) : (
        <Box
          sx={{
            padding: "24px",
          }}
        >
          <Box
            my={4}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h5"
                  component="h1"
                  sx={{ fontWeight: 700, marginRight: 2 }}
                >
                  {name}
                </Typography>
                {currentModel && (
                  <ModelBadge
                    model={currentModel}
                    customStyles={{ position: "static", transform: "0" }}
                  />
                )}
              </Box>

              <Typography variant="body2" sx={{ marginTop: "8px" }}>
                {description}
              </Typography>

              {formulaEngine === "hypersheet" && (
                <Typography sx={{ marginTop: "8px" }}>
                  FormulaEngine - {formulaEngine}
                </Typography>
              )}
            </Box>

            <Button
              variant="outlined"
              color="primary"
              onClick={handleEditClick}
            >
              Редактировать
            </Button>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  flexDirection: "column",
                }}
              >
                <Box sx={{ display: "flex", gap: "16px" }}>
                  <FinanceModelProfileCard onClick={() => {}}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        Резервные копии
                      </Typography>

                      <Link
                        to={`${routePaths.grid}/${gridkeyEncoded}/backups?fromPage=${routePaths.modelInfo}`}
                      >
                        <OpenInNewIcon />
                      </Link>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ color: "#727272", fontSize: "14px" }}>
                          Количество копий
                        </Typography>

                        <Typography sx={{ fontWeight: 500 }}>
                          {backupsToKeep}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ color: "#727272", fontSize: "14px" }}>
                          Сохранение резервных копий
                        </Typography>

                        <Typography sx={{ fontWeight: 500 }}>
                          {backupFrequency}
                        </Typography>
                      </Box>
                    </Box>
                  </FinanceModelProfileCard>
                  <FinanceModelProfileCard onClick={() => {}}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <Typography sx={{ fontWeight: 500 }}>Версии</Typography>
                      <Link
                        to={`${routePaths.grid}/${gridkeyEncoded}/versions?fromPage=${routePaths.modelInfo}`}
                      >
                        {" "}
                        <OpenInNewIcon />
                      </Link>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "16px",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ color: "#727272", fontSize: "14px" }}>
                          Количество сохраненных версий перерасчета
                        </Typography>

                        <Typography sx={{ fontWeight: 500 }}>{3}</Typography>
                      </Box>
                    </Box>
                  </FinanceModelProfileCard>
                </Box>
                <Box>
                  {isInputsLoading ||
                  isVersionDataLoading ||
                  isOutputMetaInfoFetching ||
                  isFinanceModelLoading ? (
                    <div className={styles.loader}>Загрузка...</div>
                  ) : (
                    selectedSheet && (
                      <div className={styles.tableContainer}>
                        <Box
                          className={styles.table}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            maxWidth: "1070px",
                          }}
                        >
                          <Tabs
                            value={selectedSheet}
                            onChange={handleChangeSheet}
                            variant="scrollable"
                            scrollButtons={true}
                          >
                            {allSheets.map((sheet) => (
                              <Tab
                                key={sheet.tabName}
                                label={sheet.tabName}
                                value={sheet.tabName}
                                sx={
                                  checkIfIsOutputTab(sheet.tabName)
                                    ? {
                                        backgroundColor: "#F0CF2229",
                                      }
                                    : undefined
                                }
                              />
                            ))}
                          </Tabs>
                        </Box>
                        {outputMetaInfo && outputTabName ? (
                          <Box>
                            <div>ID: {outputMetaInfo[0].id}</div>
                            <div>Tab Name: {outputMetaInfo[0].tabName}</div>
                            <div>Table Name: {outputMetaInfo[0].tableName}</div>
                            <div>Sheet Name: {outputMetaInfo[0].sheetName}</div>
                            <div>Purpose: {outputMetaInfo[0].purpose}</div>
                            <div>
                              Created At:{" "}
                              {new Date(
                                outputMetaInfo[0].createdAt
                              ).toLocaleString()}
                            </div>
                            <div>
                              Updated At:{" "}
                              {new Date(
                                outputMetaInfo[0].updatedAt
                              ).toLocaleString()}
                            </div>
                          </Box>
                        ) : (
                          selectedSheetData && (
                            <div style={{ maxWidth: "1070px" }}>
                              <HotTable
                                className={styles.table}
                                ref={hotRef}
                                data={tableData}
                                height="auto"
                                width="auto"
                                autoWrapRow
                                autoWrapCol
                                autoColumnSize
                                stretchH="none"
                                licenseKey="non-commercial-and-evaluation"
                                afterChange={handleAfterChange}
                                afterRender={handleAfterRender}
                                afterInit={handleAfterInit}
                                disableVisualSelection={
                                  isRecalculateModelPending || isInputsLoading
                                }
                                readOnly={
                                  isReadonlyData ||
                                  isRecalculateModelPending ||
                                  isInputsLoading
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    )
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box
                sx={{
                  height: "100%",
                  gap: "16px",
                  display: "grid",
                }}
              >
                <FinanceModelProfileCard onClick={() => {}}>
                  <Box
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      Общая информация
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: "50px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ color: "#727272", fontSize: "14px" }}>
                        Дата создания
                      </Typography>

                      <Typography sx={{ fontWeight: 500 }}>
                        {createdAt}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ color: "#727272", fontSize: "14px" }}>
                        Автор модели
                      </Typography>

                      <SmallAvatar
                        avatarSrc={User?.avatar || ""}
                        fullname={User?.fullname || ""}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ color: "#727272", fontSize: "14px" }}>
                        Дата редактирования
                      </Typography>

                      <Typography sx={{ fontWeight: 500 }}>
                        {updatedAt}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      <Typography sx={{ color: "#727272", fontSize: "14px" }}>
                        Последний редактор
                      </Typography>

                      <SmallAvatar
                        avatarSrc={User?.avatar || ""}
                        fullname={User?.fullname || ""}
                      />
                    </Box>
                  </Box>
                </FinanceModelProfileCard>

                <FinanceModelProfileCard onClick={() => {}}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "16px",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography sx={{ fontWeight: 500 }}>
                      Совместный доступ
                    </Typography>
                    <Link
                      to={`${routePaths.grid}/${gridkeyEncoded}/sharemodal?fromPage=${routePaths.modelInfo}`}
                    >
                      <OpenInNewIcon />
                    </Link>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {loadingSharedUsersData && (
                      <Typography>Загрузка...</Typography>
                    )}
                    {errorSharedUsersData && (
                      <Typography>Ошибка загрузки пользователей</Typography>
                    )}
                    {!loadingSharedUsersData &&
                      sharedUsersData.map((user, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "12px",
                            }}
                          >
                            <SmallAvatar
                              avatarSrc={user.avatar}
                              fullname={user.fullname}
                            />
                          </Box>
                          <Typography
                            sx={{ color: "#727272", fontSize: "14px" }}
                          >
                            {user.permissions === "edit"
                              ? "Права редактирования"
                              : "Права просмотра"}
                          </Typography>
                        </Box>
                      ))}
                  </Box>
                </FinanceModelProfileCard>
              </Box>
            </Grid>
          </Grid>

          {currentModel && financeModelId && (
            <FinanceModelEditModal
              isOpen={isOpenEditModal}
              handleClose={() => {
                setIsOpenEditModal(false);
              }}
              modelId={financeModelId}
              defaultName={currentModel?.name}
              defaultDescription={currentModel?.description}
              gridKey={gridkeyEncoded}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default ModelInfoPage;
