import { useQuery } from "@tanstack/react-query";

import { fetchDashboards } from "../api/fetch";
import { dashboardKeys } from "../const";

interface UseDashboardsParams {
  financeModelId: number;
}

const useDashboards = ({ financeModelId }: UseDashboardsParams) => {
  return useQuery({
    queryKey: dashboardKeys.list(financeModelId).queryKey,
    queryFn: () => fetchDashboards({ financeModelId }),
  });
};

export { useDashboards };
