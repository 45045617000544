import { type FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Restore from "@mui/icons-material/Restore";
import { Controller, useForm, SubmitHandler } from "react-hook-form";

import {
  useBackups,
  useBackupRestore,
  useBackupCreate,
} from "@/entities/Backup";

interface BackupModalProps {
  financeModelId: number;
  open: boolean;
  handleClose: () => void;
}

interface BackupFormData {
  description: string;
  versionName: string;
}

const BackupModal: FC<BackupModalProps> = ({
  financeModelId,
  open,
  handleClose,
}) => {
  const { data: backups = [] } = useBackups({ financeModelId });
  const { mutate: createBackup } = useBackupCreate();
  const { mutate: restoreBackup } = useBackupRestore();

  const { control, handleSubmit } = useForm<BackupFormData>({
    defaultValues: {
      description: "",
      versionName: new Date().toDateString(),
    },
  });

  const onSubmit: SubmitHandler<BackupFormData> = (data) => {
    createBackup({ financeModelId, ...data });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Backup</DialogTitle>
      <DialogContent>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {backups.length &&
            backups.map((b, i: number) => (
              <ListItem
                key={b.id}
                sx={{
                  paddingLeft: "24px",
                  paddingRight: "24px",
                  margin: "0 -24px",
                  width: "calc(100% + 48px)",
                  background: (theme) =>
                    i % 2 !== 0 ? theme.palette.grey[100] : undefined,
                }}
              >
                <ListItemText
                  primary={`#${b.id}. ${b.versionName}`}
                  secondary={b.description}
                />
                <Button
                  size="large"
                  startIcon={<Restore />}
                  onClick={() => {
                    restoreBackup(
                      { versionId: b.id.toString() },
                      {
                        onSuccess: () => {
                          window.location.reload();
                        },
                      },
                    );
                  }}
                >
                  Restore
                </Button>
              </ListItem>
            ))}
        </List>
      </DialogContent>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <DialogContentText>Save a new backup.</DialogContentText>

          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Description"
                fullWidth
                margin="normal"
              />
            )}
          />
          <Controller
            name="versionName"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Version Name"
                fullWidth
                margin="normal"
              />
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Close Modal
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disableElevation
          >
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export { BackupModal };
