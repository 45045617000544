import { useState, type MouseEvent, FC } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import RestoreIcon from "@/assets/icons/restore.svg?react";
import { UniversalMenu } from "@/shared/ui/UniversalMenu";

const StyledButton = styled(Button)(() => ({
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  height: "32px"
}));

const StyledArrowButton = styled(Button)(({ theme }) => ({
  borderRadius: "4px",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  color: `${theme.palette.customColors.colorLightBlue200}`,
  height: "32px",
  border: `1px solid currentColor`,
  "&:hover": {
    borderColor: `${theme.palette.customColors.colorLightBlue200}`
  },
  borderLeft: "none",
  minWidth: "unset",
  paddingLeft: "4px",
  paddingRight: "4px",
}));

interface Props {
  onRestoreToCurrentVersion: VoidFunction;
  onRestoreToNewVersion: VoidFunction;
}

const RestoreButtonMenu: FC<Props> = ({ onRestoreToCurrentVersion, onRestoreToNewVersion }) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <StyledButton
        variant="outlineV1"
        size="small"
        startIcon={<RestoreIcon color={theme.palette.customColors.colorBrandeisBlue} />}
        onClick={onRestoreToCurrentVersion}
      >
        Восстановить в текущую
      </StyledButton>

      <StyledArrowButton
        variant="outlineV1"
        size="small"
        onClick={handleClick}
      >
        <ExpandMoreIcon />
      </StyledArrowButton>

      <UniversalMenu
        groups={[
          {
            items: [{
              label: "Восстановить в новую",
              icon: <RestoreIcon color={theme.palette.customColors.colorBrandeisBlue} />,
              onClick: () => {
                onRestoreToNewVersion();
                handleClose();
              }
            }]
          }
        ]}
        triggerElement={null}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </Box>
  );
};

export { RestoreButtonMenu };
