import React from "react";
import { Box } from "@mui/material";
import { useSprings, animated, ControllerUpdate } from "@react-spring/web";
import { paths, getRandomColor } from "../config";
import { useSlideInFromTop } from "@/shared/animations";

interface SpinnerProps {
  fullscreen?: boolean;
  animateDuration?: number;
  withBg?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  fullscreen = false,
  animateDuration = 100,
  withBg = false,
}) => {
  const scaleInAnimation = useSlideInFromTop(300);

  const svgAnimations = useSprings(
    paths.length,
    paths.map(() => ({
      from: { opacity: 0.5, fill: getRandomColor() },
      to: async (next: (props?: ControllerUpdate) => void) => {
        while (true) {
          await next({ opacity: 1, fill: getRandomColor() });
          await next({ opacity: 0.5, fill: getRandomColor() });
        }
      },
      config: { duration: animateDuration },
      reset: true,
      immediate: true,
    }))
  );

  return (
    <Box
      sx={{
        position: fullscreen ? "fixed" : "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: fullscreen ? "100vw" : "100%",
        height: fullscreen ? "100vh" : "100%",
        backgroundColor: fullscreen
          ? "rgba(255, 255, 255, 0.7)"
          : withBg
          ? "rgba(255, 255, 255, 0.7)"
          : "transparent",
        zIndex: fullscreen ? "infinity" : 1,
        userSelect: "none",
      }}
    >
      <animated.div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          ...scaleInAnimation,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="28"
          viewBox="0 0 40 40"
          fill="none"
        >
          {paths.map((path, index) => {
            const { d } = path;
            const animation = svgAnimations[index];
            return (
              <animated.path
                key={index}
                d={d}
                style={{
                  ...animation,
                  fill: animation.fill,
                }}
              />
            );
          })}
        </svg>
      </animated.div>
    </Box>
  );
};

export default Spinner;
