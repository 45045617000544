import { memo } from "react";
import { Handle, Position } from "@xyflow/react";
import { HandleData, NodeData } from "@/entities/Scenarios/model";

import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";

import "./table-node.css";

const TableNode = memo(({ data }: { data: NodeData }) => {
  console.log("data.outputs:", data.outputs);
  return (
    <div className="table-node">
      <div className="handles targets">
        {data.inputs?.map((handle: HandleData) => (
          <Handle
            key={handle.id}
            id={handle.id}
            type="target"
            position={Position.Left}
          />
        ))}
      </div>
      <div className="table-node__header">
        <TableChartOutlinedIcon className="table-node__code-icon" />
        <div className="table-node__title">Таблица</div>
      </div>
      <div className="table-node__text">{data?.label}</div>
      <div className="handles sources">
        {data.outputs?.map((handle: HandleData) => (
          <Handle
            key={handle.id}
            id={handle.id}
            type="source"
            position={Position.Right}
          />
        ))}
      </div>
    </div>
  );
});

export default TableNode;
