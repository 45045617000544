import React from "react";
import { Box } from "@mui/material";

interface BadgeProps {
  text: string;
  textColor?: string;
  backgroundColor?: string;
  customStyles?: React.CSSProperties;
}

export const Badge = ({
  text,
  textColor = "white",
  backgroundColor = "#056609",
  customStyles = {},
}: BadgeProps) => {
  return (
    <Box
      sx={{
        alignSelf: "center",
        color: textColor,
        padding: "2px 8px",
        borderRadius: "6px",
        backgroundColor: backgroundColor,
        width: "fit-content",
        position: "absolute",
        bottom: "6px",
        left: "7px",
        transform: "translateY(-100%)",
        ...customStyles,
      }}
    >
      {text}
    </Box>
  );
};
