import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateSettingsBackups } from "../api";

const useBackupUpdateSettings = (gridKey: string) => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: updateSettingsBackups,
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: ["financeModel", gridKey],
      });
    },
  });
};

export { useBackupUpdateSettings };
