import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useCreateScenario } from "@/entities/Scenarios/queries/useCreateScenario";
import {
  type ScenarioDTO,
  type CreateScenarioRequest,
} from "@/entities/Scenarios/model";

export const useScenarioCreateModal = (
  onSuccess?: (scenario: ScenarioDTO) => void,
  onError?: (error: any) => void
) => {
  const { mutateAsync: createScenario } = useCreateScenario();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = useForm<CreateScenarioRequest>({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      scenario_json: {},
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset({ scenario_json: {} });
    setOpen(false);
  };

  const handleSubmit: SubmitHandler<CreateScenarioRequest> = async (data) => {
    try {
      const res = await createScenario(data);
      handleClose();
      if (onSuccess) {
        onSuccess(res);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  return {
    open,
    handleOpen,
    handleClose,
    handleSubmit: handleFormSubmit(handleSubmit),
    register,
    errors,
    isSubmitting,
    setValue,
    isValid,
    reset,
  };
};
