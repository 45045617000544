import { FC, useEffect, useCallback } from "react";
import {
  ReactFlow,
  Controls,
  MiniMap,
  Background,
  BackgroundVariant,
  addEdge,
  useNodesState,
  useEdgesState,
} from "@xyflow/react";
import { ScenarioDetail } from "@/entities/Scenarios/model";
import "@xyflow/react/dist/style.css";
import TableNode from "./TableNode";
import ScriptNode from "./ScriptNode";
import "./common.css";

const nodeTypes = {
  tableNode: TableNode,
  scriptNode: ScriptNode,
};

interface ReactFlowWrapperProps {
  scenario: ScenarioDetail;
}

export const ReactFlowWrapper: FC<ReactFlowWrapperProps> = ({ scenario }) => {
  const snapGrid: [number, number] = [20, 20];
  const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

  const initialNodes = JSON.parse(scenario.scenario_json).nodes;
  const initialEdges = JSON.parse(scenario.scenario_json).edges;

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  useEffect(() => {
    setNodes(initialNodes);
    console.log(initialNodes);
    setEdges(initialEdges);
    console.log(initialEdges);
  }, [scenario]);

  const onConnect = useCallback(
    (params: any) =>
      setEdges((eds) => addEdge({ ...params, animated: true }, eds)),
    []
  );

  return (
    <div style={{ width: "100vw", height: "90vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        snapGrid={snapGrid}
        defaultViewport={defaultViewport}
        fitView
        attributionPosition="bottom-left"
      >
        <Controls />
        <MiniMap />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      </ReactFlow>
    </div>
  );
};
