import { api } from "@/shared/config/network";

import type { LabelDto } from "../model";

const fetchLabels = async () => {
  const response = await api.get<LabelDto[]>(`/labels`);
  return response.data;
};

export { fetchLabels };
