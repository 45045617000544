import React, { useRef, useContext, useCallback, useEffect } from "react";
import findLastIndex from "lodash/findLastIndex";
import { LuckysheetDataContext } from "@/contexts/LuckysheetDataContext"; // Adjusted import path
import { LuckysheetOptions } from "@/shared/interfaces/luckysheet.interface";
import merge from "lodash/merge";
import { FormulaEngine } from "@/entities/FinanceModel";

type QueueItem = {
  type: "create" | "destroy";
  params?: {
    gridkey?: string;
    formulaEngine?: FormulaEngine;
    data?: any;
    // Different luckysheet create options
    options?: LuckysheetOptions;
  };
};

interface LuckysheetWindow extends Window {
  luckysheet: any;
}

declare const window: LuckysheetWindow;

/**
 * @description Class created to manage switching of Luckysheet workbooks. It doesn't work correctly if you try to run a lot of create commands with different gridkeys consecutively. Maybe some kind of event emitter with a correct _processNext command on each event could do the job.
 *
 * @class LuckysheetManager
 */
class LuckysheetManager {
  private _processing = false;
  private _gridkey: string | null = null;
  private _queue: QueueItem[] = [];

  private _processNext(): void {
    if (this._queue.length === 0 || this._processing) {
      return;
    }

    const lastDestroyIndex = findLastIndex(
      this._queue,
      (v) => v.type === "destroy"
    );

    if (lastDestroyIndex > 0) {
      this._queue = this._queue.slice(lastDestroyIndex);
    }

    const nextTask = this._queue.shift();
    if (!nextTask) return;

    const { type, params } = nextTask;
    this._processing = true;

    switch (type) {
      case "create":
        if (params?.gridkey) {
          this._lscreate({
            gridkey: params.gridkey,
            formulaEngine: params.formulaEngine,
            data: params?.data,
            options: params?.options,
          });
        }
        break;
      case "destroy":
        this._lsdestroy();
        break;
      default:
        this._processing = false;
        this._processNext();
    }
  }

  private _lscreate = ({
    gridkey,
    formulaEngine,
    data,
    options,
  }: {
    gridkey: string;
    formulaEngine?: FormulaEngine;
    data?: any;
    options?: LuckysheetOptions;
  }): void => {
    this._gridkey = gridkey;
    console.log("trying to create", this._props);
    console.log("LuckysheetManager._lscreate called with data:", data);
    console.log("Type of data:", typeof data);
    if (Array.isArray(data)) {
      console.log("Data is an array with length:", data.length);
      console.log("First sheet object:", data[0]);
    } else {
      console.log("Data is not an array.");
    }

    const defaultOptions = {
      container: "luckysheet",
      title: "Luckysheet for CFO",
      lang: "ru",
      hook: {
        workbookCreateAfter: () => {
          this._processing = false;
          this._processNext();
        },
      },
      showinfobar: false,
      updateUrl: `wss://${window.location.host}/luckysheet/websocket/luckysheet`, //fm-admin.cfoservices.ru ${window.location.host}
      updateImageUrl: `${window.location.origin}/luckysheet/api/updateImg`,
      allowUpdate: true,
      loadUrl: `${window.location.origin}/luckysheet/api/load`,
      loadSheetUrl: `${window.location.origin}/luckysheet/api/loadsheet`,
      forceCalculation: true,
      gridKey: gridkey,
      data: data || undefined, // Pass the data here
      formulaEngine,
    };

    let calculatedOptions = {};
    if (options) {
      if (data) {
        // Case we view backup
        calculatedOptions = {
          showinfobar: options.showinfobar,
          showtoolbar: options.showtoolbar,
          allowEdit: options.allowEdit,
          updateUrl: "",
          updateImageUrl: "",
          allowUpdate: false,
          loadUrl: "",
          loadSheetUrl: "",
        };
      } else {
        // Case we view shared model
        calculatedOptions = {
          showinfobar: options.showinfobar,
          showtoolbar: options.showtoolbar,
          allowEdit: options.allowEdit,
          updateUrl: "",
          updateImageUrl: "",
          allowUpdate: false,
        };
      }
    }

    const finalOptions = merge(defaultOptions, calculatedOptions);

    console.log("Luckysheet create options:", finalOptions);
    window.luckysheet.create(finalOptions);
    console.log("Luckysheet is ready, version:", window.luckysheet.version);
  };

  private _lsdestroy = (): void => {
    this._gridkey = null;
    console.log("trying to destroy", this._props);

    window.luckysheet.destroy({
      success: () => {
        this._processing = false;
        console.log("destroyed", this._props);
        this._processNext();
      },
    });
  };

  private get _props() {
    return {
      processing: this._processing,
      gridkey: this._gridkey,
      queue: this._queue,
    };
  }

  public create = ({
    gridkey,
    formulaEngine,
    data,
    options,
  }: {
    gridkey: string;
    formulaEngine?: FormulaEngine;
    data?: any;
    options?: LuckysheetOptions;
  }): void => {
    if (
      this._queue.length &&
      this._queue[this._queue.length - 1]?.type === "create"
    ) {
      this._queue = [];
    }
    console.log("received command to create", this._props);
    this._queue.push({
      type: "create",
      params: { gridkey, formulaEngine, data, options },
    });
    this._processNext();
  };

  public destroy = (): void => {
    console.log("received command to destroy", this._props);
    this._queue.push({ type: "destroy" });
    this._processNext();
  };
}

const style: React.CSSProperties = {
  margin: "0px",
  padding: "0px",
  position: "absolute",
  width: "100%",
  height: "100%",
  left: "0px",
  top: "0",
};

interface LuckysheetProps {
  gridkey: string;
  formulaEngine?: FormulaEngine;
}

const Luckysheet: React.FC<LuckysheetProps> = ({ gridkey, formulaEngine }) => {
  const { current: ls } = useRef(new LuckysheetManager());
  const { data: luckysheetData, options: luckysheetOptions } = useContext(
    LuckysheetDataContext
  );

  const lsRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null && gridkey) {
        ls.create({
          gridkey,
          formulaEngine,
          data: luckysheetData,
          options: luckysheetOptions,
        });
      }
    },
    [gridkey, luckysheetData, luckysheetOptions]
  );

  useEffect(() => {
    return () => {
      // Принудительно закрываем контрол для редактирования значений
      const activeCell = document.getElementById("luckysheet-input-box");
      if (activeCell) {
        activeCell.style.cssText = "";
      }
    };
  }, []);

  return <div id="luckysheet" ref={lsRef} style={style} key="1" />;
};

export default Luckysheet;
