import { AxiosError } from "axios";
import { useQuery } from "@tanstack/react-query";
import { FinanceModelDto } from "@/entities/FinanceModel";
import { fetchFinanceModelByGridkey } from "../api";
import { financeModelKeys } from "../const";

export const useFinanceModelByGridkey = (gridkey: string) => {
  return useQuery<FinanceModelDto, AxiosError>({
    queryKey: financeModelKeys.inputs(gridkey).queryKey,
    queryFn: () => fetchFinanceModelByGridkey(gridkey),
    enabled: !!gridkey,
  });
};
