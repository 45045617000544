import { api } from "@/shared/config/network";

import type { BackupDto } from "../model";

interface RestoreBackupParams {
  versionId: string;
}

const restoreBackup = async ({ versionId }: RestoreBackupParams) => {
  const response = await api.post<BackupDto>(`/backups/restore/${versionId}`);
  return response.data;
};

export { restoreBackup };
