import { FC, useRef, ReactNode } from "react";
import { useUploadAvatar } from "@/entities/User";
import { useNotification } from "@/shared/hooks/useNotification";
import { ONE_MB } from "@/shared/const/files";
import { AVATAR_ACCEPTED_FILE_TYPES, AVATAR_MAX_FILE_SIZE_IN_MB, } from "../const";

interface UserAvatarUploadContainerProps {
  trigger: ReactNode;
}

const UserAvatarUploadContainer: FC<UserAvatarUploadContainerProps> = ({ trigger }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { notifyError } = useNotification();
  const { mutate: uploadAvatar } = useUploadAvatar();

  const handleTriggerClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    fileInputRef.current?.click();
  };

  const isCorrectFileSize = (files: File[]) => {
    return files.every(
      (file) => file.size <= ONE_MB * AVATAR_MAX_FILE_SIZE_IN_MB
    );
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const selectedFiles = Array.from(e.target.files);
    if (!isCorrectFileSize(selectedFiles)) {
      notifyError(`Размер файла не должен превышать ${AVATAR_MAX_FILE_SIZE_IN_MB} МБ.`);
    } else {
      uploadAvatar(selectedFiles[0]);
    }
  };

  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        value={""}
        onChange={handleFileChange}
        accept={AVATAR_ACCEPTED_FILE_TYPES}
        hidden
      />
      <div onClick={handleTriggerClick}>{trigger}</div>
    </>
  );
};

export default UserAvatarUploadContainer;