import { FC } from "react";
import { Box, Stack, Typography } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { BaseModal } from "@/shared/ui/Modal";

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

const PublishingSuccess: FC<Props> = ({ open, onClose }) => {
  return (
    <BaseModal open={open} onClose={onClose} width={400} sx={{ inner: { height: "180px" } }}>
      <Stack spacing={2}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CheckCircleOutlineOutlinedIcon fontSize="large" color="success" />
        </Box>

        <Typography>Мастер-модель успешно создана!</Typography>
      </Stack>
    </BaseModal>
  );
};

export { PublishingSuccess };
