import { useState, useEffect } from "react";
import { Box, Typography, Avatar } from "@mui/material";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import orgModelImg from "@/assets/images/orgModelGreen.png";
import { useSwitchCompany } from "@/entities/Company/queries/useSwitchCompany";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";

export const CompaniesInfo = () => {
  const { mutate: switchCompany } = useSwitchCompany();
  const { data: user, isLoading, isError } = useUserProfile();
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(
    user?.lastUsedOrganizationId ?? null
  );

  useEffect(() => {
    if (user?.lastUsedOrganizationId) {
      setSelectedOrgId(user.lastUsedOrganizationId);
    }
  }, [user?.lastUsedOrganizationId]);

  const handleOrgClick = (id: number) => {
    if (id !== selectedOrgId) {
      switchCompany(id, {
        onSuccess: () => {
          setSelectedOrgId(id);
        },
      });
    }
  };

  const renderCompanies = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Typography variant="h6" component="div">
          Проекты
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {user &&
            user.organizationsWithRoles.map((org) => {
              return (
                <Box
                  key={org.organization.id}
                  onClick={() => handleOrgClick(org.organization.id)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px",
                    border:
                      selectedOrgId === org.organization.id
                        ? "2px solid var(--colorLightBlue200)"
                        : "1px solid var(--colorLightBlue100)",
                    borderRadius: "8px",
                    cursor: "pointer",
                    backgroundColor:
                      selectedOrgId === org.organization.id
                        ? "#f0faff"
                        : "transparent",
                    "&:hover": {
                      backgroundColor: "#E3EFFA",
                    },
                  }}
                >
                  <Avatar
                    src={orgModelImg}
                    alt={org.organization.name}
                    sx={{
                      width: "40px",
                      height: "40px",
                      marginRight: "16px",
                    }}
                  />
                  <Box>
                    <Typography variant="h6" component="div">
                      {org.organization.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {org.organization.description}
                    </Typography>
                    {
                      <Typography variant="caption" color="textSecondary">
                        Роли: {org.roles.map((role) => role.name).join(", ")}
                      </Typography>
                    }
                  </Box>
                </Box>
              );
            })}
        </Box>
      </Box>
    );
  };

  const stateMap = {
    loading: <Spinner fullscreen={false} />,
    error: <Typography color="error">Ошибка загрузки организаций</Typography>,
    success: renderCompanies(),
  };

  const currentState = isLoading ? "loading" : isError ? "error" : "success";

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "50px",
        position: "relative",
      }}
    >
      {stateMap[currentState]}
    </Box>
  );
};
