import { FC } from "react";
import { Control, ArrayPath, useFieldArray, UseFormRegister } from "react-hook-form";
import { Box, List, ListItem, Typography } from "@mui/material";
import { CustomCheckbox } from "@/shared/ui/CustomCheckbox";
import { SheetsSettingsForm } from "./interfaces";

interface Props {
  title: string;
  register: UseFormRegister<SheetsSettingsForm>;
  control: Control<SheetsSettingsForm>;
  name: ArrayPath<SheetsSettingsForm>;
}

const SheetPurposesGroupControl: FC<Props> = ({ title, control, register, name }) => {
  const { fields: sheetFields } = useFieldArray({
    name,
    control
  });

  return (
    <Box>
      <Typography
        variant="subtitle1"
        sx={{ fontSize: "16px", lineHeight: "150%", fontWeight: 500 }}
      >
        {title}
      </Typography>

      <List>
        {sheetFields.map((field, index) => (
          <ListItem key={field.id} sx={{ padding: 0, marginBottom: "8px" }}>
            <CustomCheckbox
              label={field.name}
              {...register(`${name}.${index}.checked`)}
              defaultChecked={field.checked}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export { SheetPurposesGroupControl };
