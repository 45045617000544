import { useQuery } from "@tanstack/react-query";

import { fetchLabels } from "../api/fetchLabels";
import { labelKeys } from "../const";

const useLabels = () => {
  return useQuery({
    queryKey: labelKeys.list.queryKey,
    queryFn: () => fetchLabels(),
  });
};

export { useLabels };
