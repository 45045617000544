import { api } from "@/shared/config/network";

interface CloneModelParams {
  financeModelId: number;
}

const cloneModel = async ({ financeModelId }: CloneModelParams) => {
  const response = await api.post(`/finance-models/${financeModelId}/clone`);
  return response.data;
};

export { cloneModel };
