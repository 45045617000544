import { luckysheetApi, luckysheetApiV2 } from "@/shared/config/network";

interface ImportFileResponse {
  data: {
    docName: string;
    docCode: string;
  };
}

type CelldataType = {
  r: number;
  c: number;
  v: {
    m: string;
    v: string;
  };
};

type FileData = {
  name: string;
  order: number;
  index: string;
  celldata: CelldataType[];
};

interface SplitImportFileResponse {
  documentId: string;
  fileData: FileData[];
}

type ImportFromSheets = {
  documentId: string;
  sheetIndexes: string[];
};

const importFile = async (file: FormData) => {
  const { data } = await luckysheetApi.post<ImportFileResponse>(
    "/import_xls",
    file,
    {
      headers: { "content-type": "multi-part/formdata" },
    }
  );

  return data;
};

const splitImportFile = async (file: FormData) => {
  const { data } = await luckysheetApiV2.post<SplitImportFileResponse>(
    "/import-preview",
    file,
    {
      headers: { "content-type": "multi-part/formdata" },
    }
  );

  return data;
};

const importFileFromSheets = async (body: ImportFromSheets) => {
  const { data } = await luckysheetApiV2.post<ImportFileResponse>(
    "/import",
    body
  );

  return data;
};

export {
  importFile,
  splitImportFile,
  importFileFromSheets,
  type ImportFileResponse,
};
