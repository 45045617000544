import { type FC, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFinanceModelInputTabs } from "@/entities/FinanceModelInput";
import { SyncResultDto, useSyncedData } from "@/entities/SyncedData";
import { SheetsSettingsModal } from "./SheetsSettingsModal";
import { SyncModal } from "./SyncModal";
import { SyncResultModal } from "./SyncResultModal";
import { PublishingSuccess } from "./PublishingSuccess";
import { CheckedSheets, SheetsSettingsForm } from "./interfaces";
import {
  getAllSheetsOfCurrentModel,
  getDefaultSheetsSettings,
  isSheetChecked,
} from "./utils";

export interface Props {
  financeModelId: number;
  gridkey: string;
  isOpen: boolean;
  onClose: VoidFunction;
}

type PublishingState =
  | { type: "editSheetPurposes" }
  | { type: "viewOutputsToSync"; checkedSheets: CheckedSheets | null }
  | { type: "syncResult"; syncResults: SyncResultDto[] }
  | { type: "bindDashboards" }
  | { type: "publishingSuccess" }
  | { type: "publishingFailure" };

const PublishingProcess: FC<Props> = ({
  financeModelId,
  gridkey,
  isOpen,
  onClose,
}) => {
  const [publishingStage, setPublishingStage] =
    useState<PublishingState | null>(null);

  const { data: syncedTables, isPending: isSyncedTablesPending } =
    useSyncedData({ gridkey, financeModelId });

  const { data: tabs, isPending: isTabsPending } = useFinanceModelInputTabs({
    financeModelId,
  });

  useEffect(() => {
    setPublishingStage(isOpen ? { type: "editSheetPurposes" } : null);
  }, [isOpen]);

  // Sheets Settings Form -->
  const sheetsSettingsForm = useForm<SheetsSettingsForm>({
    defaultValues: getDefaultSheetsSettings([], tabs, syncedTables),
  });

  useEffect(() => {
    if (isOpen) {
      const allSheets = getAllSheetsOfCurrentModel();

      const { forms, bi } = getDefaultSheetsSettings(
        allSheets,
        tabs,
        syncedTables
      );

      sheetsSettingsForm.setValue("forms", forms);
      sheetsSettingsForm.setValue("bi", bi);
    }
  }, [isOpen, sheetsSettingsForm, syncedTables, tabs]);

  // <-- Sheets Settings Form

  const onSyncResultPreviousClick = useCallback(() => {
    const data = sheetsSettingsForm.getValues();

    const checkedSheets: CheckedSheets = {
      formInputs: data.forms.inputs.filter(isSheetChecked),
      formOutputs: data.forms.outputs.filter(isSheetChecked),
      biInputs: data.bi.inputs.filter(isSheetChecked),
      biOutputs: data.bi.outputs.filter(isSheetChecked),
    };

    setPublishingStage({ type: "viewOutputsToSync", checkedSheets });
  }, [sheetsSettingsForm]);

  return (
    <>
      <SheetsSettingsModal
        form={sheetsSettingsForm}
        loading={isTabsPending}
        open={publishingStage?.type === "editSheetPurposes"}
        onClose={onClose}
        onNextClick={(checkedSheets) =>
          setPublishingStage({ type: "viewOutputsToSync", checkedSheets })
        }
      />

      <SyncModal
        financeModelId={financeModelId}
        checkedSheets={
          publishingStage?.type === "viewOutputsToSync"
            ? publishingStage.checkedSheets
            : null
        }
        isLoading={isSyncedTablesPending}
        open={publishingStage?.type === "viewOutputsToSync"}
        onPreviousClick={() =>
          setPublishingStage({ type: "editSheetPurposes" })
        }
        onNextClick={(syncResults) =>
          setPublishingStage({ type: "syncResult", syncResults })
        }
        onClose={onClose}
      />

      <SyncResultModal
        results={
          publishingStage?.type === "syncResult"
            ? publishingStage.syncResults
            : []
        }
        open={publishingStage?.type === "syncResult"}
        onPreviousClick={onSyncResultPreviousClick}
        onNextClick={() => setPublishingStage({ type: "publishingSuccess" })}
        onClose={onClose}
      />

      {/*<DashboardBindingModal
        open={publishingStage?.type === "bindDashboards"}
        onPreviousClick={() => setPublishingStage({ type: "viewOutputsToSync" })}
        onNextClick={() => setPublishingStage({ type: "publishingSuccess" })}
        onClose={onClose}
      />*/}

      <PublishingSuccess
        open={publishingStage?.type === "publishingSuccess"}
        onClose={onClose}
      />
    </>
  );
};

export { PublishingProcess };
