import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FC, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  useAttachLabelsToVersion,
  useVersionLabelsById,
} from "@/entities/Version/queries";
import { useLabels } from "@/entities/Label/queries/useLabels";
import { LabelChip, LabelsList } from "@/entities/Label";
import { useNotification } from "@/shared/hooks/useNotification";
import { useDeleteLabelFromVersion } from "@/entities/Version/queries/useDeleteLabelFromVersion";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";

interface VersionsLabelsModalProps {
  versionId: number;
  open: boolean;
  handleClose: () => void;
  onLabelsAttached?: VoidFunction;
  onLabelsDeleted?: VoidFunction;
}

interface VersionsLabelsModalForm {
  versionIds: number[];
}

export const VersionsLabelsModal: FC<VersionsLabelsModalProps> = ({
  versionId,
  open,
  handleClose,
  onLabelsAttached,
  onLabelsDeleted
}) => {
  const { notifyInfo } = useNotification();

  const { data: allLabels, isLoading: areAllLabelsLoading } = useLabels();
  const { data: versionLabels, isLoading: areVersionLabelsLoading } =
    useVersionLabelsById(versionId);
  const { mutate: attachLabel } = useAttachLabelsToVersion();
  const { mutate: deleteLabel } = useDeleteLabelFromVersion();

  const isLoading = areAllLabelsLoading && areVersionLabelsLoading;

  const { handleSubmit, control, reset, setValue } =
    useForm<VersionsLabelsModalForm>({
      defaultValues: {
        versionIds: [],
      },
    });

  useEffect(() => {
    if (versionLabels) {
      setValue(
        "versionIds",
        versionLabels.labels.map((label) => label.id) ?? []
      );
    }
  }, [versionLabels]);

  const onSubmit = (data: VersionsLabelsModalForm) => {
    attachLabel(
      {
        versionId,
        dto: data,
      },
      {
        onSuccess: () => {
          notifyInfo("Метки успешно добавлены");
          onLabelsAttached?.();
        },
      }
    );
  };

  const handleCloseModal = () => {
    reset();
    handleClose();
  };

  const handleDeleteLabel = (id: number) => {
    deleteLabel(
      {
        versionId,
        labelId: id,
      },
      {
        onSuccess: () => {
          notifyInfo("Метка удалена");
          onLabelsDeleted?.();
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={handleCloseModal}>
      <DialogTitle>Метки</DialogTitle>
      {isLoading ? (
        <Spinner />
      ) : (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography variant="subtitle1">Текущие метки</Typography>
          {versionLabels && (
            <LabelsList
              labels={versionLabels.labels}
              onDelete={handleDeleteLabel}
            />
          )}
          <Divider sx={{ margin: "16px 0" }} />
          <Typography variant="subtitle1">Добавить метки</Typography>
          {allLabels && (
            <Controller
              control={control}
              name="versionIds"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    multiple
                    renderValue={(selected: number[]) => {
                      return (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected
                            .map((id) => {
                              const foundLabel = allLabels.find(
                                (label) => label.id === id
                              );
                              return foundLabel;
                            })
                            .map((selectedLabel) => {
                              if (!selectedLabel) return null;

                              return <LabelChip label={selectedLabel} />;
                            })}
                        </Box>
                      );
                    }}
                  >
                    {allLabels?.map((label) => {
                      return (
                        <MenuItem key={label.id} value={label.id}>
                          {label.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                );
              }}
            />
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Отмена
              </Button>
              <Button
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
              >
                Сохранить
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      )}
    </Dialog>
  );
};
