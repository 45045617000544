import { api } from "@/shared/config/network";

import { FinanceModelDto, FinanceModelCreateDto } from "../model";

const createFinanceModel = async (dto: FinanceModelCreateDto) => {
  const response = await api.post<FinanceModelDto>("/finance-models", dto);
  return response.data;
};

export { createFinanceModel };
