import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createBackup } from "../api/create-backup";
import { backupKeys } from "../const";

const useBackupCreate = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: createBackup,
    onSuccess: (...[, { financeModelId }]) => {
      qc.invalidateQueries({
        queryKey: backupKeys.list(financeModelId).queryKey,
      });
    },
  });
};

export { useBackupCreate };
