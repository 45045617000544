import { api } from "@/shared/config/network";

import type { DashboardDto } from "../model";

interface FetchDashboardsParams {
  financeModelId: number;
}

const fetchDashboards = async ({ financeModelId }: FetchDashboardsParams) => {
  const response = await api.get<DashboardDto[]>(
    `/dashboards/finance-models/${financeModelId}`
  );
  return response.data;
};

export { fetchDashboards };
