import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createFinanceModel } from "../api";
import { financeModelKeys } from "../const";

const useFinanceModelCreate = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createFinanceModel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: financeModelKeys.list().queryKey,
      });
    },
  });
};

export { useFinanceModelCreate };
