import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FC } from "react";

interface BackupDeleteDialogContentProps {
  handleBackupDialogClose: () => void;
  handleDeleteBackup: () => void;
}

export const BackupDeleteDialogContent: FC<BackupDeleteDialogContentProps> = ({
  handleBackupDialogClose,
  handleDeleteBackup,
}) => {
  return (
    <>
      <DialogTitle>Удалить резервную копию</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Данные будут безвозвратно потеряны. Вы уверены, что хотите удалить эту
          резервную копию?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleBackupDialogClose}
        >
          Отмена
        </Button>
        <Button color="error" variant="contained" onClick={handleDeleteBackup}>
          Удалить
        </Button>
      </DialogActions>
    </>
  );
};
