import React, { ChangeEvent, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, CircularProgress, useTheme } from "@mui/material";
import {
  type FinanceModelCreateDto,
  FinanceModelDashboard,
  FinanceModelDto,
  FinanceModelSelectSheetsPreview,
  FormulaEngine,
  importFile,
  ImportFileResponse,
  splitImportFile,
} from "@/entities/FinanceModel";
import Header from "@/widgets/Header";
import { FinanceModelCreate } from "@/features/FinanceModel/FinanceModelCreate/ui/FinanceModelCreate";
import { useNotification } from "@/shared/hooks/useNotification";
import { routePaths } from "@/shared/config/routePaths";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";
import { useUserMenuGroups } from "@/features/User";
import { TemplatesSection } from "@/widgets/TemplatesSection";
import { TemplateUseDto } from "@/entities/Templates";
import { FinanceModelCreateFromImport } from "@/features/FinanceModel/FinanceModelCreate";
import { CompanySwitcher } from "@/widgets/CompanySwitcher";
import { ScenariosDashboard } from "@/entities/Scenarios/ui/ScenariosDashboard";
import { ModelMenu } from "@/widgets/ModelMenu";
import HubIcon from "@mui/icons-material/Hub";
import TableChartIcon from "@mui/icons-material/TableChart";
import CodeIcon from "@mui/icons-material/Code";
import ImportFolder from "@/assets/icons/importFolder.svg?react";
import PlusIcon from "@/assets/icons/plus.svg?react";
import { ScenarioCreateModal } from "@/features/Scenarios/indext";
import { type ScenarioDTO } from "@/entities/Scenarios/model";
import { usePermissions } from "@/entities/User";
import { FinanceModelSelectSheetsImport } from "@/features/FinanceModel/FinanceModelCreate/ui/FinanceModelSelectSheetsImport";
import { ImportContacts } from "@mui/icons-material";

const ModelsPage: React.FC = () => {
  const { createModel } = usePermissions();
  const { data: user } = useUserProfile();
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [defaultFormulaEngine, setDefaultFormulaEngine] =
    useState<FormulaEngine>(
      (import.meta.env.VITE_DEFAULT_FORMULA_ENGINE as FormulaEngine) ||
        "hypersheet"
    );
  const [isOpenScenarioCreateModal, setIsOpenScenarioCreateModal] =
    useState(false);
  const [createModalFromImportState, setCreateModalFromImportState] = useState<{
    state: "open";
    initialValues?: FinanceModelCreateDto;
  } | null>(null);

  const [createModalFromImportSheets, setCreateModalFromImportSheets] =
    useState<{
      state: "open";
      initialValues?: FinanceModelSelectSheetsPreview;
    } | null>(null);

  const [isImportingFile, setIsImportingFile] = useState(false);
  const [importMode, setImportMode] = useState<"split" | "full" | null>(null);
  const [templateId, setTemplateId] = useState<number | undefined>();
  const { notifyError, notifySuccess, notifyInfo } = useNotification();
  const { push } = useHistory();
  const theme = useTheme();
  const userMenuGroups = useUserMenuGroups();

  const importFileInputRef = useRef<HTMLInputElement>(null);

  const handleImportXLS = (mode: "split" | "full") => {
    setImportMode(mode);
    importFileInputRef.current?.click();
  };

  const handleImportFileChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      try {
        setIsImportingFile(true);

        const formData = new FormData();
        formData.append("file", file);

        if (importMode === "split") {
          const { fileData, documentId } = await splitImportFile(formData);

          const sheets = fileData.map((sheet) => ({
            name: sheet.name,
            index: sheet.index,
            celldata: sheet.celldata,
          }));

          const sheetIds = sheets.map((sh) => sh.index);

          setCreateModalFromImportSheets({
            state: "open",
            initialValues: {
              documentId,
              description: "",
              formulaEngine: "hypersheet",
              name: "",
              sheetIds,
              sheets,
            },
          });
        } else {
          const {
            data: { docName, docCode },
          } = await importFile(formData);

          setCreateModalFromImportState({
            state: "open",
            initialValues: {
              gridkey: docCode,
              name: docName,
              description: "",
              formulaEngine: "hypersheet",
            },
          });
        }
      } catch (error) {
        console.error("Failed to import file", error);
        notifyError("Не удалось импортировать файл", { error });
      } finally {
        setIsImportingFile(false);
        setImportMode(null);
      }
    }
  };

  const onSuccessCreateModel = (model: FinanceModelDto | TemplateUseDto) => {
    push(`${routePaths.grid}/${model.gridkey}`);
    notifySuccess("Модель создана", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsOpenCreateModal(false);
  };

  const onSuccessSelectSheets = (model: ImportFileResponse) => {
    const { docCode, docName } = model.data;
    setCreateModalFromImportSheets(null);
    setCreateModalFromImportState({
      state: "open",
      initialValues: {
        gridkey: docCode,
        name: docName,
        description: "",
        formulaEngine: "hypersheet",
      },
    });
  };

  const onSuccessSceanrioModel = (model: ScenarioDTO) => {
    notifySuccess("Сценарий создан", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    push(`${routePaths.scenarios.grid}/${model.id}`);
    setIsOpenScenarioCreateModal(false);
  };

  const handleClickOnTemplate = (templateId: number) => {
    setTemplateId(templateId);
    setIsOpenCreateModal(true);
  };

  const onErrorCreateModel = () => {
    notifyError("Ошибка создания", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsOpenCreateModal(false);
  };

  const handleCloseCreateModal = () => {
    setIsOpenCreateModal(false);
    setTemplateId(undefined);
    setDefaultFormulaEngine("hypersheet");
  };

  const handleCloseCreateFromImportModal = () => {
    setCreateModalFromImportState(null);
  };

  const handleCloseCreatefromImportSheetsModal = () => {
    setCreateModalFromImportSheets(null);
  };

  const createModelMenuGroup = [
    {
      items: [
        {
          label: "Создать модель",
          icon: <TableChartIcon fontSize="small" />,
          onClick: () => {
            setDefaultFormulaEngine("hypersheet");
            setIsOpenCreateModal(true);
          },
          shouldCloseOnClick: true,
        },
        {
          label: "Создать скрипт",
          icon: <CodeIcon fontSize="small" />,
          onClick: () => {
            setDefaultFormulaEngine("python");
            setIsOpenCreateModal(true);
          },
          shouldCloseOnClick: true,
        },
        {
          label: "Создать сценарий",
          icon: <HubIcon fontSize="small" />,
          onClick: () => setIsOpenScenarioCreateModal(true),
          shouldCloseOnClick: true,
        },
      ],
      divider: true,
    },
    {
      items: [
        {
          label: "Постраничный импорт XLSX",
          icon: <ImportContacts />,
          onClick: () => {
            setDefaultFormulaEngine("hypersheet");
            handleImportXLS("split");
          },
          shouldCloseOnClick: true,
        },
        {
          label: "Импортировать XLSX",
          icon: <ImportFolder width={19.5} height={16.5} />,
          onClick: () => {
            setDefaultFormulaEngine("hypersheet");
            handleImportXLS("full");
          },
          shouldCloseOnClick: true,
        },
      ],
    },
  ];

  return (
    <Box
      sx={{
        paddingTop: "var(--headerHeight)",
        maxWidth: "1632px",
        margin: "0 auto",
      }}
    >
      <Header
        UserMenuGroups={{
          menuGroups: userMenuGroups,
          alignment: "right",
          profileImage: user?.avatar,
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          flexWrap: "wrap",
          padding: "36px 0",
          gap: "8px",
          [theme.breakpoints.down("xl")]: {
            padding: "36px 24px",
          },
        }}
      >
        <Box sx={{ marginRight: "auto", position: "relative" }}>
          <CompanySwitcher showOnlyActiveOrganization={true} />
        </Box>

        {/* <Button
          variant="outlineV1"
          disabled={isImportingFile}
          startIcon={
            isImportingFile ? (
              <CircularProgress size={20} sx={{ color: "#fff" }} />
            ) : undefined
          }
          onClick={handleImportXLS}
        >
          Импортировать XLS
        </Button> */}

        <input
          type="file"
          accept=".xlsx,.xls"
          ref={importFileInputRef}
          style={{ display: "none" }}
          onChange={handleImportFileChange}
        />

        {/* <Button variant="lightBlueV300" onClick={handleOpenCreateModal}>
          Создать модель
        </Button> */}
        <ModelMenu
          menuGroups={createModelMenuGroup}
          triggerSlot={
            <Button
              variant="outlineV1"
              disabled={isImportingFile}
              startIcon={
                isImportingFile ? (
                  <CircularProgress size={20} sx={{ color: "#fff" }} />
                ) : undefined
              }
              sx={{
                opacity: createModel ? 1 : 0.1,
              }}
              onClick={(e) => {
                if (!createModel) {
                  e.stopPropagation();
                  notifyInfo(
                    "У вас нет прав на создание моделей. Обратитесь в администратору"
                  );
                }
              }}
            >
              <PlusIcon width={25} height={25} />
              Создать
            </Button>
          }
        />

        <ScenarioCreateModal
          open={isOpenScenarioCreateModal}
          handleClose={() => setIsOpenScenarioCreateModal(false)}
          onSuccess={onSuccessSceanrioModel}
        />

        <FinanceModelCreate
          open={isOpenCreateModal}
          handleClose={handleCloseCreateModal}
          onSuccess={onSuccessCreateModel}
          onError={onErrorCreateModel}
          templateId={templateId}
          defaultFormulaEngine={defaultFormulaEngine}
        />

        <FinanceModelCreateFromImport
          open={!!createModalFromImportState}
          handleClose={handleCloseCreateFromImportModal}
          onSuccess={onSuccessCreateModel}
          onError={onErrorCreateModel}
          initialValues={createModalFromImportState?.initialValues}
          defaultFormulaEngine={defaultFormulaEngine}
        />

        <FinanceModelSelectSheetsImport
          open={!!createModalFromImportSheets}
          handleClose={handleCloseCreatefromImportSheetsModal}
          onSuccess={onSuccessSelectSheets}
          onError={onErrorCreateModel}
          initialValues={createModalFromImportSheets?.initialValues}
        />
      </Box>
      <Box
        sx={{
          [theme.breakpoints.down("xl")]: {
            paddingInline: "24px",
          },
        }}
      >
        <TemplatesSection
          onTemplateSelect={handleClickOnTemplate}
          isOpen={false}
        />
      </Box>
      <ScenariosDashboard />
      <FinanceModelDashboard />
    </Box>
  );
};

export default ModelsPage;
