import { memo } from "react";
import { Handle, Position } from "@xyflow/react";
import { HandleData, NodeData } from "@/entities/Scenarios/model";

import CodeOutlinedIcon from "@mui/icons-material/CodeOutlined";

import "./script-node.css";

const ScriptNode = memo(({ data }: { data: NodeData }) => {
  console.log("data.outputs:", data.outputs);
  return (
    <div className="script-node">
      <div className="handles targets">
        {data.inputs?.map((handle: HandleData) => (
          <Handle
            key={handle.id}
            id={handle.id}
            type="target"
            position={Position.Left}
          />
        ))}
      </div>
      <div className="script-node__header">
        <CodeOutlinedIcon className="script-node__code-icon" />
        <div className="script-node__title">Скрипт</div>
      </div>
      <div className="script-node__text">{data?.label}</div>
      <div className="handles sources">
        {data.outputs?.map((handle: HandleData) => (
          <Handle
            key={handle.id}
            id={handle.id}
            type="source"
            position={Position.Right}
          />
        ))}
      </div>
    </div>
  );
});

export default ScriptNode;
