import { api } from "@/shared/config/network";
import { BackupDto } from "../model";

interface ProtectBackupParams {
  backupId: number;
  isProtected: boolean;
}

const protectBackup = async ({
  backupId,
  isProtected,
}: ProtectBackupParams) => {
  const response = await api.patch<BackupDto>(
    `/backups/${backupId}/protected`,
    undefined,
    {
      params: { isProtected },
    }
  );
  return response.data;
};

export { protectBackup };
