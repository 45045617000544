export const getScrollbarWidth = () => {
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll";
  outer.style.position = "absolute";
  outer.style.width = "100px";
  document.body.appendChild(outer);
  const inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  outer && outer?.parentNode?.removeChild(outer);
  return scrollbarWidth;
};
