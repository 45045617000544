import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import type { ReactNode } from "react";

interface ProfileSectionProps {
  children: ReactNode;
}

const ProfileSection = ({ children }: ProfileSectionProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 4,
        boxShadow: theme.palette.customShadows.lightBlue,
        padding: 3,
        borderRadius: 2,
      }}
    >
      {children}
    </Box>
  );
};

export default ProfileSection;
