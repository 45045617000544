import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    customColors: {
      colorLightBlue200: "#cbdeef",
      colorLightBlue100: "#e1f1ff",
      colorCelestialBlue: "#409df0",
      colorBrandeisBlue: "#0273F8",
      colorArgentinianBlue: "#78BAF8",
      colorAliceBlue: "#E7F4FF",
      colorAliceBlueDark: "#D7E9FE",
      colorJordyBlue: "#88BDFC",
      colorColumbiaBlue: "#C8E0FB",
      colorBlack: "#000",
      colorWhite: "#fff",
      colorLavender: "#E0E4F9",
      colorTaupeGray: "#928686",
      colorBattleshipGray: "#898686",
    },
    customShadows: {
      lightBlue: "4px 4px 56px 0 rgba(80, 158, 227, 0.18)",
      lightBlueFaded: "4px 4px 96px 0 rgba(80, 158, 227, 0.48)",
      darkBlue: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
  },

  customVariables: {
    headerHeight: "86px",
    headerPdBlock: "28px",
    headerPdInline: "24px",
  },

  zIndex: {
    drawer: 1200,
  },

  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "lightBlueV100" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.customColors.colorCelestialBlue,
            color: theme.palette.customColors.colorWhite,
            padding: "10px",
            borderRadius: "6px",
            marginTop: "0 !important",

            "&:hover": {
              backgroundColor: theme.palette.customColors.colorCelestialBlue,
            },
            "&[disabled]:hover": {
              backgroundColor: theme.palette.customColors.colorLightBlue100,
              cursor: "not-allowed",
            },
            "&[disabled]": {
              backgroundColor: theme.palette.customColors.colorLightBlue100,
              color: "rgba(64, 157, 240, 0.48);",
              cursor: "not-allowed",
              opacity: 0.5,
            },
          }),
        },
        {
          props: { variant: "lightBlueV200" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.customColors.colorLightBlue100,
            color: theme.palette.customColors.colorBlack,
            padding: "10px",
            borderRadius: "6px",

            "&:hover": {
              backgroundColor: theme.palette.customColors.colorLightBlue100,
            },
            "&[disabled]:hover": {
              backgroundColor: theme.palette.customColors.colorLightBlue100,
              cursor: "not-allowed",
            },
            "&[disabled]": {
              backgroundColor: theme.palette.customColors.colorLightBlue100,
              color: theme.palette.customColors.colorBlack,
              cursor: "not-allowed",
              opacity: 0.5,
            },
            "& .MuiTouchRipple-root span": {
              "& .MuiTouchRipple-child": {
                backgroundColor: theme.palette.customColors.colorCelestialBlue, // Цвет Ripple
                opacity: 0.3,
              },
            },
          }),
        },
        {
          props: { variant: "lightBlueV300" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.customColors.colorBrandeisBlue,
            color: theme.palette.customColors.colorWhite,
            padding: "8px 12px",
            borderRadius: "6px",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "150%",

            "&:hover": {
              backgroundColor: theme.palette.customColors.colorBrandeisBlue,
            },
            "&[disabled]:hover": {
              backgroundColor: theme.palette.customColors.colorBrandeisBlue,
              cursor: "not-allowed",
            },
            "&[disabled]": {
              backgroundColor: theme.palette.customColors.colorBrandeisBlue,
              color: theme.palette.customColors.colorWhite,
              cursor: "not-allowed",
              opacity: 0.5,
            },
            "& .MuiTouchRipple-root span": {
              "& .MuiTouchRipple-child": {
                backgroundColor: theme.palette.customColors.colorCelestialBlue, // Цвет Ripple
                opacity: 0.3,
              },
            },
          }),
        },
        {
          props: { variant: "outlineV1" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.customColors.colorWhite,
            color: theme.palette.customColors.colorBlack,
            border: `1px solid ${theme.palette.customColors.colorLavender}`,
            padding: "8px 12px",
            borderRadius: "6px",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "150%",

            "&:hover": {
              backgroundColor: theme.palette.customColors.colorLavender,
              color: theme.palette.customColors.colorBrandeisBlue,
            },
            "&[disabled]:hover": {
              backgroundColor: theme.palette.customColors.colorBrandeisBlue,
              cursor: "not-allowed",
            },
            "&[disabled]": {
              backgroundColor: theme.palette.customColors.colorBrandeisBlue,
              color: theme.palette.customColors.colorWhite,
              cursor: "not-allowed",
              opacity: 0.5,
            },
            "& .MuiTouchRipple-root span": {
              "& .MuiTouchRipple-child": {
                backgroundColor: theme.palette.customColors.colorLavender, // Цвет Ripple
              },
            },
          }),
        },
      ],
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxWidth: 285,
          overflow: "visible",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          display: "flex",
          alignItems: "center",
          padding: "12px 16px",
          fontWeight: 400,
          fontSize: "18px",
          lineHeight: "222%",
          color: "#000",
          "&:hover": {
            backgroundColor: theme.palette.customColors.colorLightBlue100, // Цвет фона при наведении
          },
          "& .MuiSvgIcon-root": {
            marginRight: 8,
          },
          "& .content": {
            display: "flex",
            alignItems: "center",
          },
        }),
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.customColors.colorLightBlue200, // Цвет для Divider
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: "6px",
          "&:hover": {
            backgroundColor: theme.palette.customColors.colorLightBlue100,
          },
          "&.Mui-disabled": {
            color: "rgba(2, 115, 248, 0.5)",
          },
          "& .MuiTouchRipple-root span": {
            backgroundColor: theme.palette.customColors.colorLightBlue100,
          },
        }),
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          marginLeft: 0,
          height: "24px",
        },
        switchBase: {
          padding: 3, // Отступ для кнопки внутри капсулы
          "&.Mui-checked": {
            transform: "translateX(18px)", // Позиция кнопки в активном состоянии
            "& .MuiSwitch-thumb": {
              backgroundColor: "#0273f8;", // Цвет кнопки в активном состоянии
            },
            "& + .MuiSwitch-track": {
              backgroundColor: "#e1eefe", // Цвет капсулы в активном состоянии
              opacity: 1,
            },
          },
        },
        thumb: {
          width: "16px", // Увеличиваем кнопку
          height: "16px", // Увеличиваем кнопку
          backgroundColor: "rgba(2, 115, 248, 0.48)", // Цвет кнопки
        },
        track: {
          borderRadius: 20,
          height: "24px",
          width: "40px",
          opacity: 1,
          backgroundColor: "#e1eefe;",
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1680,
    },
  },
});
