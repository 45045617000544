/** @jsxImportSource @emotion/react */
import { Global, css } from "@emotion/react";
//https://coolors.co/f9fbfc - name of colors
const GlobalStyles = () => (
  <Global
    styles={css`
      :root {
        --headerHeight: 58px;
        --headerPdBlock: 4px;
        --headerPdInline: 24px;
        --colorColumbiaBlue: #cbdeef;
        --colorAliceBlue: #e1f1ff;
        --colorCelestialBlue: #409df0;
        --colorBlack: #000;
        --colorWhite: #fff;
        --colorSeaSalt: #f9fbfc;
        --colorLightBlue100: var(--colorAliceBlue);
        --colorLightBlue200: var(--colorColumbiaBlue);
        --colorBlue: var(--colorCelestialBlue);
        --boxShadowDarkBlue: 4px 4px 96px 0 rgba(2, 115, 248, 0.16);
      }

      html {
        overflow-x: hidden;

        @media (max-width: 768px) {
          --headerHeight: 58px;
          --headerPdBlock: 15px;
          --headerPdInline: 12px;
        }
      }

      * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
      }

      html,
      body {
        font-family: "Inter", sans-serif;
      }

      .interFont {
        font-family: "Inter", sans-serif;
      }

      .robotoFont {
        font-family: "Roboto", sans-serif;
      }

      .notistack-snackbar-info {
        margin-right: 8px !important;
        padding: 32px !important;
        color: var(--colorBlack) !important;
        border-radius: 8px !important;
        border: 1px solid #0273f8 !important;
        box-shadow: 4px 4px 40px 0 rgba(80, 158, 227, 0.18) !important;
        background: #fff !important;
      }

      .notistack-snackbar-info #notistack-snackbar {
        font-weight: 400 !important;
        font-size: 16px !important;
        line-height: 150% !important;
        color: #000 !important;
      }

      a {
        text-decoration: none;
        color: inherit;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        outline: none;
        font-size: inherit;
        font-weight: inherit;
        font-family: inherit;
        line-height: inherit;
      }
    `}
  />
);

export default GlobalStyles;
