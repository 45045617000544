import axios from "axios";
import { useFinanceModelExport } from "@/entities/FinanceModel/";

const useDownloadFile = () => {
  const { mutateAsync: exportModel } = useFinanceModelExport();

  const changeExtensionToZip = (fileName: string) =>
    fileName.replace(/\.\w+$/, ".zip").replace(/(\.\w+)?$/, ".zip");

  const downloadFile = async (fileName: string, listId: string) => {
    try {
      const blob = await exportModel({ fileName, listId });
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", changeExtensionToZip(fileName));
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Ошибка запроса:", error.response?.data || error.message);
        throw new Error(
          `Ошибка запроса: ${error.response?.status} - ${
            error.response?.data?.message || error.message
          }`
        );
      } else {
        console.error("Неизвестная ошибка:", error);
        throw new Error(String(error));
      }
    }
  };

  return { downloadFile };
};

export { useDownloadFile };
