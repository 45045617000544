import type { CompanyDto } from "@/entities/Company";

enum UserRole {
  Admin = "Admin",
  Developer = "Developer",
  Analytic = "Analytic",
}

enum Permissions {
  "CREATE_FINANCE_MODEL" = "CREATE_FINANCE_MODEL",
  "PUBLISH_MASTER_MODEL" = "PUBLISH_MASTER_MODEL",
  "CREATE_NEW_VERSION" = "CREATE_NEW_VERSION",
  "CREATE_TEMPLATE" = "CREATE_TEMPLATE",
  "MANAGE_BACKUPS" = "MANAGE_BACKUPS",
  "SUBMIT_FORM_FOR_APPROVAL" = "SUBMIT_FORM_FOR_APPROVAL",
  "SUBMIT_FOR_PUBLICATION" = "SUBMIT_FOR_PUBLICATION",
  "SHARE_MODEL" = "SHARE_MODEL",
}

interface PermissionDto {
  id: number;
  name: Permissions;
  description: string | null;
}

interface UserInfoDto {
  avatar: string;
  fullname: string;
  id: number;
  username: string;
}

interface UserSelfDto {
  // expiration timestamp
  exp: number;
  role: UserRole;
  permissions: Permissions[];
  organization: CompanyDto;
  user?: UserInfoDto;
  userId?: number;
}

interface UserOrganizationDto {
  userId: number; // ID пользователя
  organizationId: number; // ID организации
  roleId: number; // ID роли
  createdAt: string; // Дата создания
  updatedAt: string; // Дата обновления
  role: {
    id: number; // ID роли
    name: string; // Название роли
  }; // Роль пользователя в данной организации
}

interface organizationsWithRoles {
  organization: {
    id: number;
    name: string;
    description: string;
  };
  roles: [
    {
      id: 9;
      name: string;
    }
  ];
}

interface UserDto {
  id: number;
  username: string;
  fullname: string;
  avatar: string;
  position: string;
  notes: string;
  role: UserRole;
  organization: CompanyDto;
  phoneNumber: string;
  active: boolean;
  lastUsedOrganizationId: number;
  createdAt: string;
  updatedAt: string;
  permissions: PermissionDto[];
  userOrganizations: UserOrganizationDto[];
  organizationsWithRoles: organizationsWithRoles[];
}

interface ExternalUser {
  id: number;
  fullname: string;
  avatar: string;
  email: string | null;
  position: string;
}

interface ExternalUserWithPermissions extends Omit<ExternalUser, "id"> {
  id: string | number;
  permissions: string;
}

interface UserAvatarUpdateResponse {
  message: string;
  avatarUrl: string;
}

interface UserChangePasswordDto {
  currentPassword: string;
  newPassword: string;
}

interface NotificationSettingsDto {
  systemNotifications: boolean;
  allNotifications: boolean;
  mentionNotifications: boolean;
}

export type {
  UserSelfDto,
  UserDto,
  UserInfoDto,
  UserChangePasswordDto,
  NotificationSettingsDto,
  UserAvatarUpdateResponse,
  PermissionDto,
  ExternalUser,
  ExternalUserWithPermissions,
};
export { Permissions, UserRole };
