import { isAxiosError } from "axios";
import { useMemo, useState } from "react";

import { useNotification } from "@/shared/hooks/useNotification";
import { UpdatedCells } from "@/shared/types/dashboard.type";
import { OutputData } from "@/entities/Version/model";
import { useFinanceModelInputsRecalculate } from "../queries";
import { FinanceModelRecalculateResponse } from "../model";

type UseRecalculateModeArgs = {
  updatedCells: Record<string, UpdatedCells>;
  financeModelId?: number;
  selectedSheet?: string;
};

export const useRecalculateModel = ({
  updatedCells,
  financeModelId,
  selectedSheet,
}: UseRecalculateModeArgs) => {
  const [key, setKey] = useState(0);
  const [fetchedData, setFetchedData] =
    useState<FinanceModelRecalculateResponse["fetchedData"]>();
  const { notifyInfo, notifyError } = useNotification();

  const {
    mutateAsync: recalculateModel,
    isPending: isRecalculateModelPending,
  } = useFinanceModelInputsRecalculate();

  const mapOutputData = (outputData: OutputData["data"]) => {
    return outputData.map((row) => {
      return row.map((col) => {
        if (col === null) return "";
        if (typeof col === "object") {
          if (col.m) {
            return col.m;
          }
          return typeof col.v === "number" ? `${col.v}` : col.v;
        }

        return col;
      });
    });
  };

  const handleRecalculateModel = async (purpose: string[]) => {
    const updatedFields = Object.values(updatedCells).reduce<
      { id: number; value: string }[]
    >((allUpdatedFields, updatedFieldsOnSheet) => {
      return [
        ...allUpdatedFields,
        ...Object.values(updatedFieldsOnSheet).map((field) => ({
          id: field.id,
          value: field.newValue,
        })),
      ];
    }, []);

    if (updatedFields.length > 0) {
      await recalculateModel(
        {
          financeModelId: financeModelId ?? -1,
          dto: updatedFields,
          purpose,
        },
        {
          onError: (err) => {
            if (isAxiosError(err)) {
              const errorResponse: { message: string; error: string } =
                err.response?.data;
              notifyError(`${errorResponse.message}. ${errorResponse.error}`);
            }
          },
          onSuccess: (response) => {
            if (response.fetchedData) {
              setFetchedData(response.fetchedData);
            }
            setKey((v) => v + 1);
          },
        }
      );
    } else {
      notifyInfo(
        "Данные не были изменены. Пожалуйста, измените данные для перерасчета."
      );
    }
  };

  const calculatedOutputData = useMemo(() => {
    if (!fetchedData || !selectedSheet) return [];

    return mapOutputData(
      fetchedData.find((tab) => {
        return tab.name.startsWith(selectedSheet);
      })?.data ?? []
    );
  }, [fetchedData, selectedSheet]);

  return {
    isRecalculateModelPending,
    handleRecalculateModel,
    calculatedOutputData,
    mapOutputData,
    key,
    setKey,
  };
};
