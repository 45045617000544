import {
  Button,
  ToggleButton,
  ToggleButtonProps,
  ButtonProps,
} from "@mui/material";
import { styled } from "@mui/system";

interface IconSquareButtonProps {
  isToggle?: boolean;
  selected?: boolean;
}

type CombinedButtonProps = IconSquareButtonProps &
  (ButtonProps | ToggleButtonProps);

const StyledButton = styled(Button)(({ theme }) => ({
  width: "40px",
  height: "40px",
  minWidth: "unset",
  padding: "11px",
  borderRadius: "6px",
  border: "1px solid transparent",
  color: theme.palette.customColors.colorBlack,
  justifyContent: "center",
  "&:hover": {
    backgroundColor: theme.palette.customColors.colorLightBlue100,
  },
  "& .MuiButton-endIcon": {
    marginLeft: 0,
    marginRight: 0,
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: "40px",
  height: "40px",
  padding: "12px",
  minWidth: "unset",
  borderRadius: "6px",
  backgroundColor: "#fff",
  border: "1px solid transparent",
  color: theme.palette.customColors.colorBlack,
  justifyContent: "center",
  "&:hover": {
    backgroundColor: theme.palette.customColors.colorLightBlue100,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.customColors.colorColumbiaBlue,
    color: theme.palette.customColors.colorBrandeisBlue,
    "&:hover": {
      backgroundColor: theme.palette.customColors.colorJordyBlue,
    },
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

export const SquareButtonWithIcon: React.FC<CombinedButtonProps> = ({
  isToggle = false,
  selected,
  ...props
}) => {
  if (isToggle) {
    return (
      <StyledToggleButton
        {...(props as ToggleButtonProps)}
        selected={selected}
      />
    );
  }

  return <StyledButton {...(props as ButtonProps)} />;
};
