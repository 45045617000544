import { api } from "@/shared/config/network";
import { UserChangePasswordDto } from "../model";

export interface ChangePasswordResponse {
  message: string;
}

const changePassword = async (dto: UserChangePasswordDto) => {
  const response = await api.post<ChangePasswordResponse>("/users/change-password", dto);
  return response.data;
};

export { changePassword };
