import { useState, useEffect, useRef, useMemo } from "react";
import { Box, Typography, ToggleButtonGroup } from "@mui/material";
import { useFinanceModels } from "@/entities/FinanceModel";
import { FinanceModelPreviewGrid } from "./FinanceModelPreviewGrid";
import { FinanceModelPreviewList } from "./FinanceModelPreviewList";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import ListIcon from "@/assets/icons/lines.svg?react";
import GridIcon from "@/assets/icons/grid.svg?react";
import { Link } from "react-router-dom";
import { FinanceModelPreviewTitleList } from "./FinanceModelPreviewTitleList";
import { SortMenu } from "@/features/FinanceModel/FinanceModelSortBy";
import { SquareButtonWithIcon } from "@/shared/ui/SquareBtnWithIcon";
import {
  MODELS_SORT_BY_KEY,
  MODELS_VIEW_MODE_KEY,
} from "@/shared/const/localStorageKeys";

const getInitialViewMode = (): "grid" | "list" => {
  const savedView = localStorage.getItem(MODELS_VIEW_MODE_KEY);
  return savedView === "grid" || savedView === "list"
    ? (savedView as "grid" | "list")
    : "grid";
};

const getInitialSortBy = (): "updatedAt" | "createdAt" => {
  const savedSortBy = localStorage.getItem(MODELS_SORT_BY_KEY);

  return savedSortBy === "updatedAt" || savedSortBy === "createdAt"
    ? savedSortBy
    : "updatedAt";
};

export const FinanceModelDashboard = () => {
  const [sortBy, setSortBy] = useState<"updatedAt" | "createdAt">(
    getInitialSortBy
  );
  const [view, setView] = useState<"grid" | "list">(getInitialViewMode);

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useFinanceModels(sortBy);
  const models = useMemo(() => {
    return data ? data.pages.flatMap((page) => page.data) : [];
  }, [data]);

  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
          const loadMore = async () => {
            try {
              await fetchNextPage();
            } catch (error) {
              console.error("Error fetching next page:", error);
            }
          };
          loadMore();
        }
      },
      {
        threshold: 0,
      }
    );

    const currentElement = loadMoreRef.current;

    if (currentElement) {
      observer.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        observer.unobserve(currentElement);
      }
    };
  }, [fetchNextPage, hasNextPage]);

  useEffect(() => {
    localStorage.setItem(MODELS_VIEW_MODE_KEY, view);
  }, [view]);

  useEffect(() => {
    localStorage.setItem(MODELS_SORT_BY_KEY, sortBy);
  }, [sortBy]);

  return (
    <div>
      <Box
        sx={{
          mb: 2,
          padding: "0",
          display: "flex",
          justifyContent: "flex-end",
          gap: "32px",
          "@media (max-width: 1680px)": {
            padding: "0 24px",
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginRight: "auto", paddingLeft: "10px" }}
        >
          Модели
        </Typography>
        <SortMenu currentSort={sortBy} onSortChange={setSortBy} />
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={(_event, newView) => {
            if (newView) setView(newView);
          }}
          aria-label="view mode"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "2px",
          }}
        >
          <SquareButtonWithIcon
            isToggle
            value="grid"
            aria-label="grid view"
            selected={view === "grid"}
            sx={{
              borderBottomRightRadius: "5px !important",
              borderTopRightRadius: "5px !important",
            }}
          >
            <GridIcon width={18} height={18} />
          </SquareButtonWithIcon>
          <SquareButtonWithIcon
            isToggle
            value="list"
            aria-label="list view"
            selected={view === "list"}
            sx={{
              borderBottomLeftRadius: "5px !important",
              borderTopLeftRadius: "5px !important",
            }}
          >
            <ListIcon width={18} height={18} />
          </SquareButtonWithIcon>
        </ToggleButtonGroup>
      </Box>
      <Box
        sx={{
          display: view === "grid" ? "grid" : "block",
          gridTemplateColumns:
            view === "grid" ? "repeat(auto-fit, 304px)" : "none",
          gap: view === "grid" ? "28px" : "0",
          maxWidth: "calc(5 * (304px + 48px))",
          margin: "0 auto",
          "@media (max-width: 1920px)": {
            padding: "0",
          },
          "@media (max-width: 1680px)": {
            padding: "0 24px",
            gridTemplateColumns:
              view === "grid" ? "repeat(auto-fit, 288px)" : "none",
            maxWidth: "calc(5 * (288px + 48px))",
            justifyContent: "center",
          },
        }}
      >
        {isLoading ? (
          <Spinner fullscreen={true} />
        ) : isError ? (
          <Typography>Ошибка загрузки</Typography>
        ) : (
          <>
            {view === "list" ? <FinanceModelPreviewTitleList /> : ""}
            {models.map((model) => {
              return (
                <Box
                  key={model.gridkey}
                  sx={{
                    display: view === "grid" ? "block" : "flex",
                    flexDirection: view === "list" ? "row" : "column",
                    alignItems: view === "list" ? "center" : "start",
                    width: view === "list" ? "100%" : "auto",
                    mb: view === "list" ? 2 : 0,
                  }}
                >
                  <Link
                    to={`/grid/${encodeURIComponent(model.gridkey)}`}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      flexGrow: 1,
                    }}
                  >
                    {view === "grid" ? (
                      <FinanceModelPreviewGrid model={model} />
                    ) : (
                      <FinanceModelPreviewList model={model} />
                    )}
                  </Link>
                </Box>
              );
            })}
          </>
        )}
      </Box>
      {hasNextPage && <div ref={loadMoreRef} style={{ height: "20px" }} />}
      {isFetchingNextPage && (
        <Box sx={{ position: "relative", width: "100%", height: "50px" }}>
          <Spinner />
        </Box>
      )}
    </div>
  );
};

export default FinanceModelDashboard;
