import { api } from "@/shared/config/network";
import { VersionLabelsByIdResponse } from "../model";

export const fetchVersionLabelsById = async (versionId: number) => {
  const response = await api.get<VersionLabelsByIdResponse>(
    `/versions/${versionId}/labels`
  );

  return response.data;
};
