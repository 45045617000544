import { useRef } from "react";
import { CustomSelect, CustomSelectRef } from "@/shared/ui/CustomSelect";
import { Input } from "@/shared/ui/Input/Input";
import {
  Typography,
  Box,
  FormGroup,
  Button,
  FormControlLabel,
  Switch,
  useTheme,
} from "@mui/material";
import { useFinanceModelBackupSettings } from "../../hooks/useFinanceModelBackupsettings";
import type { FinanceModelDto } from "@/entities/FinanceModel";
import { options } from "../config";

interface FinanceModelBackupSettingsProps {
  financeModel: FinanceModelDto;
}

export const FinanceModelBackupSettings = ({
  financeModel,
}: FinanceModelBackupSettingsProps) => {
  const selectRef = useRef<CustomSelectRef>(null);
  const theme = useTheme();

  const { register, handleSubmit, isSubmitting, isValid, errors, setValue } =
    useFinanceModelBackupSettings(financeModel);

  return (
    <Box sx={{ height: "100%", paddingBottom: "32px" }}>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          height: "100%",
        }}
        onSubmit={handleSubmit}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                {...register("backupEnabled")}
                color="primary"
                defaultChecked={financeModel.backupEnabled}
              />
            }
            label="Авто создание резервных копий"
            sx={{ marginLeft: 0 }}
          />
        </FormGroup>
        <FormGroup>
          <Typography sx={{ marginBottom: "8px" }}>
            Сохранять копию модели
          </Typography>
          <CustomSelect
            ref={selectRef}
            options={options}
            setValue={setValue}
            name="backupFrequency"
            defaultValue={financeModel.backupFrequency}
            register={register}
          />
          <Typography
            sx={{
              color: `${theme.palette.customColors.colorBattleshipGray}`,
              maxWidth: "576px",
            }}
          >
            Указывать частоту выполнения бекапов, может быть задана как одна из
            предустановленных опций (каждые 30 минут, каждый час и т.д.).
          </Typography>
        </FormGroup>
        <FormGroup>
          <Typography sx={{ marginBottom: "8px" }}>
            Длительность хранения резервных копий.
          </Typography>
          <Input
            sx={{ width: "100%" }}
            inputProps={{ sx: { padding: "8px 12px" } }}
            placeholder="Укажите кол-во дней"
            defaultValue={financeModel.backupRetentionDays}
            type="number"
            {...register("backupRetentionDays", {
              required: "Введите количество дней",
            })}
          />
          {errors.backupRetentionDays && (
            <p>{errors.backupRetentionDays.message}</p>
          )}
          <Typography
            sx={{
              color: `${theme.palette.customColors.colorBattleshipGray}`,
              maxWidth: "576px",
            }}
          >
            Число дней, на протяжении которых будут храниться версии резервных
            копий.
          </Typography>
        </FormGroup>
        <FormGroup>
          <Typography sx={{ marginBottom: "8px" }}>
            Сколько резервных копий оставлять нетронутыми даже если время
            хранения прошло.
          </Typography>
          <Input
            sx={{ width: "100%" }}
            inputProps={{ sx: { padding: "8px 12px" } }}
            placeholder="Укажите кол-во резервных копий"
            defaultValue={financeModel.backupsToKeep}
            type="number"
            {...register("backupsToKeep", {
              required: "Введите количество резервных копий",
            })}
          />
          {errors.backupsToKeep && <p>{errors.backupsToKeep.message}</p>}
          <Typography
            sx={{
              color: `${theme.palette.customColors.colorBattleshipGray}`,
              maxWidth: "576px",
            }}
          >
            Сколько бекапов оставлять нетронутыми даже если время хранения
            прошло
          </Typography>
        </FormGroup>

        <Button
          variant="lightBlueV300"
          type="submit"
          sx={{ marginTop: "auto" }}
          disabled={isSubmitting || !isValid}
        >
          Сохранить
        </Button>
      </form>
    </Box>
  );
};
