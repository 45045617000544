import { useMutation, useQueryClient } from "@tanstack/react-query";

import { recalculateInputs } from "../api";
import { financeModelInputKeys } from "../const";

const useFinanceModelInputsRecalculate = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: recalculateInputs,
    onSuccess: (...[, { financeModelId }]) => {
      qc.invalidateQueries({
        queryKey: financeModelInputKeys.inputs(financeModelId).queryKey,
      });
    },
  });
};

export { useFinanceModelInputsRecalculate };
