import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createTemplate } from "../api";
import { templateKeys } from "../const";

const useTemplateCreate = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: createTemplate,
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: templateKeys._def,
      });
    },
    onError: (error) => {
      console.error("Ошибка при создании шаблона:", error);
    },
  });
};

export { useTemplateCreate };
