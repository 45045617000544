import { Box, Typography } from "@mui/material";
import type { UserDto } from "@/entities/User";

interface UserDetailsProps {
  user?: UserDto;
}

const UserDetails = ({ user }: UserDetailsProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "2px 8px 2px 4px",
      }}
    >
      <Typography variant="h6" component="div">
        {user?.fullname} - id: {user?.id}
      </Typography>

      <Typography variant="body1" color="textSecondary">
        Логин: {user?.username}
      </Typography>
    </Box>
  );
};

export default UserDetails;
