import {
  FinanceModelSelectSheetsPreview,
  ImportFileResponse,
  useFinanceModelImportFromSheets,
} from "@/entities/FinanceModel";
import { SubmitHandler, useForm } from "react-hook-form";

export const useFinanceModelCreateFromSheets = (
  onSuccess: (model: ImportFileResponse) => void,
  onError: (error: any) => void
) => {
  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    watch,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = useForm<FinanceModelSelectSheetsPreview>({
    mode: "onChange",
    defaultValues: {},
  });

  const { mutateAsync: createModelFromSheets } =
    useFinanceModelImportFromSheets();

  const handleSubmit: SubmitHandler<FinanceModelSelectSheetsPreview> = async (
    form
  ) => {
    try {
      const result = await createModelFromSheets({
        documentId: form.documentId,
        sheetIndexes: form.sheetIds,
      });
      onSuccess(result);
    } catch (error) {
      onError(error);
    }
  };

  return {
    open,
    handleSubmit: handleFormSubmit(handleSubmit),
    register,
    errors,
    isSubmitting,
    setValue,
    isValid,
    reset,
    watch,
  };
};
