import { useMutation } from "@tanstack/react-query";
import { changePassword } from "@/entities/User/api/changePassword";
import { useNotification } from "@/shared/hooks/useNotification";

const useChangePassword = () => {
  const { notifyError, notifySuccess } = useNotification();

  return useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      notifySuccess("Пароль успешно изменён");
    },
    onError: () => {
      notifyError("Ошибка при изменении пароля");
    },
  });
};

export { useChangePassword };