import { useQuery } from "@tanstack/react-query";
import { financeModelInputKeys } from "../const";
import { fetchInputs, fetchInputTabs } from "../api";
import { FinanceModelInputsAllDto } from "../model";

const useBiFinanceModelInputsAll = ({
  financeModelId = -1,
  enabled = true,
}: {
  financeModelId?: number;
  enabled?: boolean;
}) => {
  return useQuery<FinanceModelInputsAllDto[]>({
    queryKey: financeModelInputKeys.inputs(financeModelId).queryKey,
    queryFn: async () => {
      const { biInputTabs, biOutputTabs } = await fetchInputTabs({
        financeModelId,
      });

      return Promise.all([
        ...biInputTabs.map(async (tabName) => {
          const d = await fetchInputs({ financeModelId, tabName, purpose: "bi" });
          return { tabName, data: d, type: "input" };
        }),
        ...biOutputTabs.map((tabName) => {
          return { tabName, data: [], type: "output" };
        }),
      ]);
    },
    enabled,
  });
};

export { useBiFinanceModelInputsAll };
