import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { v4 as uuid } from "uuid";
import {
  type FinanceModelCreateDto,
  FinanceModelDto,
  useFinanceModelCreate,
} from "@/entities/FinanceModel";
import {
  TemplateUseDto,
  useTemplateForCreateModel,
} from "@/entities/Templates";

export const useFinanceModelCreateModal = (
  onSuccess?: (model: FinanceModelDto | TemplateUseDto) => void,
  onError?: (error: any) => void
) => {
  const { mutateAsync: createModel } = useFinanceModelCreate();
  const { mutateAsync: createModelByTemplate } = useTemplateForCreateModel();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = useForm<FinanceModelCreateDto>({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      formulaEngine: "hypersheet",
      // gridkey: uuid(),   // gridkey provided by the server
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset({ gridkey: uuid() });
    setOpen(false);
  };

  const handleSubmit: SubmitHandler<FinanceModelCreateDto> = async (data) => {
    try {
      let res;
      if (data?.templateId) {
        res = await createModelByTemplate({
          templateId: data?.templateId,
          name: data.name,
          description: data.description,
          formulaEngine: data.formulaEngine,
        });
      } else {
        res = await createModel(data);
      }
      handleClose();
      if (onSuccess) {
        onSuccess(res);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  return {
    open,
    handleOpen,
    handleClose,
    handleSubmit: handleFormSubmit(handleSubmit),
    register,
    errors,
    isSubmitting,
    setValue,
    isValid,
    reset,
  };
};
