import { AUTH_TOKEN_KEY } from "@/shared/const/localStorageKeys";
import { useQuery } from "@tanstack/react-query";
import { fetchUserProfile } from "../api";
import { userKeys } from "../const";

const useUserProfile = () => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);

  return useQuery({
    queryKey: userKeys.user.queryKey,
    queryFn: fetchUserProfile,
    staleTime: 1000 * 60 * 5,
    enabled: !!token,
  });
};

export { useUserProfile };
