import { api } from "@/shared/config/network";
import { DeleteLabelFromVersionResponse } from "../model";

export const deleteLabelFromVersion = async ({
  versionId,
  labelId,
}: {
  versionId: number;
  labelId: number;
}) => {
  const response = await api.delete<DeleteLabelFromVersionResponse>(
    `/versions/${versionId}/labels/${labelId}`
  );

  return response.data;
};
