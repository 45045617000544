import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { HeaderLayout } from "@/shared/ui/HeaderLayout";
import { Logo } from "@/shared/ui/Logo";
import { UserMenu, UserMenuGroups } from "@/widgets/UserMenu";
import { ModelMenu, ModelMenuProps } from "@/widgets/ModelMenu";
import { routePaths } from "@/shared/config/routePaths";

interface HeaderProps {
  UserMenuGroups?: UserMenuGroups;
  modelMenuProps?: ModelMenuProps;
  leftSlot?: React.ReactNode;
  middleSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  title?: string;
}

const Header: React.FC<HeaderProps> = ({
  UserMenuGroups,
  modelMenuProps,
  leftSlot,
  middleSlot,
  rightSlot,
  title,
}) => {
  return (
    <HeaderLayout
      leftSlot={
        <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
          <Link to={routePaths.models} style={{ height: "100%" }}>
            <Logo />
          </Link>
          {modelMenuProps && (
            <ModelMenu menuGroups={modelMenuProps.menuGroups} />
          )}
          {leftSlot}
        </Box>
      }
      middleSlot={
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {title && (
            <Typography
              variant="h6"
              noWrap
              sx={{
                color: "var(--colorBlue)",
              }}
            >
              {title}
            </Typography>
          )}
          {middleSlot}
        </Box>
      }
      rightSlot={
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            height: "100%",
          }}
        >
          {rightSlot}
          {UserMenuGroups && (
            <UserMenu
              profileImage={UserMenuGroups.profileImage}
              menuGroups={UserMenuGroups.menuGroups}
              alignment={UserMenuGroups.alignment}
              shouldCloseOnClick={UserMenuGroups.shouldCloseOnClick}
            />
          )}
        </Box>
      }
    />
  );
};

export default Header;
