import { useMutation, useQueryClient } from "@tanstack/react-query";
import { runScenario } from "../api";
import { scenarioKeys } from "../const";

interface RunScenarioResponse {
  message: string;
  persistedSteps: string[];
  totalTime: string;
}

const useRunScenario = () => {
  const queryClient = useQueryClient();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMutation<RunScenarioResponse, Error, string>({
    mutationFn: runScenario,
    onSuccess: (_, id) => {
      queryClient.invalidateQueries({
        queryKey: scenarioKeys.run(id).queryKey,
      });
    },
  });
};

export { useRunScenario };
