import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotification } from "@/shared/hooks/useNotification";
import { versionsKeys } from "@/entities/Version/const";
import { approveAnnotation } from "../api";

const useApproveAnnotation = () => {
  const { notifySuccess, notifyError } = useNotification();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: approveAnnotation,
    onSuccess: (_, { versionId }) => {
      notifySuccess("Форма данных успешно утверждена");
      queryClient.invalidateQueries({
        queryKey: versionsKeys.byVersionId(versionId).queryKey,
      });
    },
    onError: () => {
      notifyError("Не удалось утвердить форму данных");
    },
  });
};

export { useApproveAnnotation };
