import React from "react";
import { FinanceModelDto } from "@/entities/FinanceModel";
import { Badge } from "@/shared/ui/Badge";
import { useBackups } from "@/entities/Backup";
import { badges } from "../config";

interface ModelBadgeProps {
  model: FinanceModelDto;
  customStyles?: React.CSSProperties;
}

export const ModelBadge: React.FC<ModelBadgeProps> = ({
  model,
  customStyles,
}) => {
  const { data: backups = [] } = useBackups({ financeModelId: model.id });

  const visibleBadges = badges
    .filter((badge) => badge.condition(model, backups))
    .sort((a, b) => a.priority - b.priority);

  return (
    <>
      {visibleBadges.length > 0 && (
        <Badge
          text={visibleBadges[0].text}
          textColor={visibleBadges[0].textColor}
          backgroundColor={visibleBadges[0].color}
          customStyles={customStyles}
        />
      )}
    </>
  );
};
