import { FC } from "react";
import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { CustomSelect } from "@/shared/ui/CustomSelect";
import { Input } from "@/shared/ui/Input/Input";
import { useFinanceModelShareForm } from "../../hooks/useFinanceModelShareForm";
import { options } from "../config/index";

interface FinanceModelShareProps {
  isOpen: boolean;
  handleClose: () => void;
  modelId: number;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}

export const FinanceModelShareModal: FC<FinanceModelShareProps> = ({
  isOpen,
  handleClose,
  modelId,
  onSuccess,
  onError,
}) => {
  const { register, handleSubmit, errors, isSubmitting, isValid, setValue } =
    useFinanceModelShareForm(modelId, {
      onSuccess: () => {
        handleClose();
        if (onSuccess) onSuccess();
      },
      onError: (error) => {
        console.error("Error sharing model:", error);
        if (onError) onError(error);
      },
    });

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          minHeight: "350px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h2">
            Поделиться моделью
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          style={{
            height: "100%",
            minHeight: "350px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box visibility={"hidden"} sx={{ height: 0 }}>
            <Input
              placeholder="ID пользователя"
              fullWidth
              margin="normal"
              {...register("userId", {
                required: "ID пользователя обязателен",
              })}
              error={!!errors.userId}
              helperText={errors.userId ? errors.userId.message : ""}
              hidden
              type="text"
              defaultValue={"@anyone"}
            />
          </Box>
          <Typography sx={{ marginTop: "10px" }}>
            Выберите уровень доступа:
          </Typography>
          <CustomSelect
            options={options}
            name="permissions"
            register={register}
            setValue={setValue}
          />
          <Button
            variant="lightBlueV100"
            color="primary"
            type="submit"
            sx={{ width: "100%", marginTop: "auto !important" }}
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? <Spinner withBg={true} /> : "Поделиться"}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
