import { useQuery } from "@tanstack/react-query";

import { getDashboard } from "../api";
import { dashboardKeys } from "../const";

interface UseDashboardParams {
  dashboardId: number;
  enabled?: boolean;
}

const useDashboard = ({ dashboardId, enabled = true }: UseDashboardParams) => {
  return useQuery({
    queryKey: dashboardKeys.detail(dashboardId).queryKey,
    queryFn: () => getDashboard({ dashboardId }),
    enabled,
  });
};

export { useDashboard };
