import { useMemo } from "react";
import { useUserProfile } from "../../User";
import { FinanceModelDto } from "../model";
import { checkIfCanViewOnly } from "../utils";

const useFinanceModelPermissions = (model: FinanceModelDto) => {
  const { data: user } = useUserProfile();
  const permissions = useMemo(() => {
    return {
      viewOnly: checkIfCanViewOnly(user, model),
    };
  }, [user, model]);

  return permissions;
};

export { useFinanceModelPermissions };
