import { api } from "@/shared/config/network";

import type { TemplateCreateDto } from "../model";

interface CreateTemplateParams {
  financeModelId: number;
  name?: string;
  description: string;
}

const createTemplate = async ({
  financeModelId,
  name,
  description,
}: CreateTemplateParams) => {
  const response = await api.post<TemplateCreateDto>("/templates/create", {
    financeModelId,
    name: name ?? new Date().toISOString(),
    description,
  });
  return response.data;
};

export { createTemplate };
