import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { LabelDto } from "@/entities/Label/model";
import { LabelChip } from "./LabelChip";

interface LabelsListProps {
  labels: LabelDto[];
  onDelete?: (id: number) => void;
}

export const LabelsList: FC<LabelsListProps> = ({ labels, onDelete }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        gap: "8px",
      }}
    >
      {labels.map((label) => {
        return <LabelChip label={label} onDelete={onDelete} />;
      })}
      {labels.length === 0 && (
        <Typography variant="body1" color={"textSecondary"}>
          Меток нет
        </Typography>
      )}
    </Box>
  );
};
