import { useState } from "react";
import { Container, Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Logo } from "@/shared/ui/Logo";
import bgImg from "./assets/backgroundAuth.png";
import { Input } from "@/shared/ui/Input/Input";
import { useAuthForm } from "./hooks/useAuthForm";
import { routePaths } from "@/shared/config/routePaths";
import { animated } from "@react-spring/web";
import { useScaleInAnimation } from "@/shared/animations";
import { CustomCheckbox } from "@/shared/ui/CustomCheckbox";

const AuthPage = () => {
  const { register, handleSubmit, errors, isSubmitting } = useAuthForm();
  const [showPassword, setShowPassword] = useState(false);
  const slideInAnimation = useScaleInAnimation(300);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data: any) => {
    try {
      await handleSubmit(data);
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
    }
  };

  return (
    <>
      <header
        style={{
          position: "fixed",
          height: "var(--headerHeight)",
          top: 0,
          left: 0,
          right: 0,
          paddingBlock: "var(--headerPdBlock)",
          paddingInline: "var(--headerPdInline)",
          boxSizing: "border-box",
          backgroundColor: "var(--colorSeaSalt)",
        }}
      >
        <Logo />
      </header>
      <Container
        maxWidth="md"
        style={{
          height: "100dvh",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          justifyContent: "center",
        }}
        className="robotoFont"
      >
        <Box
          component="img"
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100vw",
            height: "100dvh",
            display: "block",
            objectFit: "cover",
            zIndex: "-2",
          }}
          alt="Example Image"
          src={bgImg}
        />
        <animated.div style={{ ...slideInAnimation, width: "100%" }}>
          <form
            onSubmit={onSubmit}
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
            noValidate
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                maxWidth: "436px",
                padding: "32px",
                borderRadius: "8px",
                boxShadow: "var(--boxShadowDarkBlue)",
                gap: "24px",
                backgroundColor: "var(--colorWhite)",

                "@media (max-width:768px)": {
                  padding: "23px",
                },
              }}
            >
              <Typography
                variant="h4"
                className="interFont"
                sx={{
                  fontWeight: 600,
                  fontSize: "24px",
                  lineHeight: "100%",
                }}
              >
                Вход
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Input
                  label="Электронная почта или телефон"
                  {...register("username")}
                  error={!!errors.username}
                  helperText={errors.username?.message || ""}
                  sx={{ marginBottom: "16px" }}
                />
                <Input
                  label="Пароль"
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  error={!!errors.password}
                  helperText={errors.password?.message || ""}
                  showPassword={showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <CustomCheckbox
                    label="Запомнить аккаунт"
                    sx={{
                      margin: 0,
                      "@media (max-width:768px)": {
                        "& .MuiTypography-root": {
                          width: "min-content",
                        },
                      },
                    }}
                  />
                  <Box>
                    <Link
                      to={routePaths.forgotPassword}
                      style={{
                        textDecoration: "none",
                        color: "var(--colorBlue)",
                        fontWeight: 500,
                        fontSize: "16px",
                        lineHeight: "150%",
                      }}
                    >
                      Забыли пароль?
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Button
                  variant="lightBlueV100"
                  color="primary"
                  type="submit"
                  sx={{ mt: 2 }}
                  disabled={isSubmitting}
                >
                  Войти
                </Button>
              </Box>
              <Box>
                <Link
                  to={routePaths.register}
                  style={{
                    textDecoration: "none",
                    color: "var(--colorBlue)",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "150%",
                  }}
                >
                  Зарегистрироваться
                </Link>
              </Box>
            </Box>
          </form>
        </animated.div>
      </Container>
    </>
  );
};

export default AuthPage;
