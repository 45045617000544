export const base64ToFile = (base64: string, fileName: string): File => {
  const [header, data] = base64.split(",");

  // Проверяем наличие данных в header и data
  if (!header || !data) {
    throw new Error("Invalid base64 string format");
  }

  // Извлекаем MIME тип из header
  const mimeMatch = header.match(/:(.*?);/);
  if (!mimeMatch || !mimeMatch[1]) {
    throw new Error("MIME type not found in base64 string");
  }
  const mime = mimeMatch[1];

  // Декодируем base64 строку в бинарный формат
  const binaryString = atob(data);
  const arrayBuffer = new ArrayBuffer(binaryString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }

  // Создаем Blob из бинарных данных
  const blob = new Blob([uint8Array], { type: mime });

  // Создаем и возвращаем объект File
  return new File([blob], fileName, { type: mime });
};
