import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/system";
import { SelectChangeEvent } from "@mui/material/Select";
import { UseFormSetValue, FieldValues } from "react-hook-form";

interface CustomSelectProps<T extends FieldValues> {
  options: Array<{ value: string; label: string; disabled?: boolean }>;
  label?: string;
  name?: keyof T;
  register?: any;
  setValue?: UseFormSetValue<T>;
  defaultValue?: string | null;
}

export interface CustomSelectRef {
  getValue: () => string;
  setValue: (value: string) => void;
}

const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    fontSize: "16px",
    lineHeight: "150%",
    borderRadius: "6px",
    padding: "8px 12px",
    border: "1px solid #cbdeef",
    color: "black",
    "&:focus": {
      border: `1px solid ${theme.palette.customColors.colorCelestialBlue}`,
    },
    "&:hover": {
      border: `1px solid ${theme.palette.customColors.colorCelestialBlue}`,
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "16px",
  lineHeight: "150%",
  padding: "8px 12px",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: theme.palette.customColors.colorAliceBlue,
  },
  "&.Mui-selected": {
    backgroundColor: theme.palette.customColors.colorJordyBlue,
    "&:hover": {
      backgroundColor: theme.palette.customColors.colorJordyBlue,
    },
  },
}));

export const CustomSelect = forwardRef<CustomSelectRef, CustomSelectProps<any>>(
  (
    {
      options,
      label,
      name,
      register,
      setValue: setValueForInput,
      defaultValue,
    },
    ref
  ) => {
    const [selectedValue, setSelectedValue] = useState<string>(
      defaultValue || options[0]?.value || ""
    );

    useImperativeHandle(ref, () => ({
      getValue: () => selectedValue,
      setValue: (value: string) => setSelectedValue(value),
    }));

    const handleChange = (event: SelectChangeEvent<unknown>) => {
      const value = event.target.value as string;
      setSelectedValue(value);
      if (name && setValueForInput) {
        setValueForInput(name as string, value);
      }
    };

    useEffect(() => {
      if (name && setValueForInput) {
        setValueForInput(name as string, selectedValue);
      }
    }, [name, selectedValue, setValueForInput]);

    return (
      <FormControl variant="outlined" fullWidth>
        {label && <InputLabel>{label}</InputLabel>}
        <StyledSelect
          value={selectedValue}
          onChange={handleChange}
          MenuProps={{
            PaperProps: {
              sx: {
                paddingTop: 0,
                paddingBottom: 0,
                borderRadius: "6px",
                boxShadow: "4px 4px 56px 0 rgba(80, 158, 227, 0.18)",
              },
            },
            sx: {
              "& .MuiList-padding": {
                paddingTop: 0,
                paddingBottom: 0,
              },
            },
          }}
        >
          {options.map((option) => (
            <StyledMenuItem
              key={option.value}
              value={option.value}
              disabled={option?.disabled}
              disableRipple
            >
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledSelect>
        {name && register && (
          <input type="hidden" value={selectedValue} {...register(name)} />
        )}
      </FormControl>
    );
  }
);

CustomSelect.displayName = "CustomSelect";
