import { useQuery } from "@tanstack/react-query";
import { fetchVersionById } from "../api";
import { versionsKeys } from "../const";
import { VersionDto } from "../model";

export const useVersionById = (versionId: number) => {
  return useQuery<VersionDto, Error>({
    queryKey: versionsKeys.byVersionId(versionId).queryKey,
    queryFn: () => fetchVersionById(versionId),
    enabled: !!versionId,
  });
};
