import type { ListDto } from "@/shared/interfaces/network";
import { api } from "@/shared/config/network";
import type { FinanceModelDto } from "../model";

const fetchFinanceModels = async (sortBy: string, page: number = 1) => {
  const params = new URLSearchParams({
    sortBy,
    order: "DESC",
    page: String(page),
    pageSize: "10",
  });

  const response = await api.get<ListDto<FinanceModelDto>>(
    `/finance-models?${params.toString()}`
  );

  return {
    data: response.data.data,
    currentPage: response.data.meta.currentPage,
    totalPages: response.data.meta.totalPages,
  };
};

export { fetchFinanceModels };
