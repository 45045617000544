import { useState, FC, useMemo, useEffect } from "react";
import { Box, Avatar, Typography, Button, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import orgModelImg from "@/assets/images/orgModelGreen.png";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { useSwitchCompany } from "@/entities/Company/queries/useSwitchCompany";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";
import { useNotification } from "@/shared/hooks/useNotification";

interface CompanySwitcherProps {
  showOnlyActiveOrganization?: boolean;
}

export const CompanySwitcher: FC<CompanySwitcherProps> = ({
  showOnlyActiveOrganization = false,
}) => {
  const theme = useTheme();
  const { notifySuccess } = useNotification();
  const { data: user, isLoading, isError } = useUserProfile();
  const { mutate: switchCompany, isPending: isSwitching } = useSwitchCompany();
  const [selectedOrgId, setSelectedOrgId] = useState<number | null>(
    user?.lastUsedOrganizationId ?? null
  );

  useEffect(() => {
    if (user?.lastUsedOrganizationId) {
      setSelectedOrgId(user.lastUsedOrganizationId);
    }
  }, [user?.lastUsedOrganizationId]);

  const handleOrgClick = (id: number, name: string) => {
    switchCompany(id, {
      onSuccess: () => {
        setSelectedOrgId(id);
        notifySuccess(`Вы переключили проект на ${name}`, {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      },
    });
  };

  const organizationsToShow = useMemo(
    () =>
      showOnlyActiveOrganization
        ? user?.organizationsWithRoles
            .map((org) => org.organization)
            .filter((org) => org.id === selectedOrgId)
        : user?.organizationsWithRoles.map((org) => org.organization) || [],
    [user, selectedOrgId, showOnlyActiveOrganization]
  );

  if (isLoading || isSwitching) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <Spinner />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
        }}
      >
        <Alert severity="error">
          Произошла ошибка при загрузке организаций.
        </Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{ display: "flex", gap: "0", flexDirection: "column", width: "100%" }}
    >
      {organizationsToShow?.map((org) => (
        <Button
          key={org.id}
          onClick={() => handleOrgClick(org.id, org.name)}
          disabled={selectedOrgId === org.id}
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderRadius: "8px",
            gap: "8px",
            padding: "2px 8px 2px 10px",
            transition: "none",
            color: "black",
            fontSize: "14px",
            lineHeight: "114%",
            "&:hover": {
              backgroundColor: "#E3EFFA",
              color: theme.palette.customColors.colorBrandeisBlue,
            },
            "&.Mui-disabled": {
              opacity: 1,
              cursor: "not-allowed",
              color: "black",
            },
          }}
        >
          <Box
            sx={{
              padding: "2px",
              outline: selectedOrgId === org.id ? "1px solid blue" : "none",
              borderRadius: "50%",
            }}
          >
            <Avatar
              src={orgModelImg}
              alt={org.name}
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          </Box>
          <Typography
            variant="body1"
            sx={{ marginLeft: "8px", flexGrow: 1, textAlign: "left" }}
          >
            {org.name}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};
