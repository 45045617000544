import React, {
  MouseEvent,
  HTMLAttributes,
  FC,
  useMemo,
  useCallback,
} from "react";
import { Box, IconButton, Tooltip, Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UniversalMenu } from "@/shared/ui/UniversalMenu";
import ArrowBottomIcon from "@/assets/icons/arrowBottom.svg?react";
import { MenuItemProps } from "@/shared/ui/UniversalMenu/ui/UniversalMenu.types";

interface UserMenuItemProps extends Omit<MenuItemProps, "onClick"> {
  onClick?: (event: MouseEvent<HTMLLIElement>, item: UserMenuItemProps) => void;
}

interface UserMenuProps {
  profileImage?: string;
  menuGroups: {
    items: UserMenuItemProps[];
    divider?: boolean;
  }[];
  alignment?: "left" | "right";
  extraAttrs?: HTMLAttributes<HTMLDivElement>;
  shouldCloseOnClick?: boolean;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  backgroundColor: theme.palette.customColors.colorLightBlue200,
}));

const IconButtonWithArrow = styled(IconButton)(() => ({
  position: "relative",
  padding: 0,
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "none", // Убираем эффект Ripple
  },
}));

export const UserMenu: FC<UserMenuProps> = ({
  profileImage,
  menuGroups,
  alignment = "right",
  extraAttrs = {},
  shouldCloseOnClick = true,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMenuItemClick = useCallback(
    (event: MouseEvent<HTMLLIElement>, item: UserMenuItemProps) => {
      // Skip closing the menu on element click
      // default - close
      if (item.shouldCloseOnClick ?? true) {
        handleClose();
      }

      item.onClick?.(event, item);
    },
    [shouldCloseOnClick, handleClose]
  );

  const updatedMenuGroups = useMemo(() => {
    return menuGroups.map((group) => ({
      ...group,
      items: group.items.map((item) => ({
        ...item,
        onClick: (event: MouseEvent<HTMLLIElement>) => {
          handleMenuItemClick(event, item);
        },
      })),
    }));
  }, [menuGroups, handleMenuItemClick]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} {...extraAttrs}>
      <Tooltip title="Открыть меню">
        <IconButtonWithArrow
          onClick={handleClick}
          disableRipple
          sx={{ gap: "16px" }}
        >
          {profileImage ? <Avatar src={profileImage} /> : <StyledAvatar />}
          <ArrowBottomIcon
            width={18}
            height={10}
            className="arrow-icon"
            fontSize="small"
          />
        </IconButtonWithArrow>
      </Tooltip>
      <UniversalMenu
        triggerElement={<div />} // Триггер для меню, можно оставить пустым
        groups={updatedMenuGroups}
        alignment={alignment}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        shouldCloseOnClick={shouldCloseOnClick}
      />
    </Box>
  );
};
