import { FC } from "react";
import { Card, CardContent, Box, Typography, CardMedia } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ScenarioDTO } from "../model";
import HubIcon from "@mui/icons-material/Hub";
import defaultScenariosImg from "../assets/defautBgScenarios.jpg";

interface ScenarioCardProps {
  model: ScenarioDTO;
}

export const ScenarioCardPreviewGrid: FC<ScenarioCardProps> = ({ model }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        minWidth: 288,
        minHeight: 270,
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        boxShadow: "none",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.01)",
          boxShadow: theme.palette.customShadows.lightBlue,
        },
        cursor: "pointer",
        position: "relative",
        gap: "0",
      }}
    >
      <Box sx={{ position: "relative" }}>
        {
          <Box
            sx={{
              position: "absolute",
              top: "8px",
              left: "8px",
              width: "40px",
              height: "40px",
              padding: "4px",
              backgroundColor: "white",
              borderRadius: "50%",
              boxShadow:
                "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <HubIcon color="primary" />
          </Box>
        }

        <CardMedia
          component="img"
          height="128"
          image={defaultScenariosImg}
          sx={{
            marginBottom: "16px",
            border: "1px solid #cbdeef",
            background: "white",
            borderRadius: "8px",
            maxWidth: "100%",
            maxHeight: "unset",
            minWidth: "unset",
            height: "128px",
          }}
        />

        {/* TODO: добавить в будущем */}
        {/* <ModelBadge model={model} /> */}
      </Box>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: 0,
          paddingBottom: "0 !important",
          justifyContent: "unset",
          alignItems: "unset",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "150%",
            color: "#000",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            width: "auto",
            marginRight: "unset",
          }}
        >
          {model.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "150%",
            color: "#000",
            mb: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {model.description}
        </Typography>
      </CardContent>
    </Card>
  );
};
