// export const paths1 = [
//   {
//     d: "M19.3103 3.65517C19.3103 5.17872 18.0753 6.41379 16.5517 6.41379C15.0282 6.41379 13.7931 5.17872 13.7931 3.65517C13.7931 2.13163 15.0282 0.896553 16.5517 0.896553C18.0753 0.896553 19.3103 2.13163 19.3103 3.65517Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M26.2069 3.65517C26.2069 5.17872 24.9718 6.41379 23.4483 6.41379C21.9247 6.41379 20.6897 5.17872 20.6897 3.65517C20.6897 2.13163 21.9247 0.896553 23.4483 0.896553C24.9718 0.896553 26.2069 2.13163 26.2069 3.65517Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M33.1034 3.65517C33.1034 5.17872 31.8684 6.41379 30.3448 6.41379C28.8213 6.41379 27.5862 5.17872 27.5862 3.65517C27.5862 2.13163 28.8213 0.896553 30.3448 0.896553C31.8684 0.896553 33.1034 2.13163 33.1034 3.65517Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M5.51724 10.5517C5.51724 12.0753 4.28216 13.3103 2.75862 13.3103C1.23508 13.3103 0 12.0753 0 10.5517C0 9.02818 1.23508 7.79311 2.75862 7.79311C4.28216 7.79311 5.51724 9.02818 5.51724 10.5517Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M12.4138 10.5517C12.4138 12.0753 11.1787 13.3103 9.65517 13.3103C8.13163 13.3103 6.89655 12.0753 6.89655 10.5517C6.89655 9.02818 8.13163 7.79311 9.65517 7.79311C11.1787 7.79311 12.4138 9.02818 12.4138 10.5517Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M19.3103 10.5517C19.3103 12.0753 18.0753 13.3103 16.5517 13.3103C15.0282 13.3103 13.7931 12.0753 13.7931 10.5517C13.7931 9.02818 15.0282 7.79311 16.5517 7.79311C18.0753 7.79311 19.3103 9.02818 19.3103 10.5517Z",
//     fill: "var(--colorBlue)",
//   },
//   {
//     d: "M26.2069 10.5517C26.2069 12.0753 24.9718 13.3103 23.4483 13.3103C21.9247 13.3103 20.6897 12.0753 20.6897 10.5517C20.6897 9.02818 21.9247 7.79311 23.4483 7.79311C24.9718 7.79311 26.2069 9.02818 26.2069 10.5517Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M33.1034 10.5517C33.1034 12.0753 31.8684 13.3103 30.3448 13.3103C28.8213 13.3103 27.5862 12.0753 27.5862 10.5517C27.5862 9.02818 28.8213 7.79311 30.3448 7.79311C31.8684 7.79311 33.1034 9.02818 33.1034 10.5517Z",
//     fill: "var(--colorBlue)",
//   },
//   {
//     d: "M5.51724 17.4483C5.51724 18.9718 4.28216 20.2069 2.75862 20.2069C1.23508 20.2069 0 18.9718 0 17.4483C0 15.9247 1.23508 14.6897 2.75862 14.6897C4.28216 14.6897 5.51724 15.9247 5.51724 17.4483Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M12.4138 17.4483C12.4138 18.9718 11.1787 20.2069 9.65517 20.2069C8.13163 20.2069 6.89655 18.9718 6.89655 17.4483C6.89655 15.9247 8.13163 14.6897 9.65517 14.6897C11.1787 14.6897 12.4138 15.9247 12.4138 17.4483Z",
//     fill: "var(--colorBlue)",
//   },
//   {
//     d: "M19.3103 17.4483C19.3103 18.9718 18.0753 20.2069 16.5517 20.2069C15.0282 20.2069 13.7931 18.9718 13.7931 17.4483C13.7931 15.9247 15.0282 14.6897 16.5517 14.6897C18.0753 14.6897 19.3103 15.9247 19.3103 17.4483Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M26.2069 17.4483C26.2069 18.9718 24.9718 20.2069 23.4483 20.2069C21.9247 20.2069 20.6897 18.9718 20.6897 17.4483C20.6897 15.9247 21.9247 14.6897 23.4483 14.6897C24.9718 14.6897 26.2069 15.9247 26.2069 17.4483Z",
//     fill: "var(--colorBlue)",
//   },
//   {
//     d: "M33.1034 17.4483C33.1034 18.9718 31.8684 20.2069 30.3448 20.2069C28.8213 20.2069 27.5862 18.9718 27.5862 17.4483C27.5862 15.9247 28.8213 14.6897 30.3448 14.6897C31.8684 14.6897 33.1034 15.9247 33.1034 17.4483Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M5.51724 24.3448C5.51724 25.8684 4.28216 27.1034 2.75862 27.1034C1.23508 27.1034 0 25.8684 0 24.3448C0 22.8213 1.23508 21.5862 2.75862 21.5862C4.28216 21.5862 5.51724 22.8213 5.51724 24.3448Z",
//     fill: "var(--colorBlue)",
//   },
//   {
//     d: "M12.4138 24.3448C12.4138 25.8684 11.1787 27.1034 9.65517 27.1034C8.13163 27.1034 6.89655 25.8684 6.89655 24.3448C6.89655 22.8213 8.13163 21.5862 9.65517 21.5862C11.1787 21.5862 12.4138 22.8213 12.4138 24.3448Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M19.3103 24.3448C19.3103 25.8684 18.0753 27.1034 16.5517 27.1034C15.0282 27.1034 13.7931 25.8684 13.7931 24.3448C13.7931 22.8213 15.0282 21.5862 16.5517 21.5862C18.0753 21.5862 19.3103 22.8213 19.3103 24.3448Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M26.2069 24.3448C26.2069 25.8684 24.9718 27.1034 23.4483 27.1034C21.9247 27.1034 20.6897 25.8684 20.6897 24.3448C20.6897 22.8213 21.9247 21.5862 23.4483 21.5862C24.9718 21.5862 26.2069 22.8213 26.2069 24.3448Z",
//     fill: "var(--colorLightBlue200)",
//   },
//   {
//     d: "M39.7586 3.74138C39.7586 5.26492 38.5235 6.5 37 6.5C35.4765 6.5 34.2414 5.26492 34.2414 3.74138C34.2414 2.21783 35.4765 0.982759 37 0.982759C38.5235 0.982759 39.7586 2.21783 39.7586 3.74138Z",
//     fill: "var(--colorBlue)",
//   },
// ];

export const paths = [
  {
    d: "M5 2.5C5 3.88071 3.88071 5 2.5 5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0C3.88071 0 5 1.11929 5 2.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M12 2.5C12 3.88071 10.8807 5 9.5 5C8.11929 5 7 3.88071 7 2.5C7 1.11929 8.11929 0 9.5 0C10.8807 0 12 1.11929 12 2.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M19 2.5C19 3.88071 17.8807 5 16.5 5C15.1193 5 14 3.88071 14 2.5C14 1.11929 15.1193 0 16.5 0C17.8807 0 19 1.11929 19 2.5Z",
    fill: "#0273F8",
  },
  {
    d: "M26 2.5C26 3.88071 24.8807 5 23.5 5C22.1193 5 21 3.88071 21 2.5C21 1.11929 22.1193 0 23.5 0C24.8807 0 26 1.11929 26 2.5Z",
    fill: "#0273F8",
  },
  {
    d: "M33 2.5C33 3.88071 31.8807 5 30.5 5C29.1193 5 28 3.88071 28 2.5C28 1.11929 29.1193 0 30.5 0C31.8807 0 33 1.11929 33 2.5Z",
    fill: "#0273F8",
  },
  {
    d: "M40 2.5C40 3.88071 38.8807 5 37.5 5C36.1193 5 35 3.88071 35 2.5C35 1.11929 36.1193 0 37.5 0C38.8807 0 40 1.11929 40 2.5Z",
    fill: "#0273F8",
  },
  {
    d: "M5 9.5C5 10.8807 3.88071 12 2.5 12C1.11929 12 0 10.8807 0 9.5C0 8.11929 1.11929 7 2.5 7C3.88071 7 5 8.11929 5 9.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M12 9.5C12 10.8807 10.8807 12 9.5 12C8.11929 12 7 10.8807 7 9.5C7 8.11929 8.11929 7 9.5 7C10.8807 7 12 8.11929 12 9.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M19 9.5C19 10.8807 17.8807 12 16.5 12C15.1193 12 14 10.8807 14 9.5C14 8.11929 15.1193 7 16.5 7C17.8807 7 19 8.11929 19 9.5Z",
    fill: "#0273F8",
  },
  {
    d: "M26 9.5C26 10.8807 24.8807 12 23.5 12C22.1193 12 21 10.8807 21 9.5C21 8.11929 22.1193 7 23.5 7C24.8807 7 26 8.11929 26 9.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M33 9.5C33 10.8807 31.8807 12 30.5 12C29.1193 12 28 10.8807 28 9.5C28 8.11929 29.1193 7 30.5 7C31.8807 7 33 8.11929 33 9.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M40 9.5C40 10.8807 38.8807 12 37.5 12C36.1193 12 35 10.8807 35 9.5C35 8.11929 36.1193 7 37.5 7C38.8807 7 40 8.11929 40 9.5Z",
    fill: "#0273F8",
  },
  {
    d: "M5 16.5C5 17.8807 3.88071 19 2.5 19C1.11929 19 0 17.8807 0 16.5C0 15.1193 1.11929 14 2.5 14C3.88071 14 5 15.1193 5 16.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M12 16.5C12 17.8807 10.8807 19 9.5 19C8.11929 19 7 17.8807 7 16.5C7 15.1193 8.11929 14 9.5 14C10.8807 14 12 15.1193 12 16.5Z",
    fill: "#0273F8",
  },
  {
    d: "M19 16.5C19 17.8807 17.8807 19 16.5 19C15.1193 19 14 17.8807 14 16.5C14 15.1193 15.1193 14 16.5 14C17.8807 14 19 15.1193 19 16.5Z",
    fill: "#0273F8",
  },
  {
    d: "M26 16.5C26 17.8807 24.8807 19 23.5 19C22.1193 19 21 17.8807 21 16.5C21 15.1193 22.1193 14 23.5 14C24.8807 14 26 15.1193 26 16.5Z",
    fill: "#0273F8",
  },
  {
    d: "M33 16.5C33 17.8807 31.8807 19 30.5 19C29.1193 19 28 17.8807 28 16.5C28 15.1193 29.1193 14 30.5 14C31.8807 14 33 15.1193 33 16.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M40 16.5C40 17.8807 38.8807 19 37.5 19C36.1193 19 35 17.8807 35 16.5C35 15.1193 36.1193 14 37.5 14C38.8807 14 40 15.1193 40 16.5Z",
    fill: "#0273F8",
  },
  {
    d: "M5 23.5C5 24.8807 3.88071 26 2.5 26C1.11929 26 0 24.8807 0 23.5C0 22.1193 1.11929 21 2.5 21C3.88071 21 5 22.1193 5 23.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M12 23.5C12 24.8807 10.8807 26 9.5 26C8.11929 26 7 24.8807 7 23.5C7 22.1193 8.11929 21 9.5 21C10.8807 21 12 22.1193 12 23.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M19 23.5C19 24.8807 17.8807 26 16.5 26C15.1193 26 14 24.8807 14 23.5C14 22.1193 15.1193 21 16.5 21C17.8807 21 19 22.1193 19 23.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M26 23.5C26 24.8807 24.8807 26 23.5 26C22.1193 26 21 24.8807 21 23.5C21 22.1193 22.1193 21 23.5 21C24.8807 21 26 22.1193 26 23.5Z",
    fill: "#0273F8",
  },
  {
    d: "M33 23.5C33 24.8807 31.8807 26 30.5 26C29.1193 26 28 24.8807 28 23.5C28 22.1193 29.1193 21 30.5 21C31.8807 21 33 22.1193 33 23.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M40 23.5C40 24.8807 38.8807 26 37.5 26C36.1193 26 35 24.8807 35 23.5C35 22.1193 36.1193 21 37.5 21C38.8807 21 40 22.1193 40 23.5Z",
    fill: "#0273F8",
  },
  {
    d: "M5 30.5C5 31.8807 3.88071 33 2.5 33C1.11929 33 0 31.8807 0 30.5C0 29.1193 1.11929 28 2.5 28C3.88071 28 5 29.1193 5 30.5Z",
    fill: "#0273F8",
  },
  {
    d: "M12 30.5C12 31.8807 10.8807 33 9.5 33C8.11929 33 7 31.8807 7 30.5C7 29.1193 8.11929 28 9.5 28C10.8807 28 12 29.1193 12 30.5Z",
    fill: "#0273F8",
  },
  {
    d: "M19 30.5C19 31.8807 17.8807 33 16.5 33C15.1193 33 14 31.8807 14 30.5C14 29.1193 15.1193 28 16.5 28C17.8807 28 19 29.1193 19 30.5Z",
    fill: "#0273F8",
  },
  {
    d: "M26 30.5C26 31.8807 24.8807 33 23.5 33C22.1193 33 21 31.8807 21 30.5C21 29.1193 22.1193 28 23.5 28C24.8807 28 26 29.1193 26 30.5Z",
    fill: "#0273F8",
  },
  {
    d: "M33 30.5C33 31.8807 31.8807 33 30.5 33C29.1193 33 28 31.8807 28 30.5C28 29.1193 29.1193 28 30.5 28C31.8807 28 33 29.1193 33 30.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M40 30.5C40 31.8807 38.8807 33 37.5 33C36.1193 33 35 31.8807 35 30.5C35 29.1193 36.1193 28 37.5 28C38.8807 28 40 29.1193 40 30.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M5 37.5C5 38.8807 3.88071 40 2.5 40C1.11929 40 0 38.8807 0 37.5C0 36.1193 1.11929 35 2.5 35C3.88071 35 5 36.1193 5 37.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M12 37.5C12 38.8807 10.8807 40 9.5 40C8.11929 40 7 38.8807 7 37.5C7 36.1193 8.11929 35 9.5 35C10.8807 35 12 36.1193 12 37.5Z",
    fill: "#0273F8",
  },
  {
    d: "M19 37.5C19 38.8807 17.8807 40 16.5 40C15.1193 40 14 38.8807 14 37.5C14 36.1193 15.1193 35 16.5 35C17.8807 35 19 36.1193 19 37.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M26 37.5C26 38.8807 24.8807 40 23.5 40C22.1193 40 21 38.8807 21 37.5C21 36.1193 22.1193 35 23.5 35C24.8807 35 26 36.1193 26 37.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M33 37.5C33 38.8807 31.8807 40 30.5 40C29.1193 40 28 38.8807 28 37.5C28 36.1193 29.1193 35 30.5 35C31.8807 35 33 36.1193 33 37.5Z",
    fill: "#D7E9FE",
  },
  {
    d: "M40 37.5C40 38.8807 38.8807 40 37.5 40C36.1193 40 35 38.8807 35 37.5C35 36.1193 36.1193 35 37.5 35C38.8807 35 40 36.1193 40 37.5Z",
    fill: "#D7E9FE",
  },
];

export const colors = ["#D7E9FE", "#0273F8"];

export const getRandomColor = (theColors: string[] = colors) =>
  theColors[Math.floor(Math.random() * theColors.length)];
