import { api } from "@/shared/config/network";

import type { BackupCreatedDto } from "../model";

interface CreateBackupParams {
  financeModelId: number;
  name?: string;
  description: string;
}

const createBackup = async ({
  financeModelId,
  name,
  description,
}: CreateBackupParams) => {
  const response = await api.post<BackupCreatedDto>("/backups/create", {
    financeModelId,
    name,
    description,
  });
  return response.data;
};

export { createBackup };
