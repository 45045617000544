import { useQuery } from "@tanstack/react-query";
import { BackupPreviewDto } from "../model";
import { fetchBackupPreviewById } from "../api";

export const useBackupPreview = (id: number | null) => {
  return useQuery<BackupPreviewDto | null, Error>({
    queryKey: ["backup-preview", id],
    queryFn: () => fetchBackupPreviewById({ id }),
    enabled: !!id,
  });
};
