import { useForm, SubmitHandler } from "react-hook-form";
import { useBackupUpdateSettings } from "@/entities/Backup";
import type { FinanceModelDto } from "@/entities/FinanceModel";
import { useNotification } from "@/shared/hooks/useNotification";

interface BackupSettingsForm {
  backupRetentionDays: number;
  backupsToKeep: number;
  backupEnabled: boolean;
  backupFrequency: string;
}

export const useFinanceModelBackupSettings = (
  financeModel: FinanceModelDto,
  onSuccess?: () => void,
  onError?: (error: any) => void
) => {
  const { notifyError, notifySuccess } = useNotification();
  const { mutate: updateBackup, isPending } = useBackupUpdateSettings(
    financeModel.gridkey
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors, isValid },
  } = useForm<BackupSettingsForm>({
    mode: "onChange",
    defaultValues: {
      backupRetentionDays: financeModel.backupRetentionDays || 0,
      backupsToKeep: financeModel.backupsToKeep || 0,
      backupEnabled: financeModel.backupEnabled || false,
      backupFrequency: financeModel.backupFrequency || "",
    },
  });

  const handleUpdate: SubmitHandler<BackupSettingsForm> = async (data) => {
    const updateData = {
      financeModelId: financeModel.id,
      backupData: {
        backupRetentionDays: +data.backupRetentionDays,
        backupsToKeep: +data.backupsToKeep,
        backupFrequency: data.backupFrequency,
        backupEnabled: data.backupEnabled,
      },
    };

    try {
      await updateBackup(updateData);
      notifySuccess("Настройки резервных копий успешно обновлены", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
      notifyError("Ошибка при обновлении данных", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      if (onError) onError(error);
    }
  };

  return {
    register,
    handleSubmit: handleSubmit(handleUpdate),
    isSubmitting,
    isValid,
    isPending,
    errors,
    setValue,
  };
};
