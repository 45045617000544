import { api } from "@/shared/config/network";

interface UploadParams {
  modelId: number;
  file: File;
}

interface UploadResponse {
  screenshotImage: string;
  message: string;
}

const uploadPreview = async ({
  modelId,
  file,
}: UploadParams): Promise<UploadResponse> => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await api.post(
    `/finance-models/${modelId}/screenshot`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return response.data;
};

export { uploadPreview };
