type EventCallback = (data?: any) => void;
type EventsMap = {
  [eventName: string]: EventCallback[];
};

class EventBus {
  private events: EventsMap = {};

  public on(eventName: string, callback: EventCallback): void {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }
    this.events[eventName].push(callback);
  }

  public off(eventName: string, callback: EventCallback): void {
    if (!this.events[eventName]) return;

    this.events[eventName] = this.events[eventName].filter(
      (cb) => cb !== callback
    );

    if (this.events[eventName].length === 0) {
      delete this.events[eventName];
    }
  }

  public emit(eventName: string, data?: any): void {
    if (!this.events[eventName]) return;

    this.events[eventName].forEach((callback) => callback(data));
  }
}

declare global {
  interface Window {
    EventBus: EventBus;
  }
}

(() => {
  if (!window.EventBus) {
    Object.defineProperty(window, "EventBus", {
      value: new EventBus(),
      writable: false,
      configurable: false,
      enumerable: false,
    });
  }
})();
export {}; // Указываем, что файл — модуль, чтобы TypeScript не пытался использовать его как глобальный
