import { api } from "@/shared/config/network";
import {
  AttachLabelToVersionDto,
  AttachLabelToVersionResponse,
} from "../model";

export const attachLabelsToVersion = async ({
  versionId,
  dto,
}: {
  versionId: number;
  dto: AttachLabelToVersionDto;
}) => {
  const response = await api.post<AttachLabelToVersionResponse>(
    `/versions/${versionId}/labels`,
    { labels: dto.versionIds }
  );

  return response.data;
};
