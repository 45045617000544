import { useQuery } from "@tanstack/react-query";
import { getScenario } from "../api";
import { scenarioKeys } from "../const";
import { ScenarioDetail } from "../model";

interface UseScenarioParams {
  id: string;
  enabled?: boolean;
}

const useScenario = ({ id, enabled = true }: UseScenarioParams) => {
  return useQuery<ScenarioDetail, Error>({
    queryKey: scenarioKeys.detail(id).queryKey,
    queryFn: () => getScenario(id),
    enabled,
  });
};

export { useScenario };
