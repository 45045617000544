import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { shareFinanceModel } from "../api/share";
import { FinanceModelShareResponse } from "../model"; // Убедись, что есть правильный тип для запроса

interface ShareModelVariables {
  modelId: number;
  userId?: string | null;
  permissions: string;
}

const useFinanceModelShare = (
  options?: UseMutationOptions<
    FinanceModelShareResponse,
    Error,
    ShareModelVariables
  >
) => {
  return useMutation<FinanceModelShareResponse, Error, ShareModelVariables>({
    mutationFn: shareFinanceModel,
    ...options,
  });
};

export { useFinanceModelShare };
