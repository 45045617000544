import { luckysheetApi } from "@/shared/config/network";

interface ExportFileRequest {
  fileName: string;
  listId: string;
}

const exportFinanceModel = async ({ fileName, listId }: ExportFileRequest) => {
  const response = await luckysheetApi.get(`/export_xls`, {
    params: { fileName, listId },
    responseType: "blob",
  });

  return response.data;
};

export { exportFinanceModel };
