import { type FC } from "react";
import { Box, Typography } from "@mui/material";
import { UserAvatar } from "./UserAvatar";
import { UserDto } from "@/entities/User";
import { SmallAvatar } from "@/shared/ui/SmallAvatar";

interface UserInfoProps {
  user?: UserDto;
}

export const UserInfo: FC<UserInfoProps> = ({ user }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "16px",
        padding: "2px 8px 2px 4px",
      }}
    >
      {user?.avatar ? (
        <UserAvatar src={user?.avatar} />
      ) : (
        <SmallAvatar avatarSrc={user?.avatar || ""} />
      )}
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1px",
          }}
        >
          <Typography>{user?.fullname}</Typography>
          <Typography>{user?.role}123</Typography>
        </Box>

        <Typography sx={{ color: "#727272" }}>{user?.username}</Typography>
      </Box>
    </Box>
  );
};
