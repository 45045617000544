import { useEffect, useRef, FC, useState } from "react";
import { CssBaseline, Box, Typography, Button, Tooltip } from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AxiosError, isAxiosError } from "axios";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";
import Editor, { OnMount } from "@monaco-editor/react";
import Header from "@/widgets/Header";
import { routePaths } from "@/shared/config/routePaths";
import { BreadcrumbsNav } from "@/widgets/BreadcrumbsNav";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import DeleteIcon from "@/assets/icons/delete.svg?react";
import { useNotification } from "@/shared/hooks/useNotification";
import { useUserMenuGroups } from "@/features/User";
import { useScenario } from "@/entities/Scenarios/queries/useScenario";
import { useDeleteScenario } from "@/entities/Scenarios/queries/useDeleteScenario";
import { useUpdateScenario } from "@/entities/Scenarios/queries/useUpdateScenario";
import { useRunScenario } from "@/entities/Scenarios/queries/useRunScenario";
import { ReactFlowWrapper } from "@/widgets/ReactFlowWrapper/ReactFlowWrapper";
import styles from "./ScenarioPage.module.css";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { Modal } from "@/shared/ui/Modal";

function isValidScenario(jsonString: string) {
  try {
    const parsedData = JSON.parse(jsonString);
    if (
      typeof parsedData === "object" &&
      parsedData !== null &&
      Array.isArray(parsedData.nodes)
    ) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

interface RouteParams {
  id: string;
}

const ScenarioPageInner: FC = () => {
  const [isEdited, setIsEdited] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isOpenModalRunResult, setIsOpenModalRunResult] = useState(false);
  const { id: scenarioIdEncoded } = useParams<RouteParams>();
  const { data: user } = useUserProfile();
  const editorRef = useRef<any>(null);
  const { push } = useHistory();
  const { notifyError, notifyInfo, notifySuccess } = useNotification();
  const [showGraph, setShowGraph] = useState(false);

  const handleShowGraph = () => {
    setShowGraph(!showGraph);
  };

  const scenarioId = decodeURIComponent(scenarioIdEncoded ?? "");

  const {
    data: scenario,
    isLoading,
    isError,
    error,
  } = useScenario({ id: scenarioId });

  const {
    mutate: deleteScenario,
    isPending: isPendingDeleteScenario,
    isError: isErrorDeleteScenario,
    isSuccess: isSuccessDeleteScenario,
  } = useDeleteScenario();

  const {
    mutate: updateScenario,
    isPending: isPendingUpdateScenario,
    isError: isErrorUpdateScenario,
    isSuccess: isSuccessUpdateScenario,
  } = useUpdateScenario();

  const {
    mutate: runScenario,
    isSuccess: isSuccessRunScenario,
    isPending: isPendingRunScenario,
    isError: isErrorRunScenario,
    data: responseDataRunScenario,
    error: errorRunScenario,
  } = useRunScenario();

  const scenarioName = scenario?.name || "";
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const fromPage = searchParams.get("fromPage")?.replace("/", "");

  const message: string | undefined = (() => {
    if (!scenarioId) return "Id сценария отсутствует";
    return undefined;
  })();
  const userMenuGroups = useUserMenuGroups();
  const modelMenuGroups = [
    {
      items: scenario
        ? [
            {
              label: isPendingDeleteScenario ? "Удаление" : "Удалить",
              icon: <DeleteIcon width={20} height={20} />,
              onClick: !isPendingDeleteScenario
                ? () => deleteScenario(scenarioId)
                : undefined,
              extraClasses: styles.deleteScenario,
              shouldCloseOnClick: true,
            },
          ]
        : [],
    },
  ];

  const handleEditorDidMount: OnMount = (editor) => {
    editorRef.current = editor;
  };

  const handleEditorChange = (value: string | undefined) => {
    setIsValid(value ? isValidScenario(value) : false);
    if (value !== undefined && scenario?.scenario_json !== value) {
      setIsEdited(true);
    } else {
      setIsEdited(false);
    }
  };

  const getEditorContent = () => {
    if (editorRef.current) {
      const content = editorRef.current.getValue();
      return content;
    }
    return "";
  };

  const handleSave = (
    name: string = scenario?.name || "",
    description: string = scenario?.description || ""
  ) => {
    if (editorRef.current) {
      updateScenario({
        id: scenarioId,
        dto: {
          name,
          description,
          scenario_json: getEditorContent(),
        },
      });
    }
  };

  const handleRun = () => {
    // handleSave();
    setTimeout(() => {
      runScenario(scenarioId);
    }, 500);
  };

  useEffect(() => {
    if (isError) {
      let errorMessage = "Произошла ошибка";
      if (error instanceof AxiosError) {
        errorMessage = error.message || errorMessage;
      } else {
        errorMessage = (error as Error).message || errorMessage;
      }
      console.error(errorMessage);
      notifyError("Сценарий не найден", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
      push(routePaths.models);
    }
  }, [isError]);

  useEffect(() => {
    if (isErrorDeleteScenario) {
      notifyError("Ошибка при удалении сценария");
    }
  }, [isErrorDeleteScenario]);

  useEffect(() => {
    if (isSuccessDeleteScenario) {
      notifyInfo("Сценарий удален");
      push(routePaths.models);
    }
  }, [isSuccessDeleteScenario]);

  useEffect(() => {
    if (isSuccessUpdateScenario) {
      setIsEdited(false);
      notifySuccess("Сценарий сохранен успешно");
    }
  }, [isPendingUpdateScenario, isErrorUpdateScenario]);

  useEffect(() => {
    if (isSuccessRunScenario || isErrorRunScenario) {
      setIsOpenModalRunResult(true);
    }
    if (isSuccessRunScenario) {
      console.debug("Результаты", responseDataRunScenario);
      notifySuccess("Сценарий успешно запущен");
    }
    if (isErrorRunScenario) {
      notifyError("Ошибка при запуске сценария");
    }
  }, [isSuccessRunScenario, isErrorRunScenario]);

  useEffect(() => {
    setIsValid(isValidScenario(scenario?.scenario_json || ""));
  }, [scenario]);

  return (
    <Box
      sx={{
        display: "grid",
        height: "100vh",
        width: "100%",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "var(--headerHeight) 1fr",
        columnGap: 0,
        rowGap: 0,
      }}
    >
      <CssBaseline />
      <Header
        UserMenuGroups={{
          menuGroups: userMenuGroups,
          alignment: "right",
          profileImage: user?.avatar,
        }}
        modelMenuProps={{
          menuGroups: modelMenuGroups,
        }}
        leftSlot={
          <Box
            sx={{
              marginLeft: "16px",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BreadcrumbsNav
              backLink={routePaths.models}
              breadcrumbs={[
                {
                  label: scenario ? String(scenario?.id) : "Загрузка...",
                },
                ...(fromPage
                  ? [
                      {
                        label: scenarioName,
                        path: `${routePaths.scenarios.grid}/${scenarioId}`,
                      },
                    ]
                  : []),
              ]}
              currentPage={{
                label: fromPage ? "Информация" : scenarioName,
                path: fromPage
                  ? `${routePaths.scenarios.grid}/${fromPage}`
                  : undefined,
              }}
            />
          </Box>
        }
        rightSlot={
          scenario && (
            <>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography sx={{ color: isValid ? "green" : "red" }}>
                  {isValid ? "Валидный" : "Невалидный"}
                </Typography>
                <Tooltip title="Валидный: JSON содержит nodes. Невалидный: ошибка в структуре.">
                  <HelpOutlineIcon
                    sx={{
                      color: isValid ? "green" : "red",
                      fontSize: 18,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </Box>
              <Button
                onClick={() => handleRun()}
                sx={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "red",
                }}
              >
                {isPendingRunScenario ? <Spinner /> : "Запустить"}
              </Button>

              {isEdited && (
                <Button
                  variant="lightBlueV300"
                  onClick={() => handleSave()}
                  sx={{
                    padding: "8px 16px",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  Сохранить
                </Button>
              )}
              <Button
                variant="lightBlueV300"
                onClick={handleShowGraph}
                sx={{
                  padding: "8px 16px",
                  fontSize: "14px",
                  lineHeight: "20px",
                }}
              >
                Граф
              </Button>
            </>
          )
        }
      />
      <div
        style={{
          position: "relative",
          gridRow: "2 / -1",
          gridColumn: "1 / -1",
        }}
      >
        {message && (
          <Typography
            m="24px 0 0 0"
            sx={{ textAlign: "center", width: "100%" }}
          >
            {message}
          </Typography>
        )}
        {scenarioId && !isLoading && !showGraph && (
          <Editor
            height="90vh"
            defaultLanguage={"yaml"}
            defaultValue={
              scenario?.scenario_json.toString() === "[object Object]"
                ? "{}"
                : scenario?.scenario_json.toString()
            }
            theme="vs-light"
            onMount={handleEditorDidMount}
            onChange={handleEditorChange}
          />
        )}
        {showGraph && scenario && <ReactFlowWrapper scenario={scenario} />}
      </div>
      <Modal
        title={
          <Typography variant="h6" component="h2">
            {isSuccessRunScenario ? "Результаты" : "Ошибка"} запуска
          </Typography>
        }
        width={600}
        open={isOpenModalRunResult}
        loading={isPendingRunScenario}
        onClose={() => setIsOpenModalRunResult(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "88%",
            height: "100%",
            mb: "16px",
          }}
        >
          {isSuccessRunScenario && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                color: "green",
              }}
            >
              <Typography>{responseDataRunScenario.message}</Typography>
              <Typography>
                Массив сохраненных данных:{" "}
                {responseDataRunScenario.persistedSteps.length > 0
                  ? responseDataRunScenario.persistedSteps.map((table) => table)
                  : "Нет данных по таблицам"}
              </Typography>
              <Typography>
                Время выполнения: {responseDataRunScenario.totalTime}
              </Typography>
            </Box>
          )}
          {isErrorRunScenario && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                color: "red",
              }}
            >
              <Typography>
                {isAxiosError(errorRunScenario) &&
                  errorRunScenario.response?.data.error}
              </Typography>

              <Typography>
                Произошла ошибка при получении результатов:{" "}
                {errorRunScenario.message}
              </Typography>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

const ScenarioPage: FC = () => {
  return <ScenarioPageInner />;
};

export default ScenarioPage;
