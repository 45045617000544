import { api } from "@/shared/config/network";
import { UpdateAnnotationDto } from "../model";

interface UpdateAnnotationParams {
  versionId: number;
  annotation: UpdateAnnotationDto;
}

const updateAnnotation = (
  {
    versionId,
    annotation
  }: UpdateAnnotationParams
) => api.put(`/versions/${versionId}/annotation`, annotation);

export { updateAnnotation };
