import { useMutation, useQueryClient } from "@tanstack/react-query";
import { protectBackup } from "../api/protectBackup";
import { backupKeys } from "../const";

const useBackupProtect = (financeModelId: number) => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: protectBackup,
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: backupKeys.list(financeModelId).queryKey,
      });
    },
  });
};

export { useBackupProtect };
