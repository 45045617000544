import { api } from "@/shared/config/network";

interface RunScenarioResponse {
  message: string;
  persistedSteps: string[];
  totalTime: string;
}

const runScenario = async (id: string): Promise<RunScenarioResponse> => {
  const response = await api.post<RunScenarioResponse>(`/scenarios/${id}/run`);
  return response.data;
};

export { runScenario };
