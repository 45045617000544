import { type FC } from "react";
import { CssBaseline, Box } from "@mui/material";
import { Link } from "react-router-dom";

import Header from "../../widgets/Header";
import Sidebar from "../../widgets/Sidebar";

const Settings: FC = () => {
  return (
    <Box
      sx={{
        display: "grid",
        height: "100vh",
        width: "100%",
        gridTemplateColumns: "240px 1fr",
        gridTemplateRows: "64px 1fr",
        columnGap: 0,
        rowGap: 0,
      }}
    >
      <CssBaseline />
      <Header />
      <Sidebar />

      <div style={{ position: "relative", gridArea: "2 / 2 / 3 / 3" }}>
        Hello from settings page, <Link to="/">root</Link>
      </div>
    </Box>
  );
};

export default Settings;
