import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTemplateCreate } from "@/entities/Templates";
import type { TemplateCreateDto } from "@/entities/Templates";

export const useTemplateCreateModal = (
  onSuccess?: (template: any) => void,
  onError?: (error: any) => void
) => {
  const { mutateAsync: createTemplate } = useTemplateCreate();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = useForm<TemplateCreateDto>({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      financeModelId: 0,
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleSubmit: SubmitHandler<TemplateCreateDto> = async (data) => {
    try {
      const res = await createTemplate(data);
      handleClose();
      if (onSuccess) {
        onSuccess(res);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  return {
    open,
    handleOpen,
    handleClose,
    handleSubmit: handleFormSubmit(handleSubmit),
    register,
    errors,
    isSubmitting,
    setValue,
    isValid,
    reset,
  };
};
