import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { format } from "date-fns";

const LAST_UPDATE_TIME = "wsSendSuccess";
const PARTS_INFO = "partsInfo";

type PartsInfoType = {
  remaining: number;
  waiting: number;
};

export const ModelEditingStatus: React.FC = () => {
  const [lastEditedTime, setLastEditedTime] = useState<Date | null>(null);
  const [remaining, setRemaining] = useState<number>();
  const [waiting, setWaiting] = useState<number>();
  const theme = useTheme();

  const setLastEdit = (time: number) => {
    setLastEditedTime(new Date(time));
  };

  const updatePartsInfo = (partsInfo: PartsInfoType) => {
    const { remaining = 0, waiting = 0 } = partsInfo || {};
    setRemaining(remaining);
    setWaiting(waiting);
  };

  useEffect(() => {
    window.EventBus?.on(LAST_UPDATE_TIME, setLastEdit);
    window.EventBus?.on(PARTS_INFO, updatePartsInfo);

    return () => {
      window.EventBus?.off(LAST_UPDATE_TIME, setLastEdit);
      window.EventBus?.off(PARTS_INFO, updatePartsInfo);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        minWidth: 250,
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          fontSize: 14,
          color: theme.palette.customColors.colorTaupeGray,
        }}
      >
        {lastEditedTime
          ? `Последнее изменение: ${format(lastEditedTime, " HH:mm:ss")}`
          : ""}
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: 12,
            fontWeight: "500",
            color: theme.palette.customColors.colorTaupeGray,
          }}
        >
          {waiting ? `В очереди: ${waiting}` : ""}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: 12,
            fontWeight: "500",
            color: theme.palette.customColors.colorTaupeGray,
          }}
        >
          {remaining ? `Сохраняются: ${remaining}` : ""}
        </Typography>
      </Box>
    </Box>
  );
};
