/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editFinanceModel } from "../api";
import { financeModelKeys } from "../const";

//TODO: need refactor gridKey!
const useFinanceModelEdit = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      id,
      name,
      description,
      sourceCode,
      // @ts-expect-error:
      gridKey,
    }: {
      id: number;
      name?: string;
      description?: string;
      gridKey: string;
      sourceCode?: string;
    }) => editFinanceModel(id, { name, description, sourceCode }),

    onSuccess: (_, { gridKey }) => {
      queryClient.invalidateQueries(financeModelKeys.inputs(gridKey));
    },
  });
};

export { useFinanceModelEdit };
