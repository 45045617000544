import { useQueryClient } from "@tanstack/react-query";
import { versionsKeys } from "../const";

const useInvalidateVersionList = (financeModelId: number) => {
  const qc = useQueryClient();

  return () => qc.invalidateQueries({ queryKey: versionsKeys.byModelId(financeModelId).queryKey });
};

export { useInvalidateVersionList };
