import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotification } from "@/shared/hooks/useNotification";
import { financeModelInputKeys } from "@/entities/FinanceModelInput/const";
import { restoreVersionToCurrent } from "../api";

const useRestoreVersionToCurrent = (financeModelId?: number) => {
  const qc = useQueryClient();
  const { notifySuccess, notifyError } = useNotification();

  return useMutation({
    mutationFn: restoreVersionToCurrent,

    onSuccess: () => {
      if (financeModelId) {
        qc.invalidateQueries({ queryKey: financeModelInputKeys.inputs(financeModelId).queryKey });
      }

      notifySuccess("Данные обновлены");
    },

    onError: () => notifyError("Не удалось восстановить версию")
  });
};

export { useRestoreVersionToCurrent };
