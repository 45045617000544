import { FC } from "react";
import { Box, Chip } from "@mui/material";
import { LabelDto } from "@/entities/Label/model";
import CrossIcon from "@/assets/icons/xMark.svg?react";

interface LabelChipProps {
  label: LabelDto;
  onDelete?: (id: number) => void;
}

export const LabelChip: FC<LabelChipProps> = ({ label, onDelete }) => {
  return (
    <Chip
      key={label.id}
      sx={{
        "> span": {
          display: "flex",
        },
        borderRadius: "6px",
        height: "24px",
        background: label.color,
      }}
      label={
        <>
          <Box>{label.icon}</Box>
          <Box sx={{ padding: "0 8px" }}>{label.name}</Box>
          {onDelete && (
            <CrossIcon
              onClick={() => onDelete(label.id)}
              width={16}
              height={16}
              style={{ alignSelf: "center", cursor: "pointer", color: "white" }}
            />
          )}
        </>
      }
    />
  );
};
