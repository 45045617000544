import { useMutation, useQueryClient } from "@tanstack/react-query";
import { switchCompany } from "../api";
import { useNotification } from "@/shared/hooks/useNotification";
import { userKeys } from "@/entities/User/const";
import { financeModelKeys } from "@/entities/FinanceModel/const";
import { scenarioKeys } from "@/entities/Scenarios/const";

const useSwitchCompany = () => {
  const queryClient = useQueryClient();
  const { notifyError } = useNotification();

  return useMutation({
    mutationFn: switchCompany,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userKeys.user.queryKey,
      });

      queryClient.invalidateQueries({
        queryKey: financeModelKeys._def,
      });

      queryClient.invalidateQueries({
        queryKey: scenarioKeys._def,
      });
    },
    onError: () => {
      notifyError("Ошибка смены организации", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    },
  });
};

export { useSwitchCompany };
