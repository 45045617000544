import { api } from "@/shared/config/network";

import { FinanceModelSheetPurpose } from "@/entities/SyncedData";

interface CreateOutputParams {
  financeModelId: number;
  data: Luckysheet.Data;
  tabName: string;
  purpose: FinanceModelSheetPurpose;
}

interface CreateOutputResponse {
  tableName: string;
}

const createOutput = async ({ data, tabName, financeModelId, purpose }: CreateOutputParams) => {
  const response = await api.post<CreateOutputResponse>(
    `/data-registry/finance-models/${financeModelId}/output`,
    {
      data,
      tabName,
      financeModelId,
      purpose,
      skipEmpty: true
    });

  return response.data;
};

export { createOutput };
