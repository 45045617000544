import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createDashboard } from "../api/create";
import { dashboardKeys } from "../const";

const useDashboardCreate = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: createDashboard,
    onSuccess: (...[, { financeModelId }]) => {
      console.log({ queryKey: dashboardKeys.list(financeModelId).queryKey });
      qc.invalidateQueries({
        queryKey: dashboardKeys.list(financeModelId).queryKey,
      });
    },
  });
};

export { useDashboardCreate };
