import { useSpring, useSprings } from "@react-spring/web";

// Анимация появления сверху вниз
export const useSlideInFromTop = (delay: number = 0) => {
  return useSpring({
    from: { transform: "translateY(-100%)", opacity: 0 },
    to: { transform: "translateY(0%)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};

// Анимация появления сверху вниз
export const useSlideInFromBottom = (delay: number = 0) => {
  return useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: { transform: "translateY(0%)", opacity: 1 },
    config: { tension: 220, friction: 20 }, 
    delay,
  });
};

// Хук для анимации одного элемента слева направо
export const useSlideInFromLeft = (delay: number = 0) => {
  return useSpring({
    from: { transform: "translateX(-100%)", opacity: 0 },
    to: { transform: "translateX(0%)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};

// Хук для анимации списка элементов слева направо с задержкой
export const useStaggeredSlideInFromLeft = (
  count: number,
  delay: number = 0
) => {
  return useSprings(
    count,
    Array.from({ length: count }).map((_, i) => ({
      from: { transform: "translateX(-100%)", opacity: 0 },
      to: { transform: "translateX(0%)", opacity: 1 },
      config: { tension: 220, friction: 20 },
      delay: delay + i * 50,
    }))
  );
};

export const useRandomAppearances = (count: number, delay: number = 0) => {
  return useSprings(
    count,
    Array.from({ length: count }).map((_, i) => {
      const randomX = Math.random() * 200 - 100;
      const randomY = Math.random() * 200 - 100;
      const randomDelay = delay + i * 100;

      return {
        from: {
          transform: `translate(${randomX}%, ${randomY}%)`,
          opacity: 0,
        },
        to: {
          transform: "translate(0%, 0%)",
          opacity: 1,
        },
        config: { tension: 220, friction: 20 },
        delay: randomDelay,
      };
    })
  );
};

// Хук для пульсирубщей анимации
export const usePulseAnimation = (
  scale: number = 1.1,
  duration: number = 300,
  tension: number = 200,
  friction: number = 15
) => {
  return useSpring({
    from: { transform: "scale(1)" },
    to: async (next) => {
      while (true) {
        await next({ transform: `scale(${scale})` });
        await next({ transform: "scale(1)" });
      }
    },
    config: { tension, friction, duration },
  });
};

// Хук для анимации увеличения логотипа
export const useScaleInFromCenter = (delay: number = 0) => {
  return useSpring({
    from: { transform: "scale(0)", opacity: 0 },
    to: { transform: "scale(1)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};

//Анимация из верхнего правого угла
export const useSlideInFromTopRight = (delay: number = 0, step = "100px") => {
  return useSpring({
    from: { transform: `translate(${step}, ${step}`, opacity: 0 },
    to: { transform: "translate(0%, 0%)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};

//Анимация из верхнего левого угла
export const useSlideInFromTopLeft = (delay: number = 0, step = "100px") => {
  return useSpring({
    from: { transform: `translate(-${step}, ${step})`, opacity: 0 },
    to: { transform: "translate(0%, 0%)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};

//Анимация из нижнего левого угла
export const useSlideInFromBottomLeft = (delay: number = 0, step = "100px") => {
  return useSpring({
    from: { transform: `translate(-${step}, -${step})`, opacity: 0 },
    to: { transform: "translate(0%, 0%)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};

//Анимация из нижнего правого угла
export const useSlideInFromBottomRight = (
  delay: number = 0,
  step = "100px"
) => {
  return useSpring({
    from: { transform: `translate(${step}, -${step})`, opacity: 0 },
    to: { transform: "translate(0%, 0%)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};

// Анимация увеличения и появления
export const useScaleInAnimation = (
  delay: number = 0,
  scaleFrom: number = 0.8
) => {
  return useSpring({
    from: { transform: `scale(${scaleFrom})`, opacity: 0 },
    to: { transform: "scale(1)", opacity: 1 },
    config: { tension: 220, friction: 20 },
    delay,
  });
};
