import { useMutation } from "@tanstack/react-query";
import { useNotification } from "@/shared/hooks/useNotification";
import { updateNotificationSettings } from "@/entities/User/api/updateNotificationSettings";

const useUpdateNotifications = () => {
  const { notifySuccess, notifyError } = useNotification();

  return useMutation({
    mutationFn: updateNotificationSettings,
    onSuccess: () => {
      notifySuccess("Настройки уведомлений сохранены");
    },
    onError: () => {
      notifyError("Ошибка сохранения настроек");
    },
  });
};

export { useUpdateNotifications };