import { api } from "@/shared/config/network";

import type { FinanceModelInputDto } from "../model";

const fetchInputs = async ({
  financeModelId,
  tabName,
  purpose,
}: {
  financeModelId: number;
  tabName: string;
  purpose: string;
}) => {
  const response = await api.get<FinanceModelInputDto[]>(
    `/data-registry/finance-models/${financeModelId}/inputs/${tabName}?purpose=${purpose}`
  );
  return response.data;
};

export { fetchInputs };
