import {
  createContext,
  useState,
  useEffect,
  useCallback,
  type ReactNode,
  type FC,
  useMemo,
} from "react";

import { AxiosError } from "axios";

import { api } from "@/shared/config/network";
import { AUTH_TOKEN_KEY } from "@/shared/const/localStorageKeys";
import { qc } from "../QueryClient/const";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string) => void;
  logout: () => void;
}

const defaultAuthContext: AuthContextType = {
  isAuthenticated: false,
  login: () => {},
  logout: () => {},
};

const AuthContext = createContext<AuthContextType>(defaultAuthContext);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem(AUTH_TOKEN_KEY)
  );
  const isAuthenticated = useMemo(() => {
    return Boolean(token);
  }, [token]);

  const login = useCallback((token: string) => {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
    setToken(token);
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    setToken(null);
    qc.clear();
  }, []);

  useEffect(() => {
    const interceptorId = api.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response) {
          const { status } = error.response;
          if ([401, 403].includes(status)) {
            logout();
          }
        }
        return Promise.reject(error);
      }
    );

    return () => api.interceptors.response.eject(interceptorId);
  }, [logout]);

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
