import React from "react";
import { useScreenshotOnRouteChange } from "@/shared/hooks/useScreenshotOnRouteChange";
import { base64ToFile } from "@/shared/config/files/index";
import { useFinanceModelPreviewUpload } from "@/entities/FinanceModel";
import { useNotification } from "@/shared/hooks/useNotification";

interface ScreenshotUploaderProps {
  modelId: number;
}

export const ScreenshotUploader: React.FC<ScreenshotUploaderProps> = ({
  modelId,
}) => {
  const { mutate: uploadPreview } = useFinanceModelPreviewUpload();
  const { notifyError } = useNotification();

  const handleScreenshotUpload = async (dataURL: string) => {
    try {
      const file = base64ToFile(dataURL, "screenshot.png");
      await uploadPreview({ modelId, file });
    } catch (error) {
      console.error("Error processing screenshot upload:", error);
      notifyError("Ошибка загрузки скриншота", {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    }
  };

  useScreenshotOnRouteChange({
    callback: handleScreenshotUpload,
    routesToWatch: ["/grid"],
  });

  return null;
};
