import { useMutation } from "@tanstack/react-query";
import { importFile, importFileFromSheets, splitImportFile } from "../api";

const useFinanceModelImport = () => {
  return useMutation({
    mutationFn: importFile,
  });
};

const useFinanceModelSplitImport = () => {
  return useMutation({
    mutationFn: splitImportFile,
  });
};

const useFinanceModelImportFromSheets = () => {
  return useMutation({
    mutationFn: importFileFromSheets,
  });
};

export {
  useFinanceModelImport,
  useFinanceModelSplitImport,
  useFinanceModelImportFromSheets,
};
