import { ReactNode } from "react";
import { FetchInputTabsResponseDto } from "@/entities/FinanceModelInput";
import { FinanceModelSheetPurpose, SyncedDataDto } from "@/entities/SyncedData";
import {
  SheetsSettingsForm,
  Sheet,
  SheetSettingsFormValue,
  SheetSettingsItem,
  SyncedTableConflict
} from "./interfaces";

const getAllSheetsOfCurrentModel = (): Sheet[] => {
  return window?.computationEngine.LS_sheets || [];
};

const getDefaultChecked = (sheet: Sheet, defaultValues?: string[]) => defaultValues?.some((tabName) => tabName === sheet.name);
const getDefaultSynced = (sheet: Sheet, syncedTables?: SyncedDataDto[]) => syncedTables?.find(({ tabName }) => tabName === sheet.name);

const mapSheetToFormValue = (sheet: Sheet, defaultValues?: string[], syncedTables?: SyncedDataDto[]): SheetSettingsFormValue => {
  const checked = getDefaultChecked(sheet, defaultValues) ?? false;
  const syncedTable = getDefaultSynced(sheet, syncedTables) ?? false;

  if (syncedTable) {
    return {
      sheet,
      name: sheet.name,
      checked,
      synced: true,
      syncedId: syncedTable.id
    };
  }

  return {
    sheet,
    name: sheet.name,
    checked
  };
};

const getDefaultSheetsSettings = (sheets: Sheet[], defaultValues?: FetchInputTabsResponseDto, syncedTables?: SyncedDataDto[]): SheetsSettingsForm => {
  return ({
    forms: {
      inputs: sheets.map((sheet) => mapSheetToFormValue(sheet, defaultValues?.inputTabs, syncedTables)),
      outputs: sheets.map((sheet) => mapSheetToFormValue(sheet, defaultValues?.outputTabs, syncedTables)),
      hidden: sheets.map((sheet) => mapSheetToFormValue(sheet, [], syncedTables))
    },
    bi: {
      inputs: sheets.map((sheet) => mapSheetToFormValue(sheet, defaultValues?.biInputTabs, syncedTables)),
      outputs: sheets.map((sheet) => mapSheetToFormValue(sheet, defaultValues?.biOutputTabs, syncedTables))
    }
  });
};

const sheetDefinitionTitlesDict: Record<`${FinanceModelSheetPurpose}_${"input" | "output"}`, ReactNode> = {
  bi_input: "Параметры для BI дашбордов",
  bi_output: "Листы BI дашбордов",
  biandforms_input: "Данные для редактирования в формах и параметры для BI дашбордов",
  biandforms_output: "Данные для рассчетов в формах и листы BI дашбордов",
  forms_input: "Данные для редактирования в формах",
  forms_output: "Данные для рассчетов в формах"
};

const getSheetDefinitionTitle = ({ sheetType, sheetPurpose }: {
  sheetPurpose: FinanceModelSheetPurpose;
  sheetType: "input" | "output"
}): ReactNode => {
  return sheetDefinitionTitlesDict[`${sheetPurpose}_${sheetType}`];
};

const isSheetChecked = ({ checked }: { checked: boolean }) => checked;

const groupSyncedDataByPurpose = (syncedTables: SyncedDataDto[]) => {
  return syncedTables.reduce<{
    formOutputs: SyncedDataDto[],
    biOutputs: SyncedDataDto[]
  }>((syncedTables, table) => {
    switch (table.purpose) {
      case "forms":
        syncedTables.formOutputs.push(table);
        break;

      case "bi":
        syncedTables.biOutputs.push(table);
        break;

      case "biandforms":
        syncedTables.formOutputs.push(table);
        syncedTables.biOutputs.push(table);
        break;
    }

    return syncedTables;
  }, { formOutputs: [], biOutputs: [] });
};

const isSheetSynced = (syncedId: number, sheets: SheetSettingsItem[]): boolean =>
  sheets.some((o) => "synced" in o && o.syncedId === syncedId);

const isTableInSheets = (syncedTable: SyncedDataDto, sheets: Sheet[]): boolean => {
  return sheets.some(({ name }) => name === syncedTable.sheetName);
};

const getSyncingConflicts = (sheets: Sheet[], syncedTables: SyncedDataDto[]): SyncedTableConflict[] | null => {
  const conflicts: SyncedTableConflict[] = [];

  for (const syncedTable of syncedTables) {
    if (!isTableInSheets(syncedTable, sheets)) {
      conflicts.push({
        sheetName: syncedTable.sheetName,
        syncedTable
      });
    }
  }

  return conflicts.length ? conflicts : null;
};

const groupDoubleOutputs = (formOutputs: SheetSettingsItem[] | undefined, biOutputs: SheetSettingsItem[] | undefined) => {
  const groupedOutputs: {
    formOutputs: SheetSettingsItem[]
    biOutputs: SheetSettingsItem[]
    biAndFormOutputs: SheetSettingsItem[]
  } = {
    formOutputs: [],
    biOutputs: [],
    biAndFormOutputs: []
  };

  const formOutputsLookUp = new Map(formOutputs?.map((o) => [o.name, o]));
  const biOutputsLookUp = new Map(biOutputs?.map((o) => [o.name, o]));

  for (const [key, value] of formOutputsLookUp.entries()) {
    if (biOutputsLookUp.has(key)) {
      groupedOutputs.biAndFormOutputs.push(value);
      biOutputsLookUp.delete(key);
    } else {
      groupedOutputs.formOutputs.push(value);
    }
  }

  for (const [, value] of biOutputsLookUp.entries()) {
    groupedOutputs.biOutputs.push(value);
  }

  return groupedOutputs;
};

export {
  getAllSheetsOfCurrentModel,
  getDefaultSheetsSettings,
  getSheetDefinitionTitle,
  isSheetChecked,
  groupSyncedDataByPurpose,
  isSheetSynced,
  getSyncingConflicts,
  groupDoubleOutputs
};

