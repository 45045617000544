import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FC } from "react";

interface BackupRestoreCurrentDialogContentProps {
  handleBackupDialogClose: () => void;
  handleRestoreBackup: () => void;
}

export const BackupRestoreCurrentDialogContent: FC<
  BackupRestoreCurrentDialogContentProps
> = ({ handleBackupDialogClose, handleRestoreBackup }) => {
  return (
    <>
      <DialogTitle>Восстановить резервную копию</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Вы уверены, что хотите восстановить резервную копию в текущую модель?
          Это действие перезапишет текущие данные.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleBackupDialogClose}
        >
          Отмена
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleRestoreBackup}
        >
          Да
        </Button>
      </DialogActions>
    </>
  );
};
