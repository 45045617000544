import { SyntheticEvent, useEffect, useMemo, useRef, type FC } from "react";
import {
  CssBaseline,
  Box,
  Button,
  Tabs,
  Tab,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import HotTable, { HotTableClass } from "@handsontable/react";
import { useParams } from "react-router-dom";

import { useDashboard } from "@/entities/Dashboards";
import Header from "@/widgets/Header";
import { useHotTable } from "@/shared/hooks/useHotTable";
import { useRecalculateModel } from "@/entities/FinanceModelInput/hooks/useRecalculateModel";
import styles from "./table.module.css";
import { useBiFinanceModelInputsAll } from "@/entities/FinanceModelInput/queries/useBiFinanceModelInputsAll.ts";

interface DashboardProps {
  id: number;
}

const Dashboard: FC<DashboardProps> = () => {
  const { id } = useParams<{ id: string }>();
  const idNum = +id;

  const hotRef = useRef<HotTableClass>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const { data: dashboard, isLoading } = useDashboard({
    dashboardId: idNum,
    enabled: !isNaN(idNum) && idNum !== 0,
  });

  const financeModelId = dashboard?.financeModelId;

  const financeModelInputsAllQuery = useBiFinanceModelInputsAll({
    financeModelId,
    enabled: Boolean(financeModelId),
  });

  const {
    tableInputs,
    isInputsLoading,

    handleAfterChange,
    handleAfterRender,
    updatedCells,
    updateSettings,

    selectedSheet,
    selectedSheetData,
    setSelectedSheet,
    values,
    cellSettings,
  } = useHotTable({ hotRef, inputsQuery: financeModelInputsAllQuery });

  const { handleRecalculateModel, isRecalculateModelPending, key } =
    useRecalculateModel({
      financeModelId: dashboard?.financeModelId,
      updatedCells,
    });

  const allParamsSheets = useMemo(() => {
    return tableInputs.filter((v) => v.type === "input");
  }, [tableInputs]);

  // Select first params sheet
  useEffect(() => {
    if (!selectedSheet && allParamsSheets.length > 0) {
      setSelectedSheet(allParamsSheets[0].tabName);
    }
  }, [allParamsSheets, selectedSheet, setSelectedSheet]);

  const handleChangeSheet = (_event: SyntheticEvent, sheetName: string) => {
    setSelectedSheet(sheetName);
  };

  useEffect(() => {
    updateSettings(selectedSheet, values, updatedCells);
  }, [selectedSheet, updateSettings, updatedCells, values]);

  return (
    <Box
      sx={{
        display: "grid",
        height: "100vh",
        width: "100%",
        columnGap: 0,
        rowGap: 0,
      }}
    >
      <CssBaseline />
      <Header
        leftSlot={
          <Button
            sx={{ marginLeft: "16px" }}
            variant="lightBlueV300"
            onClick={() => handleRecalculateModel(["bi", "biandforms"])}
          >
            {isRecalculateModelPending ? "Считаем..." : "Перерасчет"}
          </Button>
        }
      />

      {isLoading ? (
        <div className={styles.loader}>Загрузка...</div>
      ) : (
        <div className={styles.dashboard}>
          {!isInputsLoading && selectedSheet && (
            <Accordion defaultExpanded sx={{ boxShadow: "unset" }}>
              <AccordionSummary
                sx={{ paddingLeft: 4 }}
                expandIcon={<ExpandMoreIcon />}
              >
                Управление параметрами
              </AccordionSummary>

              <AccordionDetails>
                <div className={styles.tableContainer}>
                  <Box
                    className={styles.table}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Tabs value={selectedSheet} onChange={handleChangeSheet}>
                      {allParamsSheets.map((sheet) => {
                        return (
                          <Tab
                            key={sheet.tabName}
                            label={sheet.tabName}
                            value={sheet.tabName}
                          />
                        );
                      })}
                    </Tabs>
                  </Box>
                  {selectedSheetData && (
                    <HotTable
                      className={styles.table}
                      ref={hotRef}
                      data={values}
                      height="auto"
                      width="auto"
                      autoWrapRow
                      autoWrapCol
                      autoColumnSize
                      stretchH="none"
                      licenseKey="non-commercial-and-evaluation"
                      afterChange={handleAfterChange}
                      afterRender={handleAfterRender}
                      disableVisualSelection={
                        isRecalculateModelPending || isInputsLoading
                      }
                      readOnly={isRecalculateModelPending || isInputsLoading}
                      cell={cellSettings ?? undefined}
                    />
                  )}
                </div>
              </AccordionDetails>
            </Accordion>
          )}

          <iframe
            ref={iframeRef}
            key={key}
            className={styles.iframe}
            src={dashboard?.publicUrl}
            allowFullScreen
          />
        </div>
      )}
    </Box>
  );
};

export default Dashboard;
