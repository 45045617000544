import { useForm, SubmitHandler } from "react-hook-form";
import { useFinanceModelShare } from "@/entities/FinanceModel";

interface FinanceModelShareForm {
  userId?: string | null;
  permissions: string;
}

interface ShareFormProps {
  onSuccess?: () => void;
  onError?: (error: any) => void;
}

export const useFinanceModelShareForm = (
  modelId: number,
  { onSuccess, onError }: ShareFormProps
) => {
  const { mutateAsync: shareModel } = useFinanceModelShare({
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    onSettled: () => {
      reset();
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
    reset,
  } = useForm<FinanceModelShareForm>({
    mode: "onChange",
    defaultValues: {
      userId: "@anyone", //TODO: пока делимся со всеми (далее будем править логику, когда договоримся с беком)
      permissions: "view",
    },
  });

  const handleShare: SubmitHandler<FinanceModelShareForm> = async (data) => {
    await shareModel({
      modelId,
      userId: data.userId,
      permissions: data.permissions,
    });
  };

  return {
    register,
    handleSubmit: handleSubmit(handleShare),
    errors,
    isSubmitting,
    isValid,
    setValue,
  };
};
