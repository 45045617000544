import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useFinanceModelEdit } from "@/entities/FinanceModel";

interface FinanceModelEditForm {
  name: string;
  description: string;
}

interface EditFormProps {
  onSuccess?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onError?: (error: any) => void;
}

export const useFinanceModelEditForm = (
  modelId: number,
  { onSuccess, onError }: EditFormProps,
  defaultName: string,
  defaultDescription: string,
  gridKey: string
) => {
  const { mutateAsync: editModel } = useFinanceModelEdit();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit: handleFormSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
    setValue,
  } = useForm<FinanceModelEditForm>({
    mode: "onChange",
    defaultValues: {
      name: defaultName,
      description: defaultDescription,
    },
  });

  const handleOpen = () => {
    setOpen(true);
    reset({
      name: defaultName,
      description: defaultDescription,
    });
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const handleEdit: SubmitHandler<FinanceModelEditForm> = async (data) => {
    try {
      await editModel({
        id: modelId,
        name: data.name,
        description: data.description,
        gridKey,
      });
      handleClose();
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    }
  };

  return {
    open,
    handleOpen,
    handleClose,
    handleSubmit: handleFormSubmit(handleEdit),
    register,
    errors,
    isSubmitting,
    setValue,
    isValid,
    reset,
  };
};
