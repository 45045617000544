import { FC, useEffect } from "react";
import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { Input } from "@/shared/ui/Input/Input";
import { useFinanceModelEditForm } from "../../hooks/useFinanceModelEditForm";

interface FinanceModelEditProps {
  isOpen: boolean;
  handleClose: () => void;
  modelId: number;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
  defaultName: string;
  defaultDescription: string;
  gridKey: string;
}

export const FinanceModelEditModal: FC<FinanceModelEditProps> = ({
  isOpen,
  handleClose,
  modelId,
  onSuccess,
  onError,
  defaultName,
  defaultDescription,
  gridKey,
}) => {
  const { register, handleSubmit, errors, isSubmitting, isValid, reset } =
    useFinanceModelEditForm(
      modelId,
      {
        onSuccess: () => {
          console.debug("Model edited successfully");
          handleClose();
          if (onSuccess) onSuccess();
        },
        onError: (error) => {
          console.error("Error editing model:", error);
          if (onError) onError(error);
        },
      },
      defaultName,
      defaultDescription,
      gridKey
    );

  useEffect(() => {
    if (isOpen) {
      reset({
        name: defaultName,
        description: defaultDescription,
      });
    }
  }, [isOpen, reset, defaultName, defaultDescription]);

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          minHeight: "350px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h2">
            Редактировать модель
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          style={{
            height: "100%",
            minHeight: "350px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Input
            placeholder="Имя модели"
            fullWidth
            margin="normal"
            {...register("name", {
              required: "Имя модели обязательно",
              maxLength: {
                value: 100,
                message: "Имя модели не может превышать 100 символов",
              },
            })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
            type="text"
          />

          <Input
            placeholder="Описание модели"
            fullWidth
            margin="normal"
            multiline
            rows={4}
            {...register("description", {
              required: "Описание модели обязательно",
              maxLength: {
                value: 500,
                message: "Описание не может превышать 500 символов",
              },
            })}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
            type="text"
          />

          <Button
            variant="lightBlueV100"
            color="primary"
            type="submit"
            sx={{ width: "100%", marginTop: "auto !important" }}
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? <Spinner withBg={true} /> : "Сохранить"}
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
