import { useQuery } from "@tanstack/react-query";
import { versionsKeys } from "../const";
import { fetchVersionLabelsById } from "../api";
import { VersionLabelsByIdResponse } from "../model";

export const useVersionLabelsById = (versionId: number) => {
  return useQuery<VersionLabelsByIdResponse, Error>({
    queryKey: versionsKeys.labelsByVersionId(versionId).queryKey,
    queryFn: () => fetchVersionLabelsById(versionId),
    enabled: !!versionId,
  });
};
