import { FC, ReactNode } from "react";
import { ListItemText } from "@mui/material";
import { StyledList } from "./StyledList";
import { StyledListItem } from "./StyledListItem";
import { SheetSettingsItem } from "./interfaces";

interface Props {
  sheets: SheetSettingsItem[];
  title?: ReactNode;
}

const SheetViewList: FC<Props> = ({ sheets, title }) => {
  return (
    <StyledList subheader={title}>
      {sheets.map((sheet, index: number) => {
        return (
          <StyledListItem key={sheet.name} index={index}>
            <ListItemText primary={sheet.name} />
          </StyledListItem>
        );
      })}
    </StyledList>
  );
};

export { SheetViewList };
