import { useQuery } from "@tanstack/react-query";
import { fetchOutputMetaInfo } from "../api";
import { financeModelInputKeys } from "../const";
import { FinanceModelOutput } from "../model";

interface UseFinanceModelOutputMetaInfoParams {
  financeModelId: number;
  tabName: string;
  purpose: string;
  enabled?: boolean;
  outNumber?: number;
}

const useFinanceModelOutputMetaInfo = ({
  financeModelId,
  tabName,
  purpose,
  enabled = false,
}: UseFinanceModelOutputMetaInfoParams) => {
  return useQuery<FinanceModelOutput[]>({
    queryKey: financeModelInputKeys.outputs(financeModelId, tabName).queryKey,
    queryFn: () => fetchOutputMetaInfo(financeModelId, tabName, purpose),
    staleTime: 1000 * 60 * 5,
    enabled,
  });
};

export { useFinanceModelOutputMetaInfo };
