import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Button, CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useVersionById, useUpdateAnnotation } from "@/entities/Version";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { Input } from "@/shared/ui/Input";
import { VersionAnnotationForm } from "../interfaces";

interface Props {
  versionId: number;
  open: boolean;
  onClose: VoidFunction;
  onAnnotationChanged?: VoidFunction;
}

const StyledDialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  minWidth: "500px",
  minHeight: "180px"
}));

const VersionAnnotationModal: FC<Props> = ({ versionId, open, onClose, onAnnotationChanged }) => {
  const { data: version, isPending: isVersionLoading } = useVersionById(versionId);
  const { mutate: updateAnnotation, isPending: isUpdating } = useUpdateAnnotation();

  const { register, setValue, handleSubmit } = useForm<VersionAnnotationForm>();

  useEffect(() => {
    if (version) {
      setValue("annotation", version.annotation);
    }
  }, [setValue, version]);

  const onSave: SubmitHandler<VersionAnnotationForm> = (data) => {
    updateAnnotation(
      { versionId, annotation: data },
      {
        onSuccess: () => {
          onAnnotationChanged?.();
          onClose();
        }
      }
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Аннотация</DialogTitle>

      <StyledDialogContent>
        {
          isVersionLoading
            ? <Spinner />
            : (
              version
                ? (
                  <form onSubmit={handleSubmit(onSave)}>
                    <Stack spacing={2}>
                      <Typography>Добавить аннотацию</Typography>

                      <Input
                        placeholder="Аннотация"
                        fullWidth
                        {...register("annotation")}
                      />

                      <DialogActions>
                        <Button onClick={onClose} color="primary" variant="outlined">Отмена</Button>
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                          disableElevation
                          disabled={isUpdating}
                          startIcon={isUpdating ? <CircularProgress size={14} /> : undefined}
                        >
                          Сохранить
                        </Button>
                      </DialogActions>
                    </Stack>
                  </form>
                )
                : <Typography>Нет данных</Typography>
            )
        }
      </StyledDialogContent>
    </Dialog>
  );
};

export { VersionAnnotationModal };
