// src/api/share.ts
import { api } from "@/shared/config/network";
import { FinanceModelShareResponse } from "../model";

interface ShareModelRequest {
  modelId: number;
  userId?: string | null;
  permissions: string;
}

const shareFinanceModel = async ({
  modelId,
  userId,
  permissions,
}: ShareModelRequest) => {
  const response = await api.post<FinanceModelShareResponse>(
    `/finance-models/${modelId}/share`,
    { userId, permissions }
  );
  return response.data;
};

export { shareFinanceModel };
