import { useState, MouseEvent, FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Divider,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreIcon from "@mui/icons-material/MoreVert";

import { usePermissions } from "@/entities/User";
import { useCloneModel } from "@/entities/FinanceModel";
import { useSyncedData, useSyncedDataDelete } from "@/entities/SyncedData";
import { useDeleteFinanceModel } from "@/entities/FinanceModel";

import { BackupModal } from "./Backups/BackupModal";
import { DashboardsModal } from "./DashboardsModal";

interface SidebarItemProps {
  id: number;
  gridkey: string;
  name?: string;
  description?: string;
  selected: boolean;
  onClick: () => void;
}

interface SyncedData {
  id: number;
  tableName: string;
}

const SidebarItem: FC<SidebarItemProps> = (props) => {
  const iCan = usePermissions();

  const [dropdownAnchorEl, setDropdownAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const dropdownOpen = Boolean(dropdownAnchorEl);

  const handleDropdownClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setDropdownAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setDropdownAnchorEl(null);
  };

  const { data: outTables = [] } = useSyncedData({
    gridkey: props.gridkey,
    financeModelId: props.id,
    enabled: props.selected,
  });
  const { mutate: delOutTable } = useSyncedDataDelete();
  const { mutate: deleteFinanceModelById } = useDeleteFinanceModel();

  const [openModelDeletionDialog, setOpenModelDeletionDialog] = useState(false);

  const handleModelDelete = () => {
    deleteFinanceModelById(props.id);
    setOpenModelDeletionDialog(false);
  };

  const handleModelClickOpen = () => {
    setOpenModelDeletionDialog(true);
  };

  const handleModelClose = () => {
    setOpenModelDeletionDialog(false);
  };

  const [openBackupsDialog, setOpenBackupsDialog] = useState(false);

  const handleBackupsDialogClickOpen = () => {
    setOpenBackupsDialog(true);
  };

  const handleBackupsDialogClose = () => {
    setOpenBackupsDialog(false);
  };

  const [openDashboardsDialog, setOpenDashboardsDialog] = useState(false);

  const handleDashboardsDialogClickOpen = () => {
    setOpenDashboardsDialog(true);
  };

  const handleDashboardsDialogClose = () => {
    setOpenDashboardsDialog(false);
  };

  const [openOutTableDialog, setOpenOutTableDialog] = useState(false);
  const [selectedTable, setSelectedTable] = useState<SyncedData | null>(null);

  const handleDeleteTable = () => {
    if (selectedTable) {
      delOutTable(selectedTable.id);
      setOpenOutTableDialog(false);
      setSelectedTable(null);
    }
  };

  const handleTableClickOpen = (table: SyncedData) => {
    setSelectedTable(table);
    setOpenOutTableDialog(true);
  };

  const handleTableClose = () => {
    setOpenOutTableDialog(false);
    setSelectedTable(null);
  };

  const { mutate: clone } = useCloneModel();

  return (
    <>
      {props.selected && <Divider />}

      <ListItem
        selected={props.selected}
        onClick={props.onClick}
        sx={{
          "&:hover": {
            background: (theme) => theme.palette.grey[300],
            cursor: "pointer",
          },
        }}
        secondaryAction={
          props.selected && (
            <>
              <IconButton
                id="basic-button"
                aria-controls={dropdownOpen ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={dropdownOpen ? "true" : undefined}
                size="small"
                onClick={handleDropdownClick}
              >
                <MoreIcon />
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={dropdownAnchorEl}
                open={dropdownOpen}
                onClose={handleDropdownClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {iCan.deleteModel && (
                  <MenuItem
                    onClick={() => {
                      handleModelClickOpen();
                      handleDropdownClose();
                    }}
                  >
                    Delete
                  </MenuItem>
                )}
                {iCan.createModel && (
                  <MenuItem
                    onClick={() => {
                      clone({ financeModelId: props.id });
                      handleDropdownClose();
                    }}
                  >
                    Clone
                  </MenuItem>
                )}
                {iCan.createModel && (
                  <MenuItem
                    onClick={() => {
                      handleDropdownClose();
                      handleBackupsDialogClickOpen();
                    }}
                  >
                    Backup
                  </MenuItem>
                )}

                {iCan.addDashboard && (
                  <MenuItem
                    onClick={() => {
                      handleDropdownClose();
                      handleDashboardsDialogClickOpen();
                    }}
                  >
                    Dashboards
                  </MenuItem>
                )}
              </Menu>
            </>
          )
        }
      >
        <Tooltip title={props.gridkey}>
          <ListItemText
            primary={props.name}
            secondary={(() => {
              const v = props.description || props.gridkey || "??";

              return v?.length > 25 ? v?.slice(0, 25) + "..." : v;
            })()}
            sx={{
              overflow: "hidden",
            }}
          />
        </Tooltip>
      </ListItem>

      <Dialog open={openModelDeletionDialog} onClose={handleModelClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this finance model? This action
            cannot be undone.
          </DialogContentText>

          <TextField
            margin="dense"
            size="small"
            label="GridKey"
            type="text"
            fullWidth
            value={props.gridkey}
            InputProps={{
              readOnly: true,
            }}
            variant="standard"
          />

          {props.name && (
            <TextField
              margin="dense"
              size="small"
              label="Name"
              type="text"
              fullWidth
              value={props.name}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
            />
          )}
          {props.description && (
            <TextField
              margin="dense"
              size="small"
              label="Description"
              type="text"
              fullWidth
              value={props.description}
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleModelClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleModelDelete}
            color="error"
            variant="contained"
            disableElevation
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <BackupModal
        open={openBackupsDialog}
        handleClose={handleBackupsDialogClose}
        financeModelId={props.id}
      />

      <DashboardsModal
        open={openDashboardsDialog}
        handleClose={handleDashboardsDialogClose}
        financeModelId={props.id}
      />

      {props.selected && (
        <>
          <List component="div" disablePadding>
            {outTables.map((d: SyncedData) => (
              <ListItem
                key={d.id}
                sx={{ pl: 4 }}
                selected={props.selected}
                secondaryAction={
                  iCan.deleteModel && (
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      size="small"
                      onClick={() => handleTableClickOpen(d)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )
                }
              >
                <ListItemText secondary={d.tableName} />
              </ListItem>
            ))}
          </List>

          <Dialog open={openOutTableDialog} onClose={handleTableClose}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete the table "
                {selectedTable?.tableName}"? This action cannot be undone.
              </DialogContentText>

              <TextField
                margin="dense"
                label="Table ID"
                type="text"
                fullWidth
                value={selectedTable?.id || ""}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
              <TextField
                margin="dense"
                label="Table Name"
                type="text"
                fullWidth
                value={selectedTable?.tableName || ""}
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
              />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleTableClose}
                color="primary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDeleteTable}
                color="error"
                variant="contained"
                disableElevation
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      {props.selected && <Divider />}
    </>
  );
};

export default SidebarItem;
