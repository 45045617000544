import { ReactNode } from "react";
import { SnackbarProvider } from "notistack";
import { notificationConfig } from "./config";

interface NotificationProviderProps {
  children: ReactNode;
}

export const NotificationProvider = ({
  children,
}: NotificationProviderProps) => {
  return (
    <SnackbarProvider {...notificationConfig}>{children}</SnackbarProvider>
  );
};
