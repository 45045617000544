import { Container, Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Logo } from "@/shared/ui/Logo";
import { animated } from "@react-spring/web";
import { useScaleInAnimation } from "@/shared/animations";
import { routePaths } from "@/shared/config/routePaths";

export const Register = () => {
  const slideInAnimation = useScaleInAnimation(300);
  return (
    <>
      <header
        style={{
          position: "fixed",
          height: "var(--headerHeight)",
          top: 0,
          left: 0,
          right: 0,
          paddingBlock: "var(--headerPdBlock)",
          paddingInline: "var(--headerPdInline)",
          boxSizing: "border-box",
          backgroundColor: "var(--colorSeaSalt)",
        }}
      >
        <Logo />
      </header>
      <Container
        maxWidth="md"
        style={{
          height: "100dvh",
          alignItems: "center",
          display: "flex",
          overflow: "hidden",
          justifyContent: "center",
        }}
        className="robotoFont"
      >
        <animated.div style={{ ...slideInAnimation, width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              maxWidth: "436px",
              margin: "0 auto",
              padding: "32px",
              borderRadius: "8px",
              boxShadow: "var(--boxShadowDarkBlue)",
              gap: "24px",
              backgroundColor: "var(--colorWhite)",
              "@media (max-width:768px)": {
                padding: "23px",
              },
            }}
          >
            <Typography
              variant="h4"
              className="interFont"
              sx={{
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "100%",
              }}
            >
              Регистрация
            </Typography>

            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                fontSize: "16px",
                lineHeight: "150%",
              }}
            >
              Для регистрации обратитесь к администратору.
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Button
                variant="lightBlueV100"
                color="primary"
                sx={{ mt: 2 }}
                component={Link}
                to={routePaths.login}
              >
                Войти в аккаунт
              </Button>
            </Box>
          </Box>
        </animated.div>
      </Container>
    </>
  );
};
