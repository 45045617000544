import { api } from "@/shared/config/network";

import type { DashboardDto } from "../model";

interface GetDashboardParams {
  dashboardId: number;
}

const getDashboard = async ({ dashboardId }: GetDashboardParams) => {
  const response = await api.get<DashboardDto>(
    `/dashboards/${dashboardId}`
  );
  return response.data;
};

export { getDashboard };