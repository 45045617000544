import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createOutput } from "../api";
import { syncedDataKeys } from "../const";

const useSyncedDataPersist = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: createOutput,

    onSuccess: (...[, { financeModelId }]) => {
      qc.invalidateQueries({
        queryKey: syncedDataKeys.details(financeModelId).queryKey,
      });
    },
  });
};

export { useSyncedDataPersist };
