import React, { ForwardedRef } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { SxProps, Theme, useTheme } from "@mui/material/styles";

// Интерфейс InputProps расширяется от TextFieldProps с исключением лишних параметров
interface InputProps
  extends Omit<TextFieldProps, "type" | "value" | "onChange" | "onBlur"> {
  label?: string;
  type?: string;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  error?: boolean;
  helperText?: string;
  showPassword?: boolean;
  handleClickShowPassword?: () => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
  InputProps?: {
    endAdornment?: React.ReactNode;
    sx?: SxProps<Theme>;
  };
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      type = "text",
      value,
      onChange,
      onBlur,
      error = false,
      helperText,
      showPassword = false,
      handleClickShowPassword,
      placeholder,
      sx,
      InputProps,
      ...rest
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const theme = useTheme();

    return (
      <TextField
        label={label ? label : undefined}
        variant="outlined"
        type={type}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        InputProps={{
          endAdornment: handleClickShowPassword ? (
            <InputAdornment position="end">
              <IconButton
                disableRipple
                edge="end"
                onClick={handleClickShowPassword}
                aria-label="toggle password visibility"
                sx={{ color: theme.palette.customColors.colorBlack }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : undefined,
          sx: {
            borderRadius: "6px",
            ...InputProps?.sx,
          },
        }}
        sx={{
          "& .MuiInputLabel-root": {
            color: theme.palette.customColors.colorBlack,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "150%",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: theme.palette.customColors.colorLightBlue200,
            },
            "&:hover fieldset": {
              borderColor: theme.palette.customColors.colorCelestialBlue,
              opacity: 0.48,
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.customColors.colorCelestialBlue,
            },
          },
          ...sx,
        }}
        ref={ref}
        {...rest}
      />
    );
  }
);
