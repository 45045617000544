import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  FormControlLabel,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFinanceModelCreateModal } from "../../hooks/useFinanceModelCreateModal";
import { FC, useLayoutEffect, useState } from "react";
import { CustomSelect } from "@/shared/ui/CustomSelect";
import { Input } from "@/shared/ui/Input/Input";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { formatTemplates } from "../utils";
import { FinanceModelDto, type FormulaEngine } from "@/entities/FinanceModel";
import { TemplateUseDto, useTemplates } from "@/entities/Templates";
import { OPTIONS } from "../config/options";

interface FinanceModelCreateProps {
  open: boolean;
  handleClose: () => void;
  onSuccess?: (model: FinanceModelDto | TemplateUseDto) => void;
  onError?: (error: any) => void;
  templateId?: number;
  defaultFormulaEngine?: FormulaEngine;
}

export const FinanceModelCreate: FC<FinanceModelCreateProps> = ({
  open,
  handleClose,
  onSuccess,
  onError,
  templateId,
  defaultFormulaEngine,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    isSubmitting,
    setValue,
    isValid,
    reset,
  } = useFinanceModelCreateModal(onSuccess, onError);
  const { data: templates = [], isLoading } = useTemplates(formatTemplates);

  const [isTemplate, setIsTemplate] = useState(!!templateId || false);

  useLayoutEffect(() => {
    setIsTemplate(!!templateId);
  }, [templateId]);

  useLayoutEffect(() => {
    if (defaultFormulaEngine) {
      setValue("formulaEngine", defaultFormulaEngine);
    }
  }, [defaultFormulaEngine, setValue]);

  const handleModalClose = () => {
    setIsTemplate(false);
    reset();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={"20px"}
        >
          <Typography variant="h6" component="h2">
            Создание модели
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          style={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Input
            placeholder="Имя модели"
            fullWidth
            {...register("name", { required: "Имя обязательно" })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
          />
          <Input
            placeholder="Описание"
            rows={4}
            multiline
            fullWidth
            {...register("description", { required: "Описание обязательно" })}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
          />

          {templates.length > 0 && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={isTemplate}
                    sx={{ width: "40px", marginBottom: "10px" }}
                    onChange={() => setIsTemplate((state) => !state)}
                  />
                }
                label="Использовать шаблон"
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  margin: "0 !important",
                }}
              />

              {isTemplate ? (
                isLoading ? (
                  <Box
                    sx={{
                      width: "100px",
                      height: "20px",
                      position: "relative",
                      marginBottom: "20px",
                    }}
                  >
                    <Spinner />
                  </Box>
                ) : (
                  <>
                    <Typography>Выберите шаблон за основу</Typography>
                    {templates && (
                      <CustomSelect
                        options={templates}
                        name="templateId"
                        register={register}
                        setValue={setValue}
                        defaultValue={
                          (templateId &&
                            templates.find(
                              (template) =>
                                template.value === templateId.toString()
                            )?.value) ||
                          ""
                        }
                      />
                    )}
                  </>
                )
              ) : null}
            </>
          )}
          <Typography>Библиотека для расчёта</Typography>
          <Box marginBottom={"24px"}>
            <CustomSelect
              options={OPTIONS}
              name="formulaEngine"
              register={register}
              setValue={setValue}
              defaultValue={defaultFormulaEngine}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="lightBlueV100"
              color="primary"
              type="submit"
              sx={{ mt: 2, width: "100%" }}
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? <Spinner withBg={true} /> : "Создать"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
