import React, { useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  styled,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useVersionsByModelId } from "@/entities/Version/queries/useVersionsByModelId";
import { formatDate } from "@/shared/config/dateFormat";
import { routePaths } from "@/shared/config/routePaths";
import { LabelsList } from "@/entities/Label";
import { ModelMenu } from "@/widgets/ModelMenu";
import { MenuItemProps } from "@/widgets/ModelMenu/ui/ModelMenu.types";
import MoreIcon from "@/assets/icons/more.svg?react";
import { VersionsLabelsModal } from "@/widgets/Versions";
import {
  useApproveAnnotation,
  useInvalidateVersionList,
  VersionMetaDto,
} from "@/entities/Version";
import { useNotification } from "@/shared/hooks/useNotification";
import { VersionAnnotationModal } from "@/widgets/VersionAnnotationModal";
import RefreshIcon from "@/assets/icons/refresh.svg?react";
import DeleteIcon from "@/assets/icons/delete.svg?react";
import TemplateIcon from "@/assets/icons/template.svg?react";
import styles from "./VersionsList.module.css";
import { usePermissions } from "@/entities/User";

type VersionsListProps = {
  financeModelId: number;
  gridKey: string;
  onRestoreToCurrentVersionClick(versionId: number): void;
};

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: theme.palette.customColors.colorLightBlue200,
}));

export const VersionsList: React.FC<VersionsListProps> = ({
  financeModelId,
  gridKey,
  onRestoreToCurrentVersionClick,
}) => {
  const { notifyInfo } = useNotification();

  const { data: versions } = useVersionsByModelId(financeModelId);
  const { mutate: approve, isPending } = useApproveAnnotation();
  const iCan = usePermissions();

  const [labelModalState, setLabelModalState] = useState<{
    type: "open";
    versionId: number;
  } | null>(null);
  const [annotationModalState, setAnnotationModalState] = useState<{
    type: "open";
    versionId: number;
  } | null>(null);

  const getMenuItems = (
    version: VersionMetaDto
  ): { items: MenuItemProps[] }[] => [
    {
      items: [
        {
          label: "Добавить метку",
          icon: <TemplateIcon />,
          onClick: () =>
            setLabelModalState({ type: "open", versionId: version.id }),
        },
      ],
    },
    {
      items: [
        {
          label: "Добавить аннотацию",
          icon: <TemplateIcon />,
          onClick: () =>
            setAnnotationModalState({ type: "open", versionId: version.id }),
        },
      ],
    },
    {
      items: [
        {
          label: "Восстановить версию в текущую",
          icon: <RefreshIcon />,
          onClick: () => onRestoreToCurrentVersionClick(version.id),
        },
      ],
    },
    {
      items: [
        {
          label: isPending ? "Отправка..." : "Отправить на утверждение",
          icon: <TemplateIcon />,
          onClick: iCan.submitFormForApproval
            ? () => {
                if (version.id) approve({ versionId: version.id });
              }
            : () =>
                notifyInfo(
                  "Нет прав отправки на утверждение. Обратитесь к администратору"
                ),
        },
      ],
    },
    {
      items: [
        {
          label: "Удалить версию",
          extraClasses: styles.trash,
          icon: <DeleteIcon />,
          onClick: () => notifyInfo("Скоро можно будет удалять версии"),
        },
      ],
    },
  ];

  const invalidateVersionsList = useInvalidateVersionList(financeModelId);

  return (
    <>
      <Box sx={{ maxWidth: "calc(576px - 64px)" }}>
        <List
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {versions?.map((version, index) => {
            return (
              <React.Fragment key={version.id}>
                <Link
                  to={`${routePaths.grid}/${gridKey}${routePaths.versions}/${version.id}`}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    marginBottom: index === versions.length - 1 ? "48px" : 0,
                  }}
                >
                  <ListItem
                    sx={(theme) => {
                      return {
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px",
                        alignItems: "normal",
                        transition: "background-color 0.3s",
                        borderRadius: "16px",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor:
                            theme.palette.customColors.colorAliceBlue,
                        },
                      };
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <LabelsList labels={version.labels} />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ textWrap: "nowrap" }}>
                          {formatDate(version.updatedAt, true)}
                        </Box>
                        <Box sx={{ marginLeft: "16px", marginRight: "4px" }}>
                          {version.author?.avatar ? (
                            <Avatar
                              src={version.author.avatar}
                              sx={{ width: 32, height: 32 }}
                            />
                          ) : (
                            <StyledAvatar />
                          )}
                        </Box>
                        <ModelMenu
                          menuGroups={getMenuItems(version)}
                          MenuIcon={MoreIcon}
                          extraAttrs={{ className: styles.menu }}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "4px",
                        maxWidth: "100%",
                      }}
                    >
                      <Typography variant={"body1"} sx={{ fontWeight: "500" }}>
                        {"Изменение формы"}
                      </Typography>

                      <Typography
                        variant={"body1"}
                        color={"textSecondary"}
                        dangerouslySetInnerHTML={{
                          __html: version.description,
                        }}
                      />

                      {!!version.annotation && (
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          sx={{ mt: 2 }}
                        >
                          {version.annotation}
                        </Typography>
                      )}
                    </Box>
                  </ListItem>
                </Link>
                {index !== versions.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
          {!versions && (
            <Typography variant="body2" color="textSecondary">
              Нет форм
            </Typography>
          )}
        </List>
      </Box>

      {labelModalState?.type === "open" && (
        <VersionsLabelsModal
          open
          versionId={labelModalState.versionId}
          handleClose={() => setLabelModalState(null)}
          onLabelsAttached={invalidateVersionsList}
          onLabelsDeleted={invalidateVersionsList}
        />
      )}

      {annotationModalState?.type === "open" && (
        <VersionAnnotationModal
          versionId={annotationModalState.versionId}
          open
          onClose={() => setAnnotationModalState(null)}
          onAnnotationChanged={invalidateVersionsList}
        />
      )}
    </>
  );
};
