import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createScenario } from "../api";
import { scenarioKeys } from "../const";
import { CreateScenarioRequest, ScenarioDTO } from "../model";

const useCreateScenario = () => {
  const queryClient = useQueryClient();

  return useMutation<ScenarioDTO, Error, CreateScenarioRequest>({
    mutationFn: createScenario,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: scenarioKeys.list().queryKey,
      });
    },
  });
};

export { useCreateScenario };
