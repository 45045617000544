import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { BackupDto } from "@/entities/Backup";
import { formatDateWithSeconds } from "@/shared/config/dateFormat";
import { SmallAvatar } from "@/shared/ui/SmallAvatar";
import { useForm } from "react-hook-form";
import { Input } from "@/shared/ui/Input/Input";
import { useUserProfile } from "@/entities/User";

interface BackupIfonDialogContentProps {
  handleBackupDialogClose: () => void;
  handleUpdateBackup: () => void;
  backupInfo: BackupDto;
}

interface UpdateBackupInfoForm {
  versionName: string;
  description: string;
}

export const BackupInfoDialogContent: FC<BackupIfonDialogContentProps> = ({
  handleBackupDialogClose,
  handleUpdateBackup,
  backupInfo,
}) => {
  const { data: user } = useUserProfile();

  const { handleSubmit, register } = useForm<UpdateBackupInfoForm>({
    defaultValues: {
      versionName: backupInfo.versionName,
      description: backupInfo.description,
    },
  });

  const onSubmit = (data: UpdateBackupInfoForm) => {
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Информация о резервной копии</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Дата и время создания
          </Typography>
          <Typography>{formatDateWithSeconds(backupInfo.createdAt)}</Typography>
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Автор
          </Typography>
          <SmallAvatar
            avatarSrc={user?.avatar || ""}
            fullname={user?.fullname || "Имя Фамилия"}
          />
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Название
          </Typography>
          <Input
            sx={{ width: "100%" }}
            inputProps={{ sx: { padding: "8px 12px" } }}
            placeholder="Укажите название резервной копии"
            defaultValue={backupInfo.versionName}
            {...register("versionName", {
              required: "Введите название резервной копии",
            })}
          />
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Описание
          </Typography>
          <Input
            sx={{ width: "100%" }}
            inputProps={{ sx: { padding: "8px 12px" } }}
            defaultValue={backupInfo.description}
            placeholder="Укажите описание резервной копии"
            {...register("description", {
              required: "Введите описание резервной копии",
            })}
          />
        </Box>
        <Box>
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            Статус
          </Typography>
          <Typography>
            {backupInfo.protected ? "Защищена" : "Не защищена"}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleBackupDialogClose}
        >
          Отмена
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdateBackup}
        >
          Сохранить
        </Button>
      </DialogActions>
    </form>
  );
};
