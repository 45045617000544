import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { HeaderSlotProps } from "./HeaderLayout.types";

const StyledAppBar = styled(AppBar)(() => ({
  zIndex: 10,
  backgroundColor: "var(--colorWhite)",
  borderBottom: "1px solid var(--colorLightBlue200)",
  "--headerPdBlock": "4px",
  paddingBlock: "var(--headerPdBlock)",
  paddingInline: "var(--headerPdInline) !important",
  "--headerHeight": "58px",
  height: "var(--headerHeight)",
}));

const StyledToolbar = styled(Toolbar)(() => ({
  padding: "0 !important",
  height: "100%",
  minHeight: "0 !important",
}));

export const HeaderLayout: React.FC<HeaderSlotProps> = ({
  leftSlot,
  middleSlot,
  rightSlot,
}) => {
  return (
    <StyledAppBar position="fixed" variant="outlined" elevation={0}>
      <StyledToolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            gap: "32px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
              height: "100%",
            }}
          >
            {leftSlot}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            {middleSlot}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            {rightSlot}
          </Box>
        </Box>
      </StyledToolbar>
    </StyledAppBar>
  );
};
