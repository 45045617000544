import { Box, Typography } from "@mui/material";

import { ScenarioCardPreviewGrid } from "./SceanriosPreviewGrid";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";

import { Link } from "react-router-dom";

import { useScenarios } from "../queries/useScenarios";
import { routePaths } from "@/shared/config/routePaths";

export const ScenariosDashboard = () => {
  const { data, isLoading, isError } = useScenarios();

  return (
    <div>
      <Box
        sx={{
          mb: 2,
          padding: "0",
          display: "flex",
          justifyContent: "flex-end",
          gap: "32px",
          "@media (max-width: 1680px)": {
            padding: "0 24px",
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{ marginRight: "auto", paddingLeft: "10px" }}
        >
          Сценарии
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 304px)",
          gap: "28px",
          maxWidth: "calc(5 * (304px + 48px))",
          margin: "0 auto",
          "@media (max-width: 1920px)": {
            padding: "0",
          },
          "@media (max-width: 1680px)": {
            padding: "0 24px",
            gridTemplateColumns: "repeat(auto-fit, 288px)",
            maxWidth: "calc(5 * (288px + 48px))",
            justifyContent: "center",
          },
        }}
      >
        {isLoading ? (
          <Spinner fullscreen={true} />
        ) : isError ? (
          <Typography>Ошибка загрузки</Typography>
        ) : (
          <>
            {data &&
              data.map((model) => {
                return (
                  <Box
                    key={model.id}
                    sx={{
                      display: "block",
                      flexDirection: "column",
                      alignItems: "start",
                      width: "auto",
                      mb: 0,
                    }}
                  >
                    <Link
                      to={`${routePaths.scenarios.grid}/${encodeURIComponent(
                        model.id
                      )}`}
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        flexGrow: 1,
                      }}
                    >
                      <ScenarioCardPreviewGrid model={model} />
                    </Link>
                  </Box>
                );
              })}
          </>
        )}
      </Box>
    </div>
  );
};
