import React, { useContext, ComponentType } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { AuthContext } from "@/app/providers/Auth";

interface ProtectedRouteProps extends RouteProps {
  component: ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};

export default ProtectedRoute;
