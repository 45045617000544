import { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import SettingsIcon from "@/assets/icons/settings.svg?react";
import LogoutIcon from "@/assets/icons/logout.svg?react";
import DashboardIcon from "@/assets/icons/dashboard.svg?react"; // Import the new icon
import { CompanySwitcher } from "@/widgets/CompanySwitcher";
import { UserInfo } from "@/features/User/ui/UserInfo";
import { AuthContext } from "@/app/providers/Auth";
import { routePaths } from "@/shared/config/routePaths";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";

// Get dashboard URL from runtime config or fallback to build-time env
const getDashboardUrl = () => {
  if (window.ENV && window.ENV.VITE_BI_DASHBOARD_URL) {
    return window.ENV.VITE_BI_DASHBOARD_URL;
  }
  return import.meta.env.VITE_BI_DASHBOARD_URL || "#";
};

const useUserMenuGroups = () => {
  const { logout } = useContext(AuthContext);
  const { data: user } = useUserProfile();
  const { push } = useHistory();

  const userMenuGroups = useMemo(() => {
    return [
      {
        items: [
          {
            content: <UserInfo user={user} />,
            shouldCloseOnClick: false,
            extraClasses: "no-cursor",
          },
        ],
        divider: true,
      },
      {
        items: [
          {
            content: <CompanySwitcher />,
            shouldCloseOnClick: false,
            extraClasses: "no-hover no-padding no-cursor",
          },
        ],
        divider: true,
      },
      {
        items: [
          {
            label: "BI Dashboard",
            icon: <DashboardIcon width={18} height={18} />,
            onClick: () => window.open(getDashboardUrl(), "_blank"),
          },
          {
            label: "Настройки",
            icon: <SettingsIcon width={18} height={18} />,
            onClick: () => push(routePaths.profile),
          },
          {
            label: "Выйти",
            icon: <LogoutIcon width={18} height={18} />,
            onClick: logout,
          },
        ],
        divider: false,
      },
    ];
  }, [logout, user]);

  return userMenuGroups;
};

export { useUserMenuGroups };
