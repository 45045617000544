import merge from "lodash/merge";

import { api } from "@/shared/config/network";

api.interceptors.request.use((config) => ({
  ...config,
  headers: merge(config.headers, {
    Authorization: `Bearer ${localStorage.getItem("authToken") ?? ""}`,
  }),
}));
