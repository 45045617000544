import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useScenarioCreateModal } from "../hooks/useScenarioCreateModal";
import { FC } from "react";
import { Input } from "@/shared/ui/Input/Input";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { type ScenarioDTO } from "@/entities/Scenarios/model";

interface ScenarioCreateModalProps {
  open: boolean;
  handleClose: () => void;
  onSuccess?: (scenario: ScenarioDTO) => void;
  onError?: (error: any) => void;
}

export const ScenarioCreateModal: FC<ScenarioCreateModalProps> = ({
  open,
  handleClose,
  onSuccess,
  onError,
}) => {
  const { register, handleSubmit, errors, isSubmitting, isValid, reset } =
    useScenarioCreateModal(onSuccess, onError);

  const handleModalClose = () => {
    reset();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={"20px"}
        >
          <Typography variant="h6" component="h2">
            Создание сценария
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          style={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Input
            placeholder="Имя сценария"
            fullWidth
            {...register("name", { required: "Имя обязательно" })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
          />
          <Input
            placeholder="Описание"
            rows={4}
            multiline
            fullWidth
            {...register("description", { required: "Описание обязательно" })}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
          />
          <Box mt={2}>
            <Button
              variant="lightBlueV100"
              color="primary"
              type="submit"
              sx={{ mt: 2, width: "100%" }}
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? <Spinner withBg={true} /> : "Создать"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
