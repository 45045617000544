import { FC } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useUpdateNotifications } from "@/entities/User/queries/useUpdateNotifications";
import { CustomCheckbox } from "@/shared/ui/CustomCheckbox";

interface NotificationSettingsFormData {
  allNotifications: boolean;
  mentionNotifications: boolean;
  systemNotifications: boolean;
}

export const NotificationSettings: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<NotificationSettingsFormData>({
    mode: "onChange",
    defaultValues: {
      allNotifications: false,
      mentionNotifications: false,
      systemNotifications: false,
    },
  });

  const { mutate: saveSettings } = useUpdateNotifications();

  const onSubmit = (data: NotificationSettingsFormData) => saveSettings(data);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <Typography variant="h6" component="div">
        Настройка уведомлений
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          
          <CustomCheckbox 
           label="Все" 
           {...register("allNotifications")} 
          />

          <CustomCheckbox
            label="С упоминанием меня"
            {...register("mentionNotifications")}
          />

          <CustomCheckbox
            label="Системные сообщения"
            {...register("systemNotifications")}
          />

          <Button
            color="primary"
            variant="lightBlueV300"
            type="submit"
            sx={{ width: "200px" }}
            disabled={isSubmitting || !isValid}
          >
            {isSubmitting ? "Сохраняем..." : "Сохранить"}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
