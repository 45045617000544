import { useMutation } from "@tanstack/react-query";
import { exportFinanceModel } from "../api";

interface ExportModelVariables {
  fileName: string;
  listId: string;
}

const useFinanceModelExport = () => {
  return useMutation<Blob, Error, ExportModelVariables>({
    mutationFn: exportFinanceModel,
  });
};

export { useFinanceModelExport };
