import { api } from "@/shared/config/network";
import { CreateScenarioRequest, ScenarioDetail } from "../model";

const updateScenario = async (
  id: string,
  dto: CreateScenarioRequest
): Promise<ScenarioDetail> => {
  const response = await api.put<ScenarioDetail>(`/scenarios/${id}`, dto);
  return response.data;
};

export { updateScenario };
