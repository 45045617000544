import { FC } from "react";
import { Box, Button, List, ListItem, ListItemText, Stack, Typography } from "@mui/material";
import {
  CheckCircleOutlineOutlined as CheckCircleOutlineOutlinedIcon,
  ErrorOutlineOutlined as ErrorOutlineOutlinedIcon
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Modal } from "@/shared/ui/Modal";
import { SyncResultDto } from "@/entities/SyncedData";
import { getSheetDefinitionTitle } from "./utils";

interface Props {
  results: SyncResultDto[];
  open: boolean;
  onClose: VoidFunction;
  onNextClick: VoidFunction;
  onPreviousClick: VoidFunction;
}

const DescriptionContainer = styled(Box)(() => ({
  paddingLeft: "32px",
  opacity: 0.6
}));

const SyncResultModal: FC<Props> = (
  {
    results,
    open,
    onClose,
    onPreviousClick,
    onNextClick
  }
) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      width={800}
      title={<Typography variant="h6">Результаты синхронизации</Typography>}
      footer={
        <>
          <Button onClick={onPreviousClick} color="primary">Назад</Button>
          <Button onClick={onNextClick} color="primary">Далее</Button>
        </>
      }
    >
      <List>
        {results.map((result, index) => (
          <ListItem key={index}>
            {"error" in result ? (
              <Stack>
                <ListItemText
                  primary={
                    <Stack direction="row" spacing={1}>
                      <ErrorOutlineOutlinedIcon color="error" />
                      <Typography>{result.sheet?.name}</Typography>
                    </Stack>
                  }
                />
                <DescriptionContainer>
                  <Typography>{getSheetDefinitionTitle(result)}</Typography>
                  <Typography>Ошибка: {result.error}</Typography>
                </DescriptionContainer>
              </Stack>
            ) : (
              <>
                {result.sheetType === "input"
                  ? (
                    <Stack>
                      <ListItemText
                        primary={
                          <Stack direction="row" spacing={1}>
                            <CheckCircleOutlineOutlinedIcon color="success" />
                            <Typography>{result.sheet?.name}</Typography>
                          </Stack>
                        }
                      />
                      <DescriptionContainer>
                        <Typography>{getSheetDefinitionTitle(result)}</Typography>
                      </DescriptionContainer>
                    </Stack>
                  )
                  : (
                    <Stack>
                      <ListItemText
                        primary={
                          <Stack direction="row" spacing={1}>
                            <CheckCircleOutlineOutlinedIcon color="success" />
                            <Typography>{result.sheet?.name}</Typography>
                          </Stack>
                        }
                      />
                      <DescriptionContainer>
                        <Typography>{getSheetDefinitionTitle(result)}</Typography>
                        <Typography>Данные сохранены в таблицу <b>{result.tableName}</b></Typography>
                      </DescriptionContainer>
                    </Stack>
                  )}
              </>
            )}
          </ListItem>
        ))}
      </List>
    </Modal>
  );
};

export { SyncResultModal };
