import { FC } from "react";
import { Box, Typography, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBottomIcon from "@/assets/icons/arrowBottom.svg?react";

interface Breadcrumb {
  label: string;
  path?: string;
}

interface BreadcrumbsProps {
  backLink: string;
  breadcrumbs: Breadcrumb[];
  currentPage: {
    label: string;
    path?: string;
  };
}

export const BreadcrumbsNav: FC<BreadcrumbsProps> = ({
  backLink,
  breadcrumbs,
  currentPage,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Link
        to={backLink}
        style={{
          display: "flex",
          alignItems: "center",
          textDecoration: "none",
        }}
      >
        <ArrowBottomIcon
          color="#000"
          style={{ transform: "rotate(90deg)", marginRight: "8px" }}
        />
      </Link>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((breadcrumb, index) =>
          breadcrumb.path ? (
            <Link
              key={index}
              to={breadcrumb.path || "#"}
              style={{
                color: "#928686",
                textDecoration: "none",
              }}
            >
              {breadcrumb.label}
            </Link>
          ) : (
            <Typography key={index} color="textPrimary">
              {breadcrumb.label}
            </Typography>
          )
        )}

        {currentPage.path ? (
          <Link
            to={currentPage.path}
            style={{
              color: "#928686",
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            {currentPage.label}
          </Link>
        ) : (
          <Typography color="textPrimary">{currentPage.label}</Typography>
        )}
      </Breadcrumbs>
    </Box>
  );
};
