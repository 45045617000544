import { useQuery } from "@tanstack/react-query";
import { fetchVersionsByModelId } from "../api";
import { VersionMetaDto } from "../model";
import { versionsKeys } from "../const";

export const useVersionsByModelId = (financeModelId: number) => {
  return useQuery<VersionMetaDto[], Error>({
    queryKey: versionsKeys.byModelId(financeModelId).queryKey,
    queryFn: () => fetchVersionsByModelId(financeModelId),
    enabled: !!financeModelId,
  });
};
