import { format, formatDistanceToNow, parseISO } from "date-fns";
import { ru } from "date-fns/locale";

function formatDate(dateString: string, withTime?: boolean): string {
  const date = parseISO(dateString);
  const now = new Date();

  const formattedDate = format(date, "yyyy-MM-dd");

  if (formattedDate === format(now, "yyyy-MM-dd")) {
    return !withTime
      ? "Сегодня"
      : `Сегодня ${format(date, "HH:mm", { locale: ru })}`;
  }

  if (
    formattedDate ===
    format(new Date(now.setDate(now.getDate() - 1)), "yyyy-MM-dd")
  ) {
    return !withTime
      ? "Вчера"
      : `Вчера ${format(date, "HH:mm", { locale: ru })}`;
  }

  const distance = formatDistanceToNow(date, { addSuffix: true, locale: ru });
  if (
    distance.includes("дня") ||
    distance.includes("недели") ||
    distance.includes("месяца")
  ) {
    return distance;
  }

  return format(date, "d MMMM yyyy", { locale: ru });
}

const formatDateWithSeconds = (date: string) => {
  return format(date, "dd.MM.yyyy HH:mm:ss");
};

export { formatDate, formatDateWithSeconds };
