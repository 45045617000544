import { forwardRef, ForwardedRef } from "react";
import { Checkbox, FormControlLabel, CheckboxProps } from "@mui/material";
import { styled } from "@mui/system";
import { UseFormRegister, FieldValues, Path } from "react-hook-form";

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  padding: 0,
  "& .MuiSvgIcon-root": {
    display: "none"
  },
  "& .empty-square": {
    backgroundColor: theme.palette.customColors.colorAliceBlueDark,
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: "4px"
  },
  "&.Mui-checked .filled-square": {
    backgroundColor: theme.palette.customColors.colorBrandeisBlue,
    width: "12px",
    height: "12px",
    borderRadius: "2px",
    position: "absolute",
    top: "6px",
    left: "6px"
  },
  "&:hover": {
    backgroundColor: "transparent"
  },
  "&:focus-visible": {
    outline: "none"
  },
  "&:focus": {
    outline: "none"
  }
}));

interface CustomCheckboxProps<TFields extends FieldValues, TName extends Path<TFields>> extends CheckboxProps {
  label: string;
  name?: TName;
  register?: UseFormRegister<TFields>;
}

const CustomCheckbox = forwardRef(
  <TFields extends FieldValues, TName extends Path<TFields> = Path<TFields>>(
    { label, register, checked, onChange, ...props }: CustomCheckboxProps<TFields, TName>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    return (
      <FormControlLabel
        control={
          <StyledCheckbox
            {...(register && register(props.name!))}
            icon={<div className="empty-square" />}
            checkedIcon={
              <div style={{ position: "relative" }}>
                <div className="empty-square" />
                <div className="filled-square" />
              </div>
            }
            checked={checked}
            onChange={onChange}
            disableRipple
            ref={ref}
            {...props}
          />
        }
        label={label}
        labelPlacement="end"
        sx={{ gap: "8px", marginLeft: 0, marginRight: 0 }}
      />
    );
  }
);

export { CustomCheckbox, StyledCheckbox };
