import React, { useEffect, useState, useRef, FC } from "react";
import {
  CssBaseline,
  Box,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { AxiosError } from "axios";
// @ts-expect-error
import LuckyExcel from "luckyexcel";
import { usePermissions } from "@/entities/User";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";
import LuckysheetComponent from "@/widgets/LuckysheetComponent";
import Editor, { OnMount } from "@monaco-editor/react";
import Header from "@/widgets/Header";
import SettingsIcon from "@/assets/icons/settings.svg?react";
import DublicateIcon from "@/assets/icons/dublicate.svg?react";
import DeleteIcon from "@/assets/icons/delete.svg?react";
import ArrowBottomIcon from "@/assets/icons/arrowBottom.svg?react";
import ClockIcon from "@/assets/icons/clock.svg?react";
import ImportFolder from "@/assets/icons/importFolder.svg?react";
import ExportFolder from "@/assets/icons/exoportFolder.svg?react";
import DashboardIcon from "@/assets/icons/dashboard.svg?react";
import SaveIcon from "@/assets/icons/save.svg?react";
import TemplateIcon from "@/assets/icons/template.svg?react";
import PlusIcon from "@/assets/icons/plus.svg?react";
import SyncIcon from "@/assets/icons/sync.svg?react";
import PublicIcon from "@/assets/icons/public.svg?react";
import InfoIcon from "@/assets/icons/info.svg?react";
import VersionIcon from "@/assets/icons/version.svg?react";
import NotificationIcon from "@/assets/icons/notification.svg?react";
import { routePaths } from "@/shared/config/routePaths";
import { BreadcrumbsNav } from "@/widgets/BreadcrumbsNav";
import {
  useCloneModel,
  useDeleteFinanceModel,
  useFinanceModelPublish,
} from "@/entities/FinanceModel/queries";
import { useNotification } from "@/shared/hooks/useNotification";
import AddIcon from "@mui/icons-material/Add";
import {
  LuckysheetDataProvider,
  useLuckySheetContext,
} from "@/contexts/LuckysheetDataContext";
import { DashboardsModal } from "@/widgets/DashboardsModal";
import { PublishingProcess } from "@/widgets/ModelPublishing";
import { RightSidebar } from "@/shared/ui/RightSidebar";
import { BackupList } from "@/widgets/Backups/Backups";
import { BackupCreateModal } from "@/features/Backup/BackupCreateModal";
import { FinanceModelCreate } from "@/features/FinanceModel/FinanceModelCreate";
import { useFinanceModelByGridkey } from "@/entities/FinanceModel/queries/useFinanceModelByGridkey";
import { ScreenshotUploader } from "@/features/ScreenshotUploader";
import { FinanceModelBackupSettings } from "@/features/FinanceModel/FinanceModelBackupSettings";
import { SquareButtonWithIcon } from "@/shared/ui/SquareBtnWithIcon";
import {
  checkIfCanViewOnly,
  FinanceModelDto,
  useFinanceModelEdit,
} from "@/entities/FinanceModel";
import { ModelActions } from "@/widgets/ModelActions/ui/ModelActions";
import { FinanceModelShareModal } from "@/features/FinanceModel/FinanceModelShareModal";
import { useDownloadFile } from "@/features/FinanceModel/hooks/useDownloadFile";
import { useUserMenuGroups } from "@/features/User";
import { TemplateUseDto } from "@/entities/Templates";
import { TemplateCreateModal } from "@/features/Templates/TemplatesCreateModal";
import { BackupDto } from "@/entities/Backup";
import { ConfirmationDialog } from "@/shared/ui/ConfirmDialog/ui/ConfirmDialog";
import { qc } from "@/app/providers/QueryClient/const";
import { financeModelKeys } from "@/entities/FinanceModel/const";
import styles from "./GridPage.module.css";

type ModalName = "backups" | "versions" | "sharemodal";

interface RouteParams {
  gridkey: string;
  modalName: ModalName;
}

const GridPageInner: FC = () => {
  const [openedSidebar, setOpenedSidebar] = useState<
    "backup" | "versions" | null
  >(null);
  const [isCreateBackupModalOpen, setIsCreateBackupModalOpen] =
    useState<boolean>(false);
  const [isDashboardsDialogOpen, setIsDashboardsDialogOpen] =
    useState<boolean>(false);
  const [isOpenPublishingDialog, setIsOpenPublishingDialog] =
    useState<boolean>(false);
  const [isOpenShareModelModal, setIsOpenShareModelModal] =
    useState<boolean>(false);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
  const [isSecondSidebarOpen, setIsSecondSidebarOpen] =
    useState<boolean>(false);
  const [isTemplateCreateModalOpen, setIsTemplateCreateModalOpen] =
    useState<boolean>(false);
  const [isInsertingFromXlsFile, setInsertingFromXlsFile] =
    useState<boolean>(false);
  const { setOptions } = useLuckySheetContext();
  const [openedBackup, setOpenedBackup] = useState<BackupDto | null>(null);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] =
    useState<boolean>(false);

  const { gridkey: gridkeyEncoded, modalName } = useParams<RouteParams>();
  const { data: user } = useUserProfile();
  const editorRef = useRef<any>(null);
  const { mutate: deleteFinanceModelById } = useDeleteFinanceModel();
  const { mutate: clone } = useCloneModel();
  const { mutate: publishFinanceModelById } = useFinanceModelPublish();
  const { push } = useHistory();
  const { notifyError, notifySuccess, notifyInfo } = useNotification();
  const { downloadFile } = useDownloadFile();
  const {
    mutate: editFinanceModel,
    isPending: isPendingEditFinanceModel,
    isSuccess: isSucessEditFinanceModel,
    isError: isErrorEditFinanceModel,
  } = useFinanceModelEdit();
  const iCan = usePermissions();
  const gridkey = decodeURIComponent(gridkeyEncoded ?? "");
  const {
    data: currentModel,
    isLoading,
    isError,
    error,
  } = useFinanceModelByGridkey(gridkeyEncoded);
  const insertFromFileInputRef = useRef<HTMLInputElement>(null);
  const modelName = currentModel?.name || "";
  const formulaEngine = currentModel?.formulaEngine;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPage = searchParams.get("fromPage")?.replace("/", "");

  const message: string | undefined = (() => {
    if (!gridkey) return "Gridkey отсутствует";
    return undefined;
  })();

  //Пользовательское меню с набором опций
  const userMenuGroups = useUserMenuGroups();

  const payloadModals: { [key in ModalName]: () => void } = {
    backups: () => handleToggleRightSidebar("backup"),
    versions: () => handleToggleRightSidebar("versions"),
    sharemodal: () => setIsOpenShareModelModal(true),
  };

  useEffect(() => {
    if (modalName && payloadModals[modalName]) {
      payloadModals[modalName]();
    }
  }, [modalName]);

  useEffect(() => {
    if (currentModel) {
      const canViewModel = checkIfCanViewOnly(user, currentModel);

      if (canViewModel)
        setOptions({
          allowEdit: false,
          showinfobar: false,
          showtoolbar: false,
        });
    }
  }, [currentModel]);

  useEffect(() => {
    if (!isLoading && !currentModel) {
      if (isError && error) {
        let errorMessage = "Произошла ошибка";

        if (error instanceof AxiosError) {
          errorMessage = error.message || errorMessage;
        } else {
          errorMessage = (error as Error).message || errorMessage;
        }
        console.error(errorMessage);
        notifyError("Модель не найдена", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
      push(routePaths.models);
    }
  }, [isLoading, currentModel, push, error, isError]);

  useEffect(() => {
    if (isSucessEditFinanceModel) {
      notifySuccess("Скрипт успешно сохранен", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
    if (isErrorEditFinanceModel) {
      notifyError("Ошибка сохранения скрипта", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  }, [isSucessEditFinanceModel, isErrorEditFinanceModel]);

  const onSuccessCreateModel = (model: FinanceModelDto | TemplateUseDto) => {
    push(`${routePaths.grid}/${model.gridkey}`);
    notifySuccess("Модель создана", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsOpenCreateModal(false);
  };

  const onErrorCreateModel = (error: Error) => {
    const axiosError = error as AxiosError;
    const message =
      (axiosError.response?.data as { error: string })?.error ??
      "Ошибка создания";
    notifyError(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsOpenCreateModal(false);
  };

  const onSuccessShareModel = () => {
    notifySuccess("Успешное действие", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsOpenShareModelModal(false);
  };

  const onSuccessCreateTemplate = () => {
    notifySuccess("Шаблон создан", {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsTemplateCreateModalOpen(false);
  };

  const onErrorCreateTemplate = (error: Error) => {
    const axiosError = error as AxiosError;
    const message =
      (axiosError.response?.data as { error: string })?.error ??
      "Ошибка создания";
    notifyError(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsTemplateCreateModalOpen(false);
  };

  const onErrorShareModel = (error: Error) => {
    const axiosError = error as AxiosError;
    const message =
      (axiosError.response?.data as { error: string })?.error ?? "Not share";
    notifyError(message, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
      },
    });
    setIsOpenCreateModal(false);
  };

  const handleInsertFromXLSFile = () => {
    insertFromFileInputRef.current?.click();
  };

  const handleEditorDidMount: OnMount = (editor) => {
    editorRef.current = editor;
  };

  const getEditorValue = () => {
    if (editorRef.current) {
      const code = editorRef.current.getValue();
      return code;
    }
  };

  const handleInsertedFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    console.log("Selected file:", file);
    if (file) {
      setInsertingFromXlsFile(true);
      try {
        const fileData = await file.arrayBuffer();
        console.log("File data loaded:", fileData);

        LuckyExcel.transformExcelToLucky(fileData, (exportJson: any) => {
          console.log("LuckyExcel transformation result:", exportJson);
          if (
            !exportJson ||
            !exportJson.sheets ||
            exportJson.sheets.length === 0
          ) {
            alert(
              "Failed to read the content of the Excel file. Currently does not support xls files!"
            );
            return;
          }

          const importedSheets = exportJson.sheets;
          // Add the imported sheets to Luckysheet
          importedSheets.forEach((importedSheet: any) => {
            // const newSheetData = {
            //   ...sheet,
            //   index: window.luckysheet?.generateRandomSheetIndex(),
            //   order: window.luckysheet?.getSheetOrder().length,
            //   status: 0,
            // };
            window.luckysheet?.setSheetAdd({ sheetObject: importedSheet });
            console.log("LuckyExcel inported sheet added :", importedSheet);
          });

          // Pass the data to LuckysheetComponent
          // setLuckysheetData(exportJson);
          console.log("Luckysheet data set in context:", exportJson);

          // Reset the file input
          if (insertFromFileInputRef.current) {
            insertFromFileInputRef.current.value = "";
          }
          // Optionally, set the first imported sheet as active
          // starting with zero
          const firstImportedSheetIndex = importedSheets[0].index - 1;
          window.luckysheet?.setSheetActive(firstImportedSheetIndex);
        });
      } catch (error) {
        console.error("Error reading file", error);
      } finally {
        setInsertingFromXlsFile(false);
      }
    }
  };

  const handleOpenTemplateCreateModal = () => {
    setIsTemplateCreateModalOpen(true);
  };

  const handleOpenCreateModal = () => {
    setIsOpenCreateModal(true);
  };

  const handleCloseCreateModal = () => {
    setIsOpenCreateModal(false);
  };

  const handleOpenBackupCreateModal = () => {
    setIsCreateBackupModalOpen(true);
  };

  const handleCloseBackupCreateModal = () => {
    setIsCreateBackupModalOpen(false);
  };

  const handleOpenDashboardsDialogClick = () => {
    setIsDashboardsDialogOpen(true);
  };

  const handleCloseDashboardsDialog = () => {
    setIsDashboardsDialogOpen(false);
  };

  const handleSyncDialogClose = () => {
    setIsOpenPublishingDialog(false);
  };

  function handleToggleRightSidebar(type: typeof openedSidebar) {
    setOpenedSidebar(type);
  }

  //Удаление модели
  const handleDeleteModel = async () => {
    try {
      if (currentModel) {
        await deleteFinanceModelById(currentModel.id);
        push(routePaths.models);
        notifySuccess("Модель удалена", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
    } catch {
      notifyError("Ошибка удаления", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  //Клонирование / дублирование модели
  const handleCloneModel = async () => {
    try {
      if (currentModel) {
        notifyInfo("Создается копия модели", {
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
        await clone(
          { financeModelId: currentModel.id },
          {
            onSuccess: (data) => {
              const action = () => (
                <>
                  <Button
                    variant="lightBlueV300"
                    onClick={() => {
                      push(`${routePaths.grid}/${data.newModel.gridkey}`);
                      notifyInfo("Вы перешли в копию", {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center",
                        },
                      });
                    }}
                  >
                    Перейти
                  </Button>
                </>
              );
              notifySuccess("Продолжить работать в копии?", {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                className: "notistack-snackbar-info",
                autoHideDuration: 5000,
                action,
              });
            },
          }
        );
      }
    } catch {
      notifyError("Ошибка дублирования", {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      });
    }
  };

  const handlePublishModel = async () => {
    if (currentModel) {
      await publishFinanceModelById(currentModel.id, {
        onSuccess: () => {
          const msg = currentModel.isPublished
            ? "Модель снята с публикации"
            : "Модель опубликована";
          notifySuccess(msg, {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
          qc.invalidateQueries(financeModelKeys.inputs(gridkey));
        },
        onError: (error: Error) => {
          const axiosError = error as AxiosError;
          const message =
            (axiosError.response?.data as { message: string })?.message ??
            "Ошибка публикации";
          notifyError(message ?? "Ошибка публикации", {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
          });
        },
      });
    }
  };

  const handleOpenSecondSidebar = () => {
    setIsSecondSidebarOpen(true);
  };

  const handleCloseSecondSidebar = () => {
    setIsSecondSidebarOpen(false);
  };

  const modelMenuGroups = [
    {
      items: [
        ...(gridkey
          ? [
              {
                label: "Создать новую модель",
                icon: <PlusIcon />,
                onClick: iCan.createModel
                  ? handleOpenCreateModal
                  : () => notifyInfo("Нет прав, обратитесь к администратору"),
                extraClasses: iCan.createModel ? "" : styles.disabled,
              },
            ]
          : []),
        ...(gridkey
          ? [
              {
                label: "Дублировать",
                icon: <DublicateIcon width={18} height={18} />,
                onClick: iCan.createModel
                  ? () => handleCloneModel()
                  : () => notifyInfo("Нет прав, обратитесь к администратору"),
                extraClasses: iCan.createModel ? "" : styles.disabled,
              },
            ]
          : []),
        {
          label: "Сохранить как",
          icon: <SaveIcon width={12.5} height={18} />,
          onClick: () =>
            notifyInfo("В будущем можно будет сохранять модель локально", {
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }),
        },
        ...(gridkey
          ? [
              {
                label: "Сохранить как шаблон",
                icon: <TemplateIcon width={14} height={18} />,
                onClick: iCan.createTemplate
                  ? () => handleOpenTemplateCreateModal()
                  : () => notifyInfo("Нет прав, обратитесь к администратору"),
                extraClasses: iCan.createTemplate ? "" : styles.disabled,
              },
            ]
          : []),
      ],
      divider: true,
    },
    {
      items: gridkey
        ? [
            currentModel?.isPublished
              ? {
                  label: "Снять с публикации",
                  icon: <PublicIcon width={20} height={20} />,
                  onClick: iCan.submitForPublication
                    ? handlePublishModel
                    : () => notifyInfo("Нет прав, обратитесь к администратору"),
                  extraClasses: iCan.submitForPublication
                    ? ""
                    : styles.disabled,
                }
              : {
                  label: "Опубликовать модель",
                  icon: <PublicIcon width={20} height={20} />,
                  onClick: iCan.submitForPublication
                    ? handlePublishModel
                    : () => notifyInfo("Нет прав, обратитесь к администратору"),
                  extraClasses: iCan.submitForPublication
                    ? ""
                    : styles.disabled,
                },
          ]
        : [],
      divider: true,
    },
    {
      items: [
        ...(gridkey
          ? [
              {
                label: "Показать резервные копии",
                icon: <ClockIcon width={20} height={20} />,
                onClick: iCan.manageBackups
                  ? () => handleToggleRightSidebar("backup")
                  : () => notifyInfo("Нет прав, обратитесь к администратору"),
                extraClasses: iCan.manageBackups ? "" : styles.disabled,
              },
            ]
          : []),
        ...(gridkey
          ? [
              {
                label: "Сохранить резервную копию",
                icon: <SyncIcon width={18} height={16.5} />,
                onClick: iCan.manageBackups
                  ? handleOpenBackupCreateModal
                  : () => notifyInfo("Нет прав, обратитесь к администратору"),
                extraClasses: iCan.manageBackups ? "" : styles.disabled,
              },
            ]
          : []),

        ...(gridkey
          ? [
              {
                label: "Редактор форм",
                icon: <VersionIcon width={16.5} height={19.5} />,
                onClick: iCan.createNewVersions
                  ? () =>
                      push(
                        `${routePaths.grid}/${gridkeyEncoded}${routePaths.versions}`
                      )
                  : () => notifyInfo("Нет прав, обратитесь к администратору"),
                extraClasses: iCan.createNewVersions ? "" : styles.disabled,
              },
            ]
          : []),

        ...(gridkey
          ? [
              {
                label: "Дашборды",
                icon: <DashboardIcon width={19} height={14} />,
                onClick: iCan.addDashboard
                  ? handleOpenDashboardsDialogClick
                  : () => notifyInfo("Нет прав, обратитесь к администратору"),
                extraClasses: iCan.addDashboard ? "" : styles.disabled,
              },
            ]
          : []),
      ],
      divider: true,
    },
    {
      items: [
        ...(gridkey
          ? [
              {
                label: "Экспортировать",
                icon: <ExportFolder width={19.5} height={16.5} />,
                onClick: () => {
                  downloadFile(
                    // eslint-disable-next-line no-constant-binary-expression
                    `${currentModel?.name}.xlsx` || "test.xlsx",
                    gridkey
                  ).catch((error: Error) => {
                    const axiosError = error as AxiosError;
                    const message =
                      (axiosError.response?.data as { message: string })
                        ?.message ?? "Ошибка экспорта";
                    notifyError(message, {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    });
                  });
                },
              },
            ]
          : []),
        {
          label: "Вставить из файла (XLS)",
          icon: <ImportFolder width={19.5} height={16.5} />,
          onClick: handleInsertFromXLSFile,
        },
      ],
      divider: true,
    },
    {
      items: [
        ...(gridkey
          ? [
              {
                label: "Информация",
                icon: <InfoIcon width={18} height={18} />,
                onClick: () => {
                  push(
                    `${routePaths.grid}/${gridkeyEncoded}${routePaths.modelInfo}`
                  );
                },
              },
            ]
          : []),
        ...(gridkey && iCan.deleteModel
          ? [
              {
                //TODO: конфирм модалку надо сделать в будущем
                label: "Удалить",
                icon: <DeleteIcon width={16} height={20} />,
                onClick: () => setIsConfirmDeleteOpen(true),
              },
            ]
          : []),
      ],
    },
  ];

  const backupSidebarHeader = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "16px",
        flexGrow: 1,
      }}
    >
      <Typography variant="h6">Резервные копии</Typography>
      <Box>
        <IconButton
          onClick={handleOpenBackupCreateModal}
          sx={{ color: "#000" }}
        >
          <AddIcon />
        </IconButton>
        <IconButton onClick={handleOpenSecondSidebar}>
          <SettingsIcon color={"#000"} width={24} height={24} />
        </IconButton>
      </Box>
    </Box>
  );
  const backupSidebarContent = currentModel && (
    <BackupList
      financeModelId={currentModel.id}
      onOpenBackup={setOpenedBackup}
      onClose={() => handleToggleRightSidebar(null)}
    />
  );

  return (
    <Box
      sx={{
        display: "grid",
        height: "100vh",
        width: "100%",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "var(--headerHeight) 1fr",
        columnGap: 0,
        rowGap: 0,
      }}
    >
      <CssBaseline />
      <Header
        UserMenuGroups={{
          menuGroups: userMenuGroups,
          alignment: "right",
          profileImage: user?.avatar,
        }}
        modelMenuProps={{
          menuGroups: modelMenuGroups,
        }}
        leftSlot={
          <Box
            sx={{
              marginLeft: "16px",
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <BreadcrumbsNav
              backLink={routePaths.models}
              breadcrumbs={[
                {
                  label: currentModel
                    ? String(currentModel?.id)
                    : "Загрузка...",
                },
                ...(fromPage
                  ? [
                      {
                        label: modelName,
                        path: `/grid/${gridkeyEncoded}`,
                      },
                    ]
                  : []),
              ]}
              currentPage={{
                label: fromPage ? "Информация" : modelName,
                path: fromPage
                  ? `/grid/${gridkeyEncoded}/${fromPage}`
                  : undefined,
              }}
            />
          </Box>
        }
        rightSlot={
          !isLoading && (
            <>
              {formulaEngine === "python" || formulaEngine === "R" ? (
                <Button
                  variant="lightBlueV300"
                  onClick={() => {
                    if (currentModel?.id)
                      editFinanceModel({
                        id: currentModel?.id,
                        sourceCode: getEditorValue(),
                        gridKey: currentModel.gridkey,
                      });
                  }}
                  sx={{
                    padding: "8px 16px",
                    fontSize: "14px",
                    lineHeight: "20px",
                  }}
                >
                  {isPendingEditFinanceModel ? "Сохранение..." : "Сохранить"}
                </Button>
              ) : (
                <>
                  <ModelActions
                    setIsOpenSyncDialog={setIsOpenPublishingDialog}
                    setIsOpenShareModelModal={setIsOpenShareModelModal}
                    openedBackup={openedBackup}
                    onGoBackToModel={() => setOpenedBackup(null)}
                  />
                  <SquareButtonWithIcon
                    onClick={() => {}}
                    endIcon={<NotificationIcon width={17} height={18} />}
                    sx={{
                      marginLeft: "8px",
                      padding: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                </>
              )}
            </>
          )
        }
      />
      <div
        style={{
          position: "relative",
          gridRow: "2 / -1",
          gridColumn: "1 / -1",
        }}
      >
        {message && (
          <Typography
            m="24px 0 0 0"
            sx={{ textAlign: "center", width: "100%" }}
          >
            {message}
          </Typography>
        )}
        {gridkey &&
          !isLoading &&
          (formulaEngine === "python" || formulaEngine === "R" ? (
            <Editor
              height="90vh"
              defaultLanguage={formulaEngine.toLowerCase()} // Use formulaEngine value as language
              defaultValue={currentModel?.sourceCode || ""}
              theme="vs-light"
              onMount={handleEditorDidMount}
            />
          ) : (
            <LuckysheetComponent
              gridkey={gridkey}
              formulaEngine={formulaEngine}
            />
          ))}
      </div>
      {currentModel && (
        <RightSidebar
          isOpen={!!openedSidebar}
          toggleSidebar={(open) => {
            if (!open) handleToggleRightSidebar(null);
          }}
          header={openedSidebar === "backup" ? backupSidebarHeader : null}
          content={openedSidebar === "backup" ? backupSidebarContent : null}
        />
      )}
      {currentModel && isSecondSidebarOpen && (
        <RightSidebar
          isOpen={isSecondSidebarOpen}
          toggleSidebar={handleCloseSecondSidebar}
          header={
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "16px",
                flexGrow: 1,
              }}
            >
              <Box sx={{ display: "flex", gap: "18px", alignItems: "center" }}>
                <Box
                  onClick={handleCloseSecondSidebar}
                  sx={{ cursor: "pointer" }}
                >
                  <ArrowBottomIcon
                    color="#000"
                    style={{ transform: "rotate(90deg)", marginRight: "8px" }}
                  />
                </Box>

                <Typography variant="h6">Настройки копий</Typography>
              </Box>
            </Box>
          }
          content={<FinanceModelBackupSettings financeModel={currentModel} />}
        />
      )}
      {currentModel && (
        <BackupCreateModal
          open={isCreateBackupModalOpen}
          handleClose={handleCloseBackupCreateModal}
          financeModelId={currentModel.id}
        />
      )}
      {currentModel && (
        <DashboardsModal
          open={isDashboardsDialogOpen}
          handleClose={handleCloseDashboardsDialog}
          financeModelId={currentModel.id}
        />
      )}
      {currentModel && (
        <PublishingProcess
          isOpen={isOpenPublishingDialog}
          onClose={handleSyncDialogClose}
          financeModelId={currentModel.id}
          gridkey={currentModel.gridkey}
        />
      )}
      <FinanceModelCreate
        open={isOpenCreateModal}
        handleClose={handleCloseCreateModal}
        onSuccess={onSuccessCreateModel}
        onError={onErrorCreateModel}
      />
      {currentModel && (
        <FinanceModelShareModal
          isOpen={isOpenShareModelModal}
          handleClose={() => setIsOpenShareModelModal(false)}
          modelId={currentModel.id}
          onSuccess={onSuccessShareModel}
          onError={onErrorShareModel}
        />
      )}
      {currentModel && (
        <TemplateCreateModal
          financeModelId={currentModel.id}
          handleClose={() => setIsTemplateCreateModalOpen(false)}
          open={isTemplateCreateModalOpen}
          onSuccess={onSuccessCreateTemplate}
          onError={onErrorCreateTemplate}
        />
      )}

      <ConfirmationDialog
        title="Удаление"
        description="Вы уверены, что хотите удалить модель?"
        open={isConfirmDeleteOpen}
        onConfirm={handleDeleteModel}
        onClose={() => setIsConfirmDeleteOpen(false)}
      />

      <input
        type="file"
        accept=".xlsx,.xls"
        ref={insertFromFileInputRef}
        style={{ display: "none" }}
        onChange={handleInsertedFileChange}
      />

      {currentModel && <ScreenshotUploader modelId={currentModel.id} />}

      {isInsertingFromXlsFile && (
        <Box
          sx={{
            padding: "16px",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "1000",
            background: "white",
            borderRadius: "4px",
            boxShadow:
              "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
          }}
        >
          {"Импортирование Xls..."}
        </Box>
      )}
    </Box>
  );
};

const GridPage: FC = () => {
  return (
    <LuckysheetDataProvider>
      <GridPageInner />
    </LuckysheetDataProvider>
  );
};

export default GridPage;
