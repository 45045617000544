import { Box, Typography } from "@mui/material";

export const FinanceModelPreviewTitleList = () => {
  const headers = [
    "Изображение",
    "Название",
    "Описание",
    "Автор",
    "Обновлено",
    "Статус",
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns:
          "minmax(0,140px) minmax(0,256px) minmax(0,412px) minmax(0,212px) minmax(0,212px) minmax(0,212px)",
        columnGap: "24px",
        marginBottom: "32px",
        padding: "10px",
      }}
    >
      {headers.map((name, index) => (
        <Typography key={index} sx={{ fontWeight: 500 }}>
          {name}
        </Typography>
      ))}
    </Box>
  );
};
