import { FC } from "react";
import { UseFormReturn } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { Modal } from "@/shared/ui/Modal";
import { CheckedSheets, SheetsSettingsForm } from "./interfaces";
import { SheetPurposesGroupControl } from "./SheetPurposesGroupControl";
import { isSheetChecked } from "./utils";

interface Props {
  form: UseFormReturn<SheetsSettingsForm>;
  open: boolean;
  onClose: VoidFunction;

  onNextClick(checkedSheets: CheckedSheets): Promise<void> | void;

  loading?: boolean;
}

const sx: Record<string, SxProps<Theme>> = {
  groupTitle: {
    color: "#727272",
    lineHeight: "171%",
    mb: "16px",
  },
  controlsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(220px,1fr))",
    gap: "24px",
    mb: "32px",
  },
};

const SheetsSettingsModal: FC<Props> = ({
  open,
  onClose,
  onNextClick,
  form,
  loading,
}) => {
  const { register, control, handleSubmit } = form;

  const { mutate: onSubmit, isPending: isSubmitting } = useMutation({
    mutationFn: async (data: SheetsSettingsForm) =>
      onNextClick({
        formInputs: data.forms.inputs.filter(isSheetChecked),
        formOutputs: data.forms.outputs.filter(isSheetChecked),
        biInputs: data.bi.inputs.filter(isSheetChecked),
        biOutputs: data.bi.outputs.filter(isSheetChecked),
      }),
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      loading={loading}
      title={<Typography variant="h6">Публикация мастер модели</Typography>}
      footer={
        <Button
          onClick={handleSubmit((data) => onSubmit(data))}
          disabled={loading || isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={14} /> : undefined}
        >
          Далее
        </Button>
      }
      width={800}
    >
      <form>
        <Typography sx={sx.groupTitle}>Для аналитика</Typography>

        <Box sx={sx.controlsContainer}>
          <SheetPurposesGroupControl
            title="Листы доступные для редактирования"
            name="forms.inputs"
            control={control}
            register={register}
          />
          <SheetPurposesGroupControl
            title="Листы доступные для расчётов"
            name="forms.outputs"
            control={control}
            register={register}
          />
          {/* <SheetPurposesGroupControl
            title="Скрыть листы"
            name="forms.hidden"
            control={control}
            register={register}
          /> */}
        </Box>

        <Typography sx={sx.groupTitle}>Для BI дашбордов</Typography>

        <Box sx={sx.controlsContainer}>
          <SheetPurposesGroupControl
            title="Листы параметров"
            name="bi.inputs"
            control={control}
            register={register}
          />
          <SheetPurposesGroupControl
            title="Листы для BI системы"
            name="bi.outputs"
            control={control}
            register={register}
          />
        </Box>
      </form>
    </Modal>
  );
};

export { SheetsSettingsModal };
