import { Box, useTheme } from "@mui/material";
import { FC, ReactNode } from "react";

interface FinanceModelProfileCardProps {
  onClick: () => void;
  children: ReactNode;
}

const FinanceModelProfileCard: FC<FinanceModelProfileCardProps> = ({ children, onClick }) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        border: `1px solid ${theme.palette.customColors.colorLightBlue200}`,
        borderRadius: "8px",
        padding: "16px",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
};

export { FinanceModelProfileCard };
