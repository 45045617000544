import { useMutation } from "@tanstack/react-query";
import { useNotification } from "@/shared/hooks/useNotification";
import { restoreBackupToNewModel } from "../api";

const useRestoreBackupToNewModel = () => {
  const { notifyError } = useNotification();

  return useMutation({
    mutationFn: restoreBackupToNewModel,

    onError: () => notifyError("Не удалось восстановить резервную копию")
  });
};

export { useRestoreBackupToNewModel };
