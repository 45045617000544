import { api } from "@/shared/config/network";

interface UpdateBackupParams {
  financeModelId: number; // ID финансовой модели
  backupData: {
    backupFrequency: string;
    backupRetentionDays: number;
    backupsToKeep: number;
    backupEnabled: boolean;
  };
}

interface MessageResponse {
  message: string;
}

const updateSettingsBackups = async ({
  financeModelId,
  backupData,
}: UpdateBackupParams) => {
  const response = await api.put<MessageResponse>(
    `/finance-models/${financeModelId}/backup-settings`,
    backupData
  );
  return response.data;
};

export { updateSettingsBackups };
