import { FC, PropsWithChildren } from "react";
import { Backdrop, Box, CircularProgress, Divider, IconButton, Modal as MuiModal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps, Theme } from "@mui/material/styles";

interface BaseModalProps extends PropsWithChildren {
  open: boolean;
  onClose?: VoidFunction;
  width?: number;
  sx?: Partial<Record<"modal" | "inner", SxProps<Theme>>>;
  loading?: boolean;
}

const sx: Record<string, SxProps<Theme>> = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    display: "flex",
    minHeight: "48px"
  },
  body: {
    padding: "8px 16px",
    margin: "auto"
  },
  closeIcon: {
    position: "absolute",
    top: "4px",
    right: "4px"
  }
};

const BaseModal: FC<BaseModalProps> = (
  {
    open,
    onClose,
    children,
    width,
    sx: outerSx,
    loading
  }
) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      sx={outerSx?.modal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: () => null
        }
      }}
    >
      <Box
        sx={{
          ...sx.container,
          ...(outerSx?.inner ?? {}),
          ...(width !== undefined ? { width: `${width}px` } : {})
        } as SxProps<Theme>}
      >
        <Box sx={sx.closeIcon}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        <Box sx={sx.body}>
          {
            loading
              ? (
                <Box>
                  <CircularProgress size={24} />
                </Box>
              )
              : children
          }
        </Box>
      </Box>
    </MuiModal>
  );
};

export { BaseModal, type BaseModalProps };
