import { FC } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  IconButton,
  DialogContentText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useForm, SubmitHandler } from "react-hook-form";
import { useBackupCreate } from "@/entities/Backup";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { useNotification } from "@/shared/hooks/useNotification";
import { Input } from "@/shared/ui/Input/Input"; // Подключаем ваш кастомный Input

interface BackupCreateModalProps {
  financeModelId: number;
  open: boolean;
  handleClose: () => void;
}

interface BackupFormData {
  description: string;
  versionName: string;
}

export const BackupCreateModal: FC<BackupCreateModalProps> = ({
  financeModelId,
  open,
  handleClose,
}) => {
  const { mutateAsync: createBackup } = useBackupCreate();
  const { notifyError, notifySuccess } = useNotification();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors, isValid },
  } = useForm<BackupFormData>({
    defaultValues: {
      description: "",
      versionName: new Date().toDateString(),
    },
    mode: "onChange",
  });

  const onSubmit: SubmitHandler<BackupFormData> = async (data) => {
    try {
      await createBackup({ financeModelId, ...data });
      handleClose();
      reset();
      notifySuccess("Резервная копия создана", {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    } catch (error) {
      console.error("Failed to create backup:", error);
      notifyError("Ошибка создания копии", {
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    }
  };

  const handleModalClose = () => {
    reset();
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="20px"
        >
          <Typography variant="h6">Создание резервной копии</Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <DialogContentText>
            Введите описание новой резервной копии
          </DialogContentText>

          <Input
            {...register("versionName", { required: "Имя версии обязательно" })}
            placeholder="Имя версии"
            fullWidth
            margin="normal"
            disabled={isSubmitting}
            error={!!errors.versionName}
            helperText={errors.versionName?.message}
          />

          <Input
            {...register("description", {
              required: "Описание обязательно",
              minLength: {
                value: 1,
                message: "Описание должно быть не менее 1 символа",
              },
            })}
            placeholder="Описание"
            fullWidth
            margin="normal"
            disabled={isSubmitting}
            error={!!errors.description}
            helperText={errors.description?.message}
          />

          <Box mt={2}>
            <Button
              color="primary"
              type="submit"
              variant="lightBlueV100"
              fullWidth
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? (
                <Box sx={{ width: "100%", height: "37px" }}>
                  <Spinner withBg={true} />
                </Box>
              ) : (
                "Сохранить"
              )}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
