import { FinanceModelDto } from "@/entities/FinanceModel";
import { BackupDto } from "@/entities/Backup";

interface BadgeConfig {
  text: string;
  textColor?: string;
  color: string;
  condition: (model: FinanceModelDto, backups: BackupDto[]) => boolean;
  priority: number;
}

const badges: BadgeConfig[] = [
  {
    text: "Новая",
    color: "#59ff00",
    textColor: "#056609",
    condition: (model) => {
      const createdAt = new Date(model.createdAt);
      return new Date().getTime() - createdAt.getTime() < 24 * 60 * 60 * 1000; //модель созданная не позже 24 часов назад
    },
    priority: 2,
  },
  {
    text: "Мастер-модель",
    color: "blue",
    condition: (model) => model?.isPublished || false,
    priority: 1, //высокий приоритет
  },
  {
    text: "Новая форма",
    color: "#DDFAC6",
    textColor: "#056609",
    condition: (model) => {
      const updatedAt = new Date(model.updatedAt);
      return new Date().getTime() - updatedAt.getTime() < 24 * 60 * 60 * 1000; //была ли модель обновлена в последние 24 часа
    },
    priority: 3,
  },
  {
    text: "Нужен бекап",
    textColor: "black",
    color: "yellow",
    condition: (_, backups) => {
      if (backups.length === 0) return true;

      const lastBackupDate = new Date(
        Math.max(
          ...backups.map((backup) => new Date(backup.updatedAt).getTime())
        )
      );
      return (
        new Date().getTime() - lastBackupDate.getTime() > 24 * 60 * 60 * 1000 //Нет бекапа больше 24 часов
      );
    },
    priority: 4,
  },
];

export { badges };
