import { api } from "@/shared/config/network";

interface RestoreBackupToNewModelParams {
  versionId: number;
  name: string;
  description: string;
}

interface RestoreBackupToNewModelResponse {
  gridKey: string;
}

const restoreBackupToNewModel = async ({ versionId, name, description }: RestoreBackupToNewModelParams) => {
  const { data } = await api.post<RestoreBackupToNewModelResponse>(
    `/backups/restore/${versionId}`,
    {
      createNew: true,
      name,
      description
    }
  );

  return data;
};

export { restoreBackupToNewModel, type RestoreBackupToNewModelParams };
