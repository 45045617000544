import { api } from "@/shared/config/network";

const switchCompany = async (organizationId: number) => {
  try {
    const response = await api.post<{ message: string; token: string }>(
      `/organizations/switch`,
      { organizationId }
    );

    return {
      message: response.data.message,
      token: response.data.token,
    };
  } catch (error) {
    console.error("Error switching company:", error);
    throw new Error("Произошла ошибка при переключении компании.");
  }
};

export { switchCompany };
