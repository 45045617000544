import { useEffect, useState } from "react";
import { getScrollbarWidth } from "../config/lib";
import { debounce } from "@mui/material";

export const useDisableScrollLock = () => {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  useEffect(() => {
    const calculateScrollbarWidth = debounce(() => {
      setScrollbarWidth(getScrollbarWidth());
    }, 300);

    calculateScrollbarWidth();
    window.addEventListener("resize", calculateScrollbarWidth);

    return () => {
      window.removeEventListener("resize", calculateScrollbarWidth);
    };
  }, []);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const currentPadding = window.getComputedStyle(
        document.body
      ).paddingRight;

      if (currentPadding === `${scrollbarWidth}px`) {
        document.body.style.paddingRight = "0";
        console.debug("Padding was removed:", currentPadding);
      }
    });

    observer.observe(document.body, {
      attributes: true,
      attributeFilter: ["style"],
    });

    return () => {
      observer.disconnect();
    };
  }, [scrollbarWidth]);
};
