// LuckysheetDataContext.ts

import React, { useState, useEffect, ReactNode, useContext } from "react";
import type { LuckysheetOptions } from "@/shared/interfaces/luckysheet.interface";

interface LuckysheetDataContextProps {
  data: any;
  setData: (data: any) => void;
  options?: LuckysheetOptions;
  setOptions: React.Dispatch<LuckysheetOptions | undefined>;
}

export const LuckysheetDataContext =
  React.createContext<LuckysheetDataContextProps>({
    data: null,
    setData: () => {},
    setOptions: () => {},
    options: undefined,
  });

interface LuckysheetDataProviderProps {
  children: ReactNode;
}

const LuckysheetDataProvider = ({ children }: LuckysheetDataProviderProps) => {
  const [data, setData] = useState<any>(null);
  const [options, setOptions] = useState<LuckysheetOptions>();

  useEffect(() => {
    console.log("LuckysheetDataContext data updated:", data);
  }, [data]);

  return (
    <LuckysheetDataContext.Provider
      value={{ data, setData, options, setOptions }}
    >
      {children}
    </LuckysheetDataContext.Provider>
  );
};

const useLuckySheetContext = () => {
  const ctx = useContext(LuckysheetDataContext);

  if (!ctx) {
    console.error("You cant use LuckySheetContext without provider!");
  }

  return ctx;
};

export { useLuckySheetContext, LuckysheetDataProvider };
