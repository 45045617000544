import { api } from "@/shared/config/network";

import { SyncedDataDto } from "../model";

const fetchSyncedData = async (gridkey: string) => {
  const response = await api.get<SyncedDataDto[]>("/data-registry", {
    params: {
      gridkey: gridkey,
    },
  });
  return response.data;
};

export { fetchSyncedData };
