import { useMutation, useQueryClient } from "@tanstack/react-query";

import { restoreBackup } from "../api/restore-backup";
import { backupKeys } from "../const";

const useBackupRestore = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: restoreBackup,
    onSuccess: ({ financeModelId }) => {
      qc.invalidateQueries({
        queryKey: backupKeys.list(financeModelId).queryKey,
      });
    },
  });
};

export { useBackupRestore };
