import { api } from "@/shared/config/network";

import { FinanceModelSheetPurpose } from "@/entities/SyncedData";

interface CreateInputParams {
  financeModelId: number;
  data: Luckysheet.Data;
  tabName: string;
  purpose: FinanceModelSheetPurpose;
}

const createInputs = async ({ financeModelId, data, tabName, purpose }: CreateInputParams) => {
  const response = await api.post(
    `/data-registry/finance-models/${financeModelId}/inputs`,
    {
      tabName,
      purpose,
      inputs: data
    });

  return response.data;
};

export { createInputs };
