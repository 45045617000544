import React, { ReactNode } from "react";
import { Card, CardContent, CardMedia, Typography, Box } from "@mui/material";
import { FinanceModelDto, type FormulaEngine } from "@/entities/FinanceModel";
import defaultImg from "../assets/default.png";
import EyeIcon from "@/assets/icons/eye.svg?react";
import { formatDate } from "@/shared/config/dateFormat";
import { useTheme } from "@mui/material/styles";
import { SmallAvatar } from "@/shared/ui/SmallAvatar";
import { ModelBadge } from "@/features/ModelBadges";
import { useFinanceModelPermissions } from "../hooks/useFinanceModelPermissions";
import TableChartIcon from "@mui/icons-material/TableChart";
import CodeIcon from "@mui/icons-material/Code";

interface ModelCardProps {
  model: FinanceModelDto;
}

export const FinanceModelPreviewGrid: React.FC<ModelCardProps> = ({
  model,
}) => {
  const theme = useTheme();
  const { viewOnly } = useFinanceModelPermissions(model);

  const iconFormulaEngine: Record<FormulaEngine, string | ReactNode> = {
    python: <CodeIcon color="primary" />,
    R: <CodeIcon color="primary" />,
    hypersheet: <TableChartIcon color="primary" />,
  };

  return (
    <Card
      sx={{
        minWidth: 288,
        minHeight: 270,
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        boxShadow: "none",
        transition: "transform 0.3s, box-shadow 0.3s",
        "&:hover": {
          transform: "scale(1.01)",
          boxShadow: theme.palette.customShadows.lightBlue,
        },
        cursor: "pointer",
        position: "relative",
        gap: "0",
      }}
    >
      <Box sx={{ position: "relative" }}>
        {viewOnly && (
          <Box
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
              width: "40px",
              height: "40px",
              padding: "4px",
              backgroundColor: "white",
              borderRadius: "50%",
              boxShadow:
                "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
            }}
          >
            <EyeIcon />
          </Box>
        )}
        {
          <Box
            sx={{
              position: "absolute",
              top: "8px",
              left: "8px",
              width: "40px",
              height: "40px",
              padding: "4px",
              backgroundColor: "white",
              borderRadius: "50%",
              boxShadow:
                "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {model && iconFormulaEngine[model.formulaEngine]}
          </Box>
        }

        <CardMedia
          component="img"
          height="128"
          image={model?.screenshotImage ? model.screenshotImage : defaultImg}
          alt={model.name}
          sx={{
            marginBottom: "16px",
            border: "1px solid #cbdeef",
            borderRadius: "8px",
            maxWidth: "100%",
            maxHeight: "unset",
            minWidth: "unset",
          }}
        />

        <ModelBadge model={model} />
      </Box>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          padding: 0,
          paddingBottom: "0 !important",
          justifyContent: "unset",
          alignItems: "unset",
        }}
      >
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "150%",
            color: "#000",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
            width: "auto",
            marginRight: "unset",
          }}
        >
          {model.name}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "150%",
            color: "#000",
            mb: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
          }}
        >
          {model.description}
        </Typography>
        {model.ownerId && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "auto",
              }}
            >
              <SmallAvatar
                avatarSrc={model.User.avatar}
                fullname={model.User.fullname}
              />
            </Box>
            <Box>
              <Typography
                sx={{
                  ml: 1,
                  fontWeight: 400,
                  fontSize: "14px",
                  lineHeight: "171%",
                  color: "#000",
                  width: "auto",
                }}
              >
                {formatDate(model.updatedAt)}
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
