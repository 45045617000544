import { useMutation } from "@tanstack/react-query";

import { publishFinanceModel } from "../api/publish";

const useFinanceModelPublish = () => {
  return useMutation({
    mutationFn: publishFinanceModel,
  });
};

export { useFinanceModelPublish };
