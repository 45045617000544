import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "@/shared/config/network";
import { userKeys } from "@/entities/User/const";

interface LoginData {
  username: string;
  password: string;
}

interface LoginResponse {
  token: string;
}

export const useLoginMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (loginData: LoginData): Promise<LoginResponse> => {
      const response = await api.post<LoginResponse>("login", loginData);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [userKeys.user.queryKey, userKeys.token.queryKey] });
    },
  });
};
