import { api } from "@/shared/config/network";

import type { BackupDto } from "../model";

interface FetchBackupParams {
  financeModelId: number;
}

const fetchBackups = async ({ financeModelId }: FetchBackupParams) => {
  const response = await api.get<BackupDto[]>(
    `/backups/list/${financeModelId}`
  );
  return response.data;
};

export { fetchBackups };
