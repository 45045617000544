import { useState } from "react";

interface ToggleSpoilerOptions {
  initialOpen?: boolean;
}

const useToggleSpoiler = ({
  initialOpen = false,
}: ToggleSpoilerOptions = {}) => {
  const [isOpen, setIsOpen] = useState(initialOpen || false);

  const toggleSpoiler = () => {
    setIsOpen((prevState) => !prevState);
  };

  return { isOpen, toggleSpoiler };
};

export { useToggleSpoiler };
