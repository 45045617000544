import { Box, Typography, useTheme } from "@mui/material";
import { FC } from "react";

interface TemplateCardProps {
  title: string;
  description: string;
  onClick: () => void;
}

const TemplateCard: FC<TemplateCardProps> = ({
  title,
  onClick,
  description,
}) => {
  const theme = useTheme();
  return (
    <Box
      onClick={onClick}
      sx={{
        border: `1px solid ${theme.palette.customColors.colorLightBlue200}`,
        borderRadius: "8px",
        padding: "16px",
        cursor: "pointer",
        width: "294px",
        aspectRatio: "2.29 / 1",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
      <Typography
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 3,
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontWeight: 400,
          fontSize: "16px",
          lineHeight: "150%",
          color: "#000",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export { TemplateCard };
