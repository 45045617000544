import { useMutation, useQueryClient } from "@tanstack/react-query";

import { dashboardKeys } from "../const";
import { deleteDashboard } from "../api";

const useDashboardDelete = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: deleteDashboard,
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: dashboardKeys.list._def });
    },
  });
};

export { useDashboardDelete };
