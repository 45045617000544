import { api } from "@/shared/config/network";
import { ApproveVersionByIdResponse } from "../model";

export const approveAnnotation = async ({
  versionId,
}: {
  versionId: number;
}) => {
  const response = await api.post<ApproveVersionByIdResponse>(
    `/versions/${versionId}/approve`
  );

  return response.data;
};
