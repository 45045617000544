import { RefObject, useCallback, useMemo, useState } from "react";
import { CellChange, ChangeSource } from "node_modules/handsontable/common";
import { HotTableClass } from "@handsontable/react";
import Handsontable from "handsontable";
import { maxBy } from "lodash";
import { UpdatedCells } from "@/shared/types/dashboard.type";
import {
  FinanceModelInputDto,
  FinanceModelInputsAllDto,
} from "@/entities/FinanceModelInput";
import { UseQueryResult } from "@tanstack/react-query";

function adjustTableWidth(hotInstance: HotTableClass["hotInstance"]) {
  if (hotInstance) {
    const columnWidths = hotInstance.getColHeader().map((...[, i]) => {
      return hotInstance.getColWidth(i);
    });

    const totalInnerWidth = columnWidths.reduce(
      (total, width) => total + width,
      50 // Width of column with indexes
    );

    const container = hotInstance.rootElement;
    container.style.width = `${totalInnerWidth}px`;
  }
}

interface CellSettings extends Handsontable.CellMeta {
  row: number;
  col: number;
}

type UseHotTableArgs = {
  hotRef: RefObject<HotTableClass>;
  inputsQuery: UseQueryResult<FinanceModelInputsAllDto[]>;
};

const getValue = (input: FinanceModelInputDto): string => {
  return input.value ?? "";
};

const getCellKey = (row: unknown, column: unknown): string =>
  `${row}-${column}`;
const parseCellKey = (key: string): { row: number; column: number } => {
  const [row, column] = key.split("-");

  return { row: +row, column: +column };
};

export const useHotTable = ({ hotRef, inputsQuery }: UseHotTableArgs) => {
  const [updatedCells, setUpdatedCells] = useState<
    Record<string, UpdatedCells>
  >({});
  const [selectedSheet, setSelectedSheet] = useState<string>();
  const [cellSettings, setCellSettings] = useState<CellSettings[] | null>(null);

  const { data: tableInputs = [], isLoading: isInputsLoading } = inputsQuery;

  const selectedSheetData = useMemo(() => {
    if (!selectedSheet) return undefined;
    console.debug("Попытка найти в tableInputs", tableInputs);
    return tableInputs.find((v) => v.tabName === selectedSheet)?.data;
  }, [tableInputs, selectedSheet]);

  const selectedType = useMemo(() => {
    if (!selectedSheet) return undefined;
    return tableInputs.find((v) => v.tabName === selectedSheet)?.type;
  }, [tableInputs, selectedSheet]);

  const mapInputData = useCallback(
    (
      inputData: FinanceModelInputDto[],
      updatedCells: UpdatedCells | undefined
    ) => {
      const columnLength = (maxBy(inputData, "column")?.column ?? 0) + 1;
      const rowLength = (maxBy(inputData, "row")?.row ?? 0) + 1;
      const matrix = [...new Array(rowLength)].map(() => [
        ...new Array(columnLength).map(() => ""),
      ]);

      inputData.forEach((v) => {
        const actualValue =
          updatedCells?.[getCellKey(v.row, v.column)]?.newValue;

        return (matrix[v.row][v.column] = actualValue ?? getValue(v));
      });

      return matrix;
    },
    []
  );

  const values = useMemo(() => {
    if (selectedSheetData) {
      return mapInputData(
        selectedSheetData,
        selectedSheet ? updatedCells[selectedSheet] : undefined
      );
    } else {
      return [[]];
    }
  }, [selectedSheetData, mapInputData, selectedSheet, updatedCells]);

  const handleAfterChange: (
    changes: CellChange[] | null,
    source: ChangeSource
  ) => void = (v) => {
    if (v) {
      const [row, column, oldValue, newValue] = v[0];

      const input = selectedSheetData?.find(
        (v) => v.row === row && v.column === +column
      );

      if (selectedSheet === undefined) {
        return;
      }

      if (input && oldValue !== newValue) {
        setUpdatedCells((prev) => {
          return {
            ...prev,
            [selectedSheet]: {
              ...prev[selectedSheet],
              [getCellKey(row, column)]: {
                id: input.id,
                newValue,
                oldValue,
              },
            },
          };
        });
      }
    }
  };

  const handleAfterRender = () => {
    if (hotRef.current?.hotInstance) {
      adjustTableWidth(hotRef.current?.hotInstance);
    }
  };

  const isCellReadonly = useCallback(
    (row: number, column: number, labelsOnly?: boolean) => {
      if (!labelsOnly) {
        const input = selectedSheetData?.find(
          (v) => v.row === row && v.column === column
        );

        return input?.type === "label";
      }

      return true;
    },
    [selectedSheetData]
  );

  const updateCellSettings = useCallback(
    (
      currentSheet: string | undefined,
      currentSheetData: string[][] | undefined,
      updatedCells: Record<string, UpdatedCells>,
      labelsOnly?: boolean
    ) => {
      const hot = hotRef.current?.hotInstance;

      currentSheetData?.forEach((rowValues, row) => {
        rowValues.forEach((_columnValue, column) => {
          hot?.removeCellMeta(row, column, "className");

          hot?.setCellMeta(
            row,
            column,
            "readOnly",
            isCellReadonly(row, column, labelsOnly)
          );
        });
      });

      if (!currentSheet) {
        return;
      }

      const updatedCellsOfCurrentSheet = updatedCells?.[currentSheet];

      if (updatedCellsOfCurrentSheet) {
        Object.keys(updatedCellsOfCurrentSheet).forEach((key) => {
          const { row, column } = parseCellKey(key);

          hot?.setCellMeta(row, column, "className", "changed");
        });
      }

      hot?.render();
    },
    [hotRef, isCellReadonly]
  );

  const changeSelectedSheet = (sheetName: string) => {
    setSelectedSheet(sheetName);
    setCellSettings(null);
  };

  return {
    tableInputs,
    isInputsLoading,
    mapInputData,

    updateSettings: updateCellSettings,
    handleAfterChange,
    handleAfterRender,

    updatedCells,
    selectedSheet,
    selectedSheetData,
    selectedType,
    setSelectedSheet: changeSelectedSheet,
    values,
    cellSettings,
  };
};
