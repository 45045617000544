import { useQuery } from "@tanstack/react-query";
import { fetchTemplates } from "../api";
import { templateKeys } from "../const";
import type { TemplateDto } from "../model";

const useTemplates = <T = TemplateDto[]>(
  select?: (data: TemplateDto[]) => T
) => {
  return useQuery({
    queryKey: templateKeys._def,
    queryFn: fetchTemplates,
    select: select || ((data) => data as T),
  });
};

export { useTemplates };
