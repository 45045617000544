import merge from "lodash/merge";

import { api } from "@/shared/config/network";

import type { DashboardCreateDto, DashboardDto } from "../model";

const CREATE_DTO_DEFAULT_VALUES: Partial<DashboardCreateDto> = {
  embedCode: "",
  isActive: true,
  status: "",
};

const createDashboard = async (dto: DashboardCreateDto) => {
  const response = await api.post<DashboardDto>("/dashboards", {
    ...merge(CREATE_DTO_DEFAULT_VALUES, dto),
    uuid: ((arr) => arr[arr.length - 1])(dto.publicUrl.split("/")),
  });
  return response.data;
};

export { createDashboard };
