import { FC, useEffect, useLayoutEffect } from "react";
import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "@/shared/ui/Input/Input";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import {
  type FinanceModelCreateDto,
  FinanceModelDto,
  FormulaEngine,
} from "@/entities/FinanceModel";
import { TemplateUseDto } from "@/entities/Templates";
import { useFinanceModelCreateModal } from "../../hooks/useFinanceModelCreateModal";
import { CustomSelect } from "@/shared/ui/CustomSelect";
import { OPTIONS } from "../config/options";

interface FinanceModelCreateProps {
  open: boolean;
  handleClose: VoidFunction;

  onSuccess?(model: FinanceModelDto | TemplateUseDto): void;

  onError?(error: unknown): void;

  initialValues?: FinanceModelCreateDto;
  defaultFormulaEngine?: FormulaEngine;
}

const FinanceModelCreateFromImport: FC<FinanceModelCreateProps> = ({
  open,
  handleClose,
  onSuccess,
  onError,
  initialValues,
  defaultFormulaEngine,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    reset,
    setValue,
  } = useFinanceModelCreateModal(onSuccess, onError);

  const handleModalClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  useLayoutEffect(() => {
    if (defaultFormulaEngine) {
      setValue("formulaEngine", defaultFormulaEngine);
    }
  }, [defaultFormulaEngine, setValue]);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={"20px"}
        >
          <Typography variant="h6" component="h2">
            Создание модели
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form
          onSubmit={handleSubmit}
          noValidate
          style={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Input
            placeholder="Имя модели"
            fullWidth
            {...register("name", { required: "Имя обязательно" })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
          />
          <Input
            placeholder="Описание"
            rows={4}
            multiline
            fullWidth
            {...register("description", { required: "Описание обязательно" })}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
          />
          <Typography>Библиотека для расчёта</Typography>
          <Box marginBottom={"24px"}>
            <CustomSelect
              options={OPTIONS}
              name="formulaEngine"
              register={register}
              setValue={setValue}
              defaultValue={defaultFormulaEngine}
            />
          </Box>
          <Box mt={2}>
            <Button
              variant="lightBlueV100"
              color="primary"
              type="submit"
              sx={{ mt: 2, width: "100%" }}
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? <Spinner withBg={true} /> : "Создать"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export { FinanceModelCreateFromImport };
