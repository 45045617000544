import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNotification } from "@/shared/hooks/useNotification";
import { versionsKeys } from "@/entities/Version/const";
import { updateAnnotation } from "../api";

const useUpdateAnnotation = () => {
  const { notifySuccess, notifyError } = useNotification();

  const qc = useQueryClient();

  return useMutation({
    mutationFn: updateAnnotation,
    onSuccess: (...[, { versionId }]) => {
      notifySuccess("Аннотация успешно обновлена");

      qc.invalidateQueries({ queryKey: versionsKeys.byVersionId(versionId).queryKey });
    },
    onError: () => notifyError("Не удалось обновить аннотацию")
  });
};

export { useUpdateAnnotation };
