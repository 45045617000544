import type { TemplateDto } from "@/entities/Templates";

const formatTemplates = (data: TemplateDto[]) => {
  return data.map((template) => ({
    value: template.id.toString(),
    label: template.name,
  }));
};

export { formatTemplates };
