import React, { MouseEvent } from "react";
import { Box } from "@mui/material";
import { UniversalMenu } from "@/shared/ui/UniversalMenu";
import ListIcon from "@/assets/icons/lines.svg?react";
import type { ModelMenuProps, MenuItemProps } from "./ModelMenu.types";
import { SquareButtonWithIcon } from "@/shared/ui/SquareBtnWithIcon/ui/SquareButtonWithIcon";

export const ModelMenu: React.FC<ModelMenuProps> = ({
  menuGroups,
  alignment = "left",
  MenuIcon = ListIcon,
  extraAttrs = {},
  widthIcon = 15,
  heightIcon = 10,
  triggerSlot,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (
    event: MouseEvent<HTMLLIElement>,
    item: MenuItemProps
  ) => {
    handleClose(event);
    item.onClick?.(event, item);
  };

  const updatedMenuGroups = menuGroups.map((group) => ({
    ...group,
    items: group.items.map((item) => ({
      ...item,
      onClick: (event: MouseEvent<HTMLLIElement>) =>
        handleMenuItemClick(event, item),
    })),
  }));

  return (
    <Box sx={{ display: "flex", alignItems: "center" }} {...extraAttrs}>
      {triggerSlot ? (
        <Box onClick={handleClick}>{triggerSlot}</Box>
      ) : (
        <Box sx={{ marginLeft: "8px" }}>
          <SquareButtonWithIcon
            onClick={handleClick}
            endIcon={<MenuIcon width={widthIcon} height={heightIcon} />}
          />
        </Box>
      )}
      <UniversalMenu
        triggerElement={<div />}
        groups={updatedMenuGroups}
        alignment={alignment}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      />
    </Box>
  );
};
