import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, Dialog, Stack } from "@mui/material";
import { ModelEditingStatus } from "@/widgets/ModelEditingStatus";
import { BackupRestoreCurrentDialogContent } from "@/widgets/Backups/components/BackupRestoreCurrentDialogContent";
import { useLuckySheetContext } from "@/contexts/LuckysheetDataContext";
import ShareIcon from "@/assets/icons/share.svg?react";
import { SquareButtonWithIcon } from "@/shared/ui/SquareBtnWithIcon/ui/SquareButtonWithIcon";
import { ViewModeBadge } from "@/features/ModelBadges";
import { BackupDto, useBackupRestore } from "@/entities/Backup";
import { useNotification } from "@/shared/hooks/useNotification";
import { routePaths } from "@/shared/config/routePaths";
import { FinanceModelCreateFromBackup } from "@/features/FinanceModel/FinanceModelCreate/ui/FinanceModelCreateFromBackup";
import { RestoreButtonMenu } from "./RestoreButtonMenu";
import { usePermissions } from "@/entities/User";

interface ModelActionsProps {
  setIsOpenSyncDialog: React.Dispatch<boolean>;
  setIsOpenShareModelModal: React.Dispatch<boolean>;
  openedBackup: BackupDto | null;
  onGoBackToModel: VoidFunction;
}

export const ModelActions: React.FC<ModelActionsProps> = ({
  setIsOpenSyncDialog,
  setIsOpenShareModelModal,
  onGoBackToModel,
  openedBackup,
}) => {
  const { options, setData, setOptions } = useLuckySheetContext();
  const isViewOnlyMode = options?.allowEdit === false;

  const { notifySuccess, notifyError } = useNotification();
  const { push } = useHistory();

  const [
    isRestoreToCurrentVersionModalOpen,
    setIsRestoreToCurrentVersionModalOpen,
  ] = useState(false);
  const [isRestoreToNewVersionModalOpen, setIsRestoreToNewVersionModalOpen] =
    useState(false);

  const { mutate: restoreBackup } = useBackupRestore();

  const iCan = usePermissions();

  const onRestoreToCurrentVersion = () => {
    if (!openedBackup) {
      notifyError("Не выбрана резервная копия для восстановления");
      return;
    }

    const backupId = openedBackup.id;

    restoreBackup(
      { versionId: backupId.toString() },
      {
        onSuccess: () => {
          window.location.reload();
        },
      }
    );
  };

  const onRestoreToNewSuccess = (gridKey: string) => {
    setData(null);
    setOptions({ allowEdit: true, showinfobar: false, showtoolbar: true });

    push(`${routePaths.grid}/${gridKey}`);

    onGoBackToModel();
    setIsRestoreToNewVersionModalOpen(false);

    notifySuccess("Данные восстановлены в новую модель");
  };

  const restoreButton = (
    <>
      {!!openedBackup && (
        <RestoreButtonMenu
          onRestoreToCurrentVersion={() =>
            setIsRestoreToCurrentVersionModalOpen(true)
          }
          onRestoreToNewVersion={() => setIsRestoreToNewVersionModalOpen(true)}
        />
      )}

      <Dialog
        open={isRestoreToCurrentVersionModalOpen}
        onClose={() => setIsRestoreToCurrentVersionModalOpen(false)}
      >
        {isRestoreToCurrentVersionModalOpen && (
          <BackupRestoreCurrentDialogContent
            handleBackupDialogClose={() =>
              setIsRestoreToCurrentVersionModalOpen(false)
            }
            handleRestoreBackup={onRestoreToCurrentVersion}
          />
        )}
      </Dialog>

      <FinanceModelCreateFromBackup
        backupId={openedBackup?.id}
        open={isRestoreToNewVersionModalOpen}
        onClose={() => setIsRestoreToNewVersionModalOpen(false)}
        onSuccess={onRestoreToNewSuccess}
      />
    </>
  );

  if (isViewOnlyMode) {
    const handleGoBackToModel = () => {
      setData(undefined);
      setOptions(undefined);
      onGoBackToModel();
    };

    return (
      <Stack direction="row" spacing={2}>
        {restoreButton}

        <ViewModeBadge onBadgeClick={handleGoBackToModel} />
      </Stack>
    );
  }

  return (
    <Box sx={{ gap: "8px", display: "flex" }}>
      <Box
        sx={{
          marginRight: "32px",
          display: "flex",
        }}
      >
        <ModelEditingStatus />
      </Box>

      {restoreButton}

      {iCan.publishMasterModel && (
        <Button
          variant="lightBlueV300"
          onClick={() => setIsOpenSyncDialog(true)}
        >
          Создать мастер модель
        </Button>
      )}

      {iCan.shareModel && (
        <SquareButtonWithIcon
          onClick={() => {
            setIsOpenShareModelModal(true);
          }}
          endIcon={<ShareIcon width={17} height={18} />}
        />
      )}
    </Box>
  );
};
