import { FC, useMemo } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel, Typography } from "@mui/material";
import {
  SyncResultDto,
  useCreateSyncedData
} from "@/entities/SyncedData";
import { Modal } from "@/shared/ui/Modal";
import type {
  CheckedSheets,
  SyncFormData
} from "./interfaces";
import { SheetViewList } from "./SheetViewList";
import { getSheetDefinitionTitle, groupDoubleOutputs } from "./utils";

interface Props {
  open: boolean;
  onClose: VoidFunction;

  onNextClick(syncResults: SyncResultDto[]): void;

  onPreviousClick: VoidFunction;
  financeModelId: number;
  checkedSheets: CheckedSheets | null;
  isLoading?: boolean;
}

const SyncModal: FC<Props> = (
  {
    open,
    onClose,
    onPreviousClick,
    onNextClick,
    financeModelId,
    checkedSheets,
    isLoading
  }
) => {
  const { formInputs, formOutputs, biInputs, biOutputs } = useMemo(() => ({
    formInputs: checkedSheets?.formInputs ?? [],
    biInputs: checkedSheets?.biInputs ?? [],
    formOutputs: checkedSheets?.formOutputs ?? [],
    biOutputs: checkedSheets?.biOutputs ?? []
  }), [checkedSheets]);

  const { mutateAsync: onCreateSyncedData, isPending: isSyncing } = useCreateSyncedData({
    ...checkedSheets,
    financeModelId
  });

  const { handleSubmit, control } = useForm<SyncFormData>({
    defaultValues: {
      skipEmpty: true
    }
  });

  const onSubmit: SubmitHandler<SyncFormData> = async () => {
    const groupedOutputs = groupDoubleOutputs(formOutputs, biOutputs);

    const promises = await onCreateSyncedData({
      formInputs,
      biInputs,
      formOutputs: groupedOutputs.formOutputs,
      biOutputs: groupedOutputs.biOutputs,
      biAndFormOutputs: groupedOutputs.biAndFormOutputs
    });

    const syncResults = promises.reduce<SyncResultDto[]>((results, result) => {
      return result.status === "fulfilled" ? [...results, result.value] : results;
    }, []);

    onNextClick(syncResults);
  };

  const hasData = formInputs.length > 0 || formOutputs.length > 0 || biInputs.length > 0 || biOutputs.length > 0;

  return (
    <Modal
      open={open}
      onClose={onClose}
      width={800}
      loading={isLoading}
      title={<Typography variant="h6">Данные для синхронизации</Typography>}
      footer={
        <>
          <Button onClick={onPreviousClick}>Назад</Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={isSyncing}
            startIcon={isSyncing ? <CircularProgress size={14} /> : undefined}
          >
            Далее
          </Button>
        </>
      }
    >
      {formInputs.length > 0 && (
        <SheetViewList
          sheets={formInputs}
          title={
            <Typography variant="subtitle1">
              {getSheetDefinitionTitle({ sheetType: "input", sheetPurpose: "forms" })}
            </Typography>
          }
        />
      )}
      {formOutputs.length > 0 && (
        <SheetViewList
          sheets={formOutputs}
          title={
            <Typography variant="subtitle1">
              {getSheetDefinitionTitle({ sheetType: "output", sheetPurpose: "forms" })}
            </Typography>
          }
        />
      )}
      {biInputs.length > 0 && (
        <SheetViewList
          sheets={biInputs}
          title={
            <Typography variant="subtitle1">
              {getSheetDefinitionTitle({ sheetType: "input", sheetPurpose: "bi" })}
            </Typography>
          }
        />
      )}
      {biOutputs.length > 0 && (
        <SheetViewList
          sheets={biOutputs}
          title={
            <Typography variant="subtitle1">
              {getSheetDefinitionTitle({ sheetType: "output", sheetPurpose: "bi" })}
            </Typography>
          }
        />
      )}

      {!hasData && (
        <Box sx={{ margin: "16px auto" }}>
          <Typography>Нет листов для синхронизации</Typography>
        </Box>
      )}

      <Divider sx={{ mt: 1, mb: 2 }} />

      {hasData && <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="skipEmpty"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox {...field} defaultChecked={field.value} />}
              label="Пропускать пустые ячейки"
            />
          )}
        />
      </form>}
    </Modal>
  );
};

export { SyncModal };
