import { api } from "@/shared/config/network";

interface DeleteBackupParams {
  id: number;
}

const deleteBackup = async ({ id }: DeleteBackupParams) => {
  const response = await api.delete(`/backups/${id}/delete`);
  return response.data;
};

export { deleteBackup };
