import { api } from "@/shared/config/network";

import type { BackupPreviewDto } from "../model";

interface FetchBackupPreviewParams {
  id: number | null; 
}

const fetchBackupPreviewById = async ({ id }: FetchBackupPreviewParams) => {
  if (!id) return null;

  const response = await api.get<BackupPreviewDto>(`/backups/preview/${id}`);
  return response.data;
};

export { fetchBackupPreviewById };
