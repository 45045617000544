import { FC, PropsWithChildren, ReactNode } from "react";
import { Backdrop, Box, CircularProgress, IconButton, Modal as MuiModal, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps, Theme } from "@mui/material/styles";

interface ModalProps extends PropsWithChildren {
  open: boolean;
  onClose?: VoidFunction;
  title?: ReactNode;
  footer?: ReactNode;
  width?: number;
  sx?: Record<"modal", SxProps<Theme>>;
  loading?: boolean;
}

const sx: Record<string, SxProps<Theme>> = {
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
    maxHeight: "calc(100vh - 2 * 40px)",
    display: "flex",
    flexFlow: "column"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    pt: 4,
    pl: 4,
    pr: 4
  },
  body: {
    position: "relative",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "auto",
    width: "100%",
    height: "100%",
    maxHeight: "100%",
    minHeight: "50%",
    display: "flex"
  },
  bodyInner: {
    pt: 4,
    pl: 4,
    pr: 4,
    width: "100%",
    height: "100%"
  },
  footer: {
    p: 4,
    justifyContent: "flex-end"
  }
};

const Modal: FC<ModalProps> = (
  {
    title,
    open,
    onClose,
    children,
    footer,
    width,
    sx: outerSx,
    loading
  }
) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      sx={outerSx?.modal}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: () => null
        }
      }}
    >
      <Box sx={{ ...sx.container, ...(width !== undefined ? { width: `${width}px` } : {}) }}>
        <Box sx={sx.header}>
          {title}

          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box sx={sx.body}>
          {
            loading
              ? (
                <Box>
                  <CircularProgress size={24} />
                </Box>
              )
              : (
                <Box sx={sx.bodyInner}>
                  {children}
                </Box>
              )
          }
        </Box>

        {!!footer && (
          <Stack direction="row" spacing={1} sx={sx.footer}>
            {footer}
          </Stack>
        )}
      </Box>
    </MuiModal>
  );
};

export { Modal, type ModalProps };
