import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import HotTable, { HotTableClass } from "@handsontable/react";
import { format } from "date-fns";
import { useHotTable } from "@/shared/hooks/useHotTable";
import Header from "@/widgets/Header";
import { useRecalculateModel } from "@/entities/FinanceModelInput/hooks/useRecalculateModel";
import { useFinanceModelByGridkey } from "@/entities/FinanceModel/queries/useFinanceModelByGridkey";
import { Box, Button, CssBaseline, Tab, Tabs, Typography } from "@mui/material";
import { BreadcrumbsNav } from "@/widgets/BreadcrumbsNav";
import { RightSidebar } from "@/shared/ui/RightSidebar";
import { routePaths } from "@/shared/config/routePaths";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";
import ClockIcon from "@/assets/icons/clock.svg?react";
import RefreshIcon from "@/assets/icons/refresh.svg?react";
import DeleteIcon from "@/assets/icons/delete.svg?react";
import TemplateIcon from "@/assets/icons/template.svg?react";
import VersionIcon from "@/assets/icons/version.svg?react";
import { VersionsLabelsModal, VersionsList } from "@/widgets/Versions";
import { useVersionById } from "@/entities/Version/queries";
import { useNotification } from "@/shared/hooks/useNotification";
import { ViewModeBadge } from "@/features/ModelBadges";
import { useUserMenuGroups } from "@/features/User";
import { useFinanceModelInputsAll } from "@/entities/FinanceModelInput";
import { VersionAnnotationModal } from "@/widgets/VersionAnnotationModal";
import styles from "./GridVersionPage.module.css";
import { useRestoreVersionToCurrent } from "@/entities/Version";
import { SquareButtonWithIcon } from "@/shared/ui/SquareBtnWithIcon";
import NotificationIcon from "@/assets/icons/notification.svg?react";

interface RouteParams {
  gridkey: string;
  version: string;
}

/**
 * FIXME: Calculation of versions uses the same method
 * as in dashboard to edit input fields, which should not be the case
 * as the inputs in versions must be immutable
 */
const GridVersionPage: React.FC = () => {
  const { notifyInfo } = useNotification();
  const { push } = useHistory();
  const { gridkey: gridkeyEncoded, version: versionFromParams } =
    useParams<RouteParams>();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromPage = searchParams.get("fromPage")?.replace("/", "");

  const [version, setVersion] = useState<number | undefined>(
    versionFromParams ? Number(versionFromParams) : undefined
  );

  const { data: user } = useUserProfile();
  const hotRef = useRef<HotTableClass>(null);
  const { data: currentModel, isLoading: isFinanceModelLoading } =
    useFinanceModelByGridkey(gridkeyEncoded);
  const [openedSidebar, setOpenedSidebar] = useState<"versions" | null>(null);
  const [openedModal, setOpenedModal] = useState<
    "labels" | "annotationEditor" | null
  >(null);
  const financeModelId = currentModel?.id;
  const modelName = currentModel?.name ?? "";

  const financeModelInputsAllQuery = useFinanceModelInputsAll({
    financeModelId,
    enabled: Boolean(financeModelId),
  });

  const {
    tableInputs,
    isInputsLoading,
    mapInputData,

    handleAfterChange,
    handleAfterRender,
    updateSettings,
    updatedCells,

    setSelectedSheet,
    selectedSheet,
    selectedSheetData,
    values,
  } = useHotTable({ hotRef, inputsQuery: financeModelInputsAllQuery });

  const { data: versionData, isLoading: isVersionDataLoading } = useVersionById(
    +version!
  );
  const isReadonlyData = Boolean(version);

  const checkIfIsOutputTab = (name: string) => {
    return (
      tableInputs.find(({ tabName }) => tabName === name)?.type === "output"
    );
  };
  const handleGoToVersionsEditor = () =>
    push(`${routePaths.grid}/${gridkeyEncoded}${routePaths.versions}`);
  const handleCloseModal = () => {
    setOpenedModal(null);
  };

  const {
    handleRecalculateModel,
    isRecalculateModelPending,
    calculatedOutputData,
    mapOutputData,
  } = useRecalculateModel({
    financeModelId,
    updatedCells,
    selectedSheet,
  });

  const userMenuGroups = useUserMenuGroups();
  const modelMenuGroups = useMemo(() => {
    return [
      {
        items: [
          {
            label: "Показать мастер-модель",
            icon: <ClockIcon />,
            onClick: () =>
              notifyInfo("В будущем можно будет добавлять аннотации"),
          },
        ],
        divider: true,
      },
      {
        items: [
          {
            label: "Добавить аннотацию",
            icon: <TemplateIcon />,
            onClick: () => setOpenedModal("annotationEditor"),
          },
          ...(version
            ? [
                {
                  label: "Добавить метку",
                  icon: <TemplateIcon />,
                  onClick: () => setOpenedModal("labels"),
                },
              ]
            : []),
          {
            label: "Комментарии",
            icon: <VersionIcon />,
            onClick: () =>
              notifyInfo("В будущем можно будет добавлять комментарии"),
          },
        ],
        divider: true,
      },
      {
        items: [
          {
            label: "Показать формы данных",
            icon: <ClockIcon />,
            onClick: () => setOpenedSidebar("versions"),
          },
          {
            label: "Сохранить форму данных",
            icon: <RefreshIcon />,
            onClick: () => console.log("version saved"),
          },
          ...(version
            ? [
                {
                  label: "Редактор форм",
                  icon: <VersionIcon />,
                  onClick: handleGoToVersionsEditor,
                },
              ]
            : []),
        ],
        divider: true,
      },
      {
        items: [
          {
            label: "Удалить форму",
            extraClasses: styles.trash,
            icon: <DeleteIcon />,
            onClick: () => notifyInfo("В будущем можно будет удалять формы"),
          },
        ],
      },
    ];
  }, [version]);

  const allSheets = useMemo(() => {
    return tableInputs.filter((v) => v.type === "input" || v.type === "output");
  }, [tableInputs]);

  const isOutputTabSelected: boolean = selectedSheet
    ? checkIfIsOutputTab(selectedSheet)
    : false;

  useEffect(() => {
    const v = versionFromParams ? Number(versionFromParams) : undefined;
    setVersion(v);
  }, [versionFromParams]);

  // Select first sheet
  useEffect(() => {
    if (!selectedSheet && allSheets.length > 0) {
      setSelectedSheet(allSheets[0].tabName);
    }
  }, [allSheets, selectedSheet, setSelectedSheet]);

  const handleChangeSheet = (
    _event: React.SyntheticEvent,
    sheetName: string
  ) => {
    setSelectedSheet(sheetName);
  };

  const versionsSidebarHeader = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "16px",
        flexGrow: 1,
      }}
    >
      <Typography variant="h6">Изменения данных</Typography>
    </Box>
  );

  const {
    mutate: restoreVersionToCurrent,
    isPending: isRestoringVersionToCurrent,
  } = useRestoreVersionToCurrent(financeModelId);

  const versionsSidebarContent = currentModel && (
    <VersionsList
      gridKey={gridkeyEncoded}
      financeModelId={currentModel.id}
      onRestoreToCurrentVersionClick={restoreVersionToCurrent}
    />
  );

  // FIXME: Separate VersionEditor and VersionById pages
  // with corresponding data
  const tableData = useMemo(() => {
    if (version) {
      if (isOutputTabSelected) {
        return mapOutputData(
          Object.values(versionData?.outputData ?? {}).find((output) => {
            return output.name.startsWith(selectedSheet ?? "-");
          })?.data ?? []
        );
      } else {
        const inputTableData = mapInputData(
          versionData?.inputData.filter(
            (input) => input.tabName === selectedSheet
          ) ?? [],
          selectedSheet ? updatedCells[selectedSheet] : undefined
        );

        return inputTableData;
      }
    }

    return isOutputTabSelected ? calculatedOutputData : values;
  }, [
    version,
    selectedSheet,
    isOutputTabSelected,
    calculatedOutputData,
    values,
    mapOutputData,
    versionData,
    mapInputData,
    updatedCells,
  ]);

  useEffect(() => {
    updateSettings(selectedSheet, tableData, updatedCells, isOutputTabSelected);
  }, [
    selectedSheet,
    updateSettings,
    updatedCells,
    isOutputTabSelected,
    tableData,
  ]);

  const onRecalculateButtonClick = () => {
    handleRecalculateModel(["forms", "biandforms"]);
  };

  const handleAfterInit = () => {
    updateSettings(selectedSheet, tableData, updatedCells, isOutputTabSelected);
  };

  return (
    <Box
      sx={{
        display: "grid",
        height: "100vh",
        width: "100%",
        marginTop: "88px",
        columnGap: 0,
        rowGap: 0,
      }}
    >
      <CssBaseline />
      <Header
        leftSlot={
          <Box sx={{ marginLeft: "16px" }}>
            <BreadcrumbsNav
              backLink={`/grid/${gridkeyEncoded}`}
              breadcrumbs={[
                {
                  label: currentModel
                    ? String(currentModel?.id)
                    : "Загрузка...",
                },
                { label: modelName, path: `/grid/${gridkeyEncoded}` },
                ...(fromPage
                  ? [
                      {
                        label: "Информация",
                        path: `/grid/${gridkeyEncoded}/${fromPage}`,
                      },
                    ]
                  : []),
              ]}
              currentPage={{
                label:
                  version && versionData
                    ? `Форма ${version} от ${format(
                        versionData.createdAt,
                        "dd.MM.yyyy HH:mm:ss"
                      )}`
                    : "Редактор форм",
              }}
            />
          </Box>
        }
        UserMenuGroups={{
          menuGroups: userMenuGroups,
          alignment: "right",
          profileImage: user?.avatar,
        }}
        modelMenuProps={{
          menuGroups: modelMenuGroups,
          alignment: "right",
        }}
        rightSlot={
          <>
            {version ? (
              <ViewModeBadge onBadgeClick={handleGoToVersionsEditor} />
            ) : (
              <Button
                sx={{ marginLeft: "16px" }}
                variant="lightBlueV300"
                onClick={onRecalculateButtonClick}
              >
                {isRecalculateModelPending ? "Считаем..." : "Сделать расчет"}
              </Button>
            )}
            <SquareButtonWithIcon
              onClick={() => {}}
              endIcon={<NotificationIcon width={17} height={18} />}
            />
          </>
        }
      />

      {isFinanceModelLoading ||
      isVersionDataLoading ||
      isInputsLoading ||
      isRestoringVersionToCurrent ? (
        <div className={styles.loader}>Загрузка...</div>
      ) : (
        <div>
          {selectedSheet && (
            <div className={styles.tableContainer}>
              <Box
                className={styles.table}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Tabs value={selectedSheet} onChange={handleChangeSheet}>
                  {allSheets.map((sheet) => {
                    return (
                      <Tab
                        key={sheet.tabName}
                        label={sheet.tabName}
                        value={sheet.tabName}
                        sx={
                          checkIfIsOutputTab(sheet.tabName)
                            ? {
                                backgroundColor: "#F0CF2229",
                              }
                            : undefined
                        }
                      />
                    );
                  })}
                </Tabs>
              </Box>
              {selectedSheetData && (
                <HotTable
                  className={styles.table}
                  ref={hotRef}
                  data={tableData}
                  height="auto"
                  width="auto"
                  autoWrapRow
                  autoWrapCol
                  autoColumnSize
                  stretchH="none"
                  licenseKey="non-commercial-and-evaluation"
                  afterChange={handleAfterChange}
                  afterRender={handleAfterRender}
                  afterInit={handleAfterInit}
                  disableVisualSelection={
                    isRecalculateModelPending || isInputsLoading
                  }
                  readOnly={
                    isReadonlyData ||
                    isRecalculateModelPending ||
                    isInputsLoading
                  }
                />
              )}
              {calculatedOutputData.length === 0 && isOutputTabSelected && (
                <Typography variant="body1">
                  Для просмотра результата, произведите расчет
                </Typography>
              )}
            </div>
          )}
        </div>
      )}

      {currentModel && (
        <RightSidebar
          isOpen={!!openedSidebar}
          toggleSidebar={(open) => {
            if (!open) setOpenedSidebar(null);
          }}
          header={versionsSidebarHeader}
          content={versionsSidebarContent}
          hasButton={false}
        />
      )}
      {version && (
        <VersionsLabelsModal
          open={openedModal === "labels"}
          versionId={version}
          handleClose={handleCloseModal}
        />
      )}
      {version && (
        <VersionAnnotationModal
          open={openedModal === "annotationEditor"}
          versionId={version}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default GridVersionPage;
