import { useQuery } from "@tanstack/react-query";
import { financeModelInputKeys } from "../const";
import { fetchInputs, fetchInputTabs } from "../api";
import { FinanceModelInputsAllDto } from "../model";

const useFinanceModelInputsAll = ({
  financeModelId = -1,
  enabled = true,
  includeBiTabs = false,
}: {
  financeModelId?: number;
  enabled?: boolean;
  includeBiTabs?: boolean;
}) => {
  return useQuery<FinanceModelInputsAllDto[]>({
    queryKey: financeModelInputKeys.inputs(financeModelId).queryKey,
    queryFn: async () => {
      const { inputTabs, outputTabs, biInputTabs, biOutputTabs } =
        await fetchInputTabs({
          financeModelId,
        });

      console.debug(
        "Получаем инпуты:",
        inputTabs,
        outputTabs,
        biInputTabs,
        biOutputTabs
      );

      return Promise.all([
        ...inputTabs.map(async (tabName) => {
          const d = await fetchInputs({ financeModelId, tabName, purpose: "forms" });
          return { tabName, data: d, type: "input" };
        }),
        ...outputTabs.map((tabName) => {
          return { tabName, data: [], type: "output" };
        }),
        ...(includeBiTabs
          ? biInputTabs.map(async (tabName) => {
              const d = await fetchInputs({ financeModelId, tabName, purpose: "bi" });
              return { tabName: `bi_${tabName}`, data: d, type: "biInput" };
            })
          : []),
        ...(includeBiTabs
          ? biOutputTabs.map((tabName) => {
              return { tabName: `bi_${tabName}`, data: [], type: "biOutput" };
            })
          : []),
      ]);
    },
    enabled,
  });
};

export { useFinanceModelInputsAll };
