import { CssBaseline, Box, IconButton } from "@mui/material";
import Header from "@/widgets/Header";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useUserProfile } from "@/entities/User/queries/useUserProfile";
import { useUserMenuGroups } from "@/features/User";
import UserAvatarUpdate from "@/features/User/ui/UserAvatarUpdate";
import UserDetails from "@/features/User/ui/UserDetails";
import { ChangePassword } from "@/features/User/ui/ChangePassword";
import { NotificationSettings } from "@/features/User/ui/NotificationSettings";
import ProfileSection from "@/features/User/ui/ProfileSection";
import { CompaniesInfo } from "@/entities/Company";
import { useHistory } from "react-router-dom";

const ProfilePage = () => {
  const userMenuGroups = useUserMenuGroups();
  const { data: user } = useUserProfile();
  const history = useHistory();
  const handleBackClick = () => {
    history.goBack();
  };
  return (
    <Box
      sx={{
        paddingTop: "var(--headerHeight)",
        maxWidth: "1280px",
        margin: "0 auto",
        paddingX: 1,
      }}
    >
      <CssBaseline />
      <Header
        UserMenuGroups={{
          menuGroups: userMenuGroups,
          alignment: "right",
          profileImage: user?.avatar,
        }}
      />
      <Box
        sx={{
          paddingTop: "10px",
        }}
      >
        <IconButton
          onClick={handleBackClick}
          sx={{
            alignSelf: "flex-start",
            marginBottom: "16px",
            color: "var(--colorBlue)",
            gap: "5px",
          }}
        >
          <ArrowBackIcon />
          <span>Назад</span>
        </IconButton>
      </Box>

      <ProfileSection>
        <UserAvatarUpdate avatarUrl={user?.avatar} />
      </ProfileSection>

      <ProfileSection>
        <UserDetails user={user} />
      </ProfileSection>

      <ProfileSection>
        <CompaniesInfo />
      </ProfileSection>

      <ProfileSection>
        <ChangePassword />
      </ProfileSection>

      <ProfileSection>
        <NotificationSettings />
      </ProfileSection>
    </Box>
  );
};

export default ProfilePage;
