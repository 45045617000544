import { api } from "@/shared/config/network";
import { stringify } from "qs";

import type {
  FinanceModelInputRecalculateDto,
  FinanceModelRecalculateResponse,
} from "../model";

const recalculateInputs = async ({
  financeModelId,
  dto,
  purpose = ["forms", "biandforms"],
}: {
  financeModelId: number;
  dto: FinanceModelInputRecalculateDto[];
  purpose?: string[];
}) => {
  const response = await api.post<FinanceModelRecalculateResponse>(
    `/finance-models/${financeModelId}/recalculate`,
    {
      purpose,
      inputs: dto,
    },
    {
      paramsSerializer: (params) => {
        return stringify(params, {
          arrayFormat: "repeat",
        });
      },
    }
  );
  return response.data;
};

export { recalculateInputs };
