import React, { MouseEvent, useState } from "react";
import { UniversalMenu } from "@/shared/ui/UniversalMenu";
import { Tooltip, IconButton, Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowBottomIcon from "@/assets/icons/arrowBottom.svg?react";

type SortBy = "updatedAt" | "createdAt";

const sortLabels: Record<SortBy, string> = {
  updatedAt: "Последнее обновление",
  createdAt: "Дата создания",
};

interface SortMenuProps {
  currentSort: SortBy;
  onSortChange: (sort: SortBy) => void;
}

const IconButtonWithArrow = styled(IconButton)(() => ({
  position: "relative",
  padding: 0,
  color: "black",
  lineHeight: "150%",
  fontSize: "16px",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
}));

export const SortMenu: React.FC<SortMenuProps> = ({
  currentSort,
  onSortChange,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (sort: SortBy) => {
    onSortChange(sort);
    handleClose();
  };

  const sortMenuGroups = [
    {
      items: Object.entries(sortLabels).map(([key, label]) => ({
        label,
        onClick: () => handleMenuItemClick(key as SortBy),
      })),
    },
  ];

  return (
    <>
      <Tooltip title="Открыть меню">
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Typography
            sx={{
              opacity: 0.4,
            }}
          >
            Сортировка
          </Typography>
          <IconButtonWithArrow
            onClick={handleClick}
            disableRipple
            sx={{ gap: "13px" }}
          >
            {sortLabels[currentSort]}
            <ArrowBottomIcon
              width={15}
              height={18}
              className="arrow-icon"
              color="#000"
            />
          </IconButtonWithArrow>
        </Box>
      </Tooltip>
      <UniversalMenu
        groups={sortMenuGroups}
        triggerElement={<div />}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        alignment="right"
      />
    </>
  );
};
