import { useMutation, useQueryClient } from "@tanstack/react-query";
import { uploadPreview } from "../api";
import { financeModelKeys } from "../const";

const useFinanceModelPreviewUpload = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: uploadPreview,
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: financeModelKeys.list().queryKey,
      });
    },
    onError: (error) => {
      console.error("File upload failed", error);
    },
  });
};

export { useFinanceModelPreviewUpload };
