import { useState, type FC } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Divider,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Fullscreen from "@mui/icons-material/Fullscreen";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver as resolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object({
    publicUrl: yup.string().required(),
  })
  .required();

import {
  type DashboardDto,
  useDashboardCreate,
  useDashboardDelete,
  useDashboards,
} from "@/entities/Dashboards";
import { Link } from "react-router-dom";

interface DashboardsModalProps {
  financeModelId: number;
  open: boolean;
  handleClose: () => void;
}

interface DashboardsFormData {
  publicUrl: string;
}

const DashboardsModal: FC<DashboardsModalProps> = ({
  financeModelId,
  open,
  handleClose: onClose,
}) => {
  const [previewDashboard, setPreviewDashboard] = useState<DashboardDto | null>(
    null
  );

  const { data: dashboards = [] } = useDashboards({ financeModelId });
  const { mutate: create } = useDashboardCreate();
  const { mutate: del } = useDashboardDelete();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DashboardsFormData>({
    resolver: resolver(schema),
  });

  const handleClose = () => {
    reset();
    setPreviewDashboard(null);
    onClose?.();
  };

  const onSubmit: SubmitHandler<DashboardsFormData> = (data) => {
    create(
      { financeModelId, ...data },
      {
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Dashboards</DialogTitle>

      {previewDashboard ? (
        <>
          <DialogContent sx={{ gap: 1, display: 'flex' }}>
            <Button
              variant="outlined"
              onClick={() => setPreviewDashboard(null)}
              startIcon={<ArrowBack />}
            >
              Back
            </Button>

            <Button
              component={Link}
              to={`/dashboards/${previewDashboard.id}`}
              variant="outlined"
              startIcon={<Fullscreen />}
            >
              Fullscreen
            </Button>
          </DialogContent>
          <DialogContent>
            <iframe
              key={previewDashboard.id}
              style={{
                width: "100%",
                height: "50vh",
                border: 0,
              }}
              src={previewDashboard.publicUrl}
            />
          </DialogContent>
        </>
      ) : (
        <>
          <DialogContent>
            <List sx={{ width: "100%", bgcolor: "background.paper" }}>
              {dashboards.length > 0
                ? dashboards.map((dashboard, i: number) => (
                    <ListItem
                      key={dashboard.id}
                      sx={{
                        paddingLeft: "24px",
                        paddingRight: "24px",
                        margin: "0 -24px",
                        width: "calc(100% + 48px)",
                        background: (theme) =>
                          i % 2 !== 0 ? theme.palette.grey[100] : undefined,
                      }}
                    >
                      <ListItemText
                        primary={`#${dashboard.id}. ${
                          dashboard.uuid ?? dashboard.publicUrl
                        }`}
                      />
                      <Button
                        size="large"
                        color="error"
                        onClick={() => {
                          del(dashboard.id);
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        size="large"
                        onClick={() => {
                          setPreviewDashboard(dashboard);
                        }}
                      >
                        Preview
                      </Button>
                    </ListItem>
                  ))
                : "No dashboards are added"}
            </List>
          </DialogContent>
          <Divider />

          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <DialogContentText>Add a new dashboard</DialogContentText>

              <TextField
                {...register("publicUrl")}
                label="publicUrl"
                fullWidth
                margin="normal"
                error={Boolean(errors.publicUrl)}
                helperText={errors.publicUrl?.message}
              />
            </DialogContent>

            <DialogActions>
              <Button onClick={handleClose} color="primary" variant="outlined">
                Close Modal
              </Button>

              <Button
                color="primary"
                type="submit"
                variant="contained"
                disableElevation
              >
                Save
              </Button>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
};

export { DashboardsModal };
