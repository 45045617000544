import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import html2canvas from "html2canvas";

interface UseScreenshotOnRouteChangeProps {
  callback: (screenshot: string) => void;
  routesToWatch: string[];
}

export const useScreenshotOnRouteChange = ({
  callback,
  routesToWatch,
}: UseScreenshotOnRouteChangeProps) => {
  const history = useHistory();
  const location = useLocation();
  const prevPathRef = useRef<string>(location.pathname);
  const unblockRef = useRef<() => void | undefined>();

  useEffect(() => {
    const handleScreenshot = async () => {
      try {
        const target =
          document.getElementById("luckysheet") || document.documentElement;
        const canvas = await html2canvas(target);
        const dataURL = canvas.toDataURL("image/png");
        callback(dataURL);
      } catch (error) {
        console.error("Error taking screenshot:", error);
      }
    };

    const isWatchedRoute = (path: string) =>
      routesToWatch.some((route) => path.startsWith(route));

    const unblock = history.block((nextLocation) => {
      const previousPath = prevPathRef.current;
      const nextPath = nextLocation.pathname;

      if (isWatchedRoute(previousPath) && !isWatchedRoute(nextPath)) {
        handleScreenshot().then(() => {
          unblockRef.current?.();
          history.push(nextPath);
        });
        return false;
      }

      return undefined;
    });

    unblockRef.current = unblock;

    return () => {
      unblock();
    };
  }, [history, callback, routesToWatch, location.pathname]);
};
