import { Box } from "@mui/material";
import { useRef, useState, useEffect, FC } from "react";
import ArrowBottomIcon from "@/assets/icons/arrowBottom.svg?react";

interface SpoilerProps {
  isOpen: boolean;
  toggleSpoiler: () => void;
  children: React.ReactNode;
  labelSlot: React.ReactNode;
}

const Spoiler: FC<SpoilerProps> = ({
  isOpen,
  toggleSpoiler,
  children,
  labelSlot,
}) => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [contentHeight, setContentHeight] = useState<string | number>(0);

  useEffect(() => {
    if (isOpen && contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    } else {
      setContentHeight(0);
    }
  }, [isOpen]);

  return (
    <Box>
      <Box
        onClick={toggleSpoiler}
        sx={{
          display: "flex",
          cursor: "pointer",
          gap: "8px",
          alignItems: "center",
        }}
      >
        {labelSlot}
        <span>
          <ArrowBottomIcon
            color="#000"
            style={{
              transform: isOpen ? "scaleY(-1)" : "scaleY(1)",
              transition: "transform 0.3s ease",
            }}
          />
        </span>
      </Box>
      <Box
        ref={contentRef}
        sx={{
          maxHeight: contentHeight,
          overflow: "hidden",
          transition: "max-height 0.3s ease",
        }}
      >
        <Box
          sx={{
            paddingTop: "16px",
            display: "flex",
            flexWrap: "wrap",
            gap: "24px",
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export { Spoiler };
