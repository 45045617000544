import { useMutation, useQueryClient } from "@tanstack/react-query";
import { attachLabelsToVersion } from "../api";
import { versionsKeys } from "../const";

export const useAttachLabelsToVersion = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: attachLabelsToVersion,
    onSuccess: (...[, { versionId }]) => {
      [versionsKeys.byVersionId, versionsKeys.labelsByVersionId].forEach(
        (keyFn) => {
          qc.invalidateQueries({
            queryKey: keyFn(versionId).queryKey,
          });
        }
      );
    },
  });
};
