import { FC, useMemo } from "react";
import { Menu as MuiMenu, MenuItem, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { UniversalMenuProps } from "./UniversalMenu.types";

const StyledMenu = styled(MuiMenu)(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    minWidth: "285px",
    background: "var(--colorWhite)",
    borderRadius: 8,
    paddingBlock: "0",
    boxShadow: theme.palette.customShadows.lightBlue,
  },
  "& ul": {
    padding: "8px 6px",
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  marginBottom: 0,
  paddingBlock: "0",
  borderRadius: "8px",
  paddingLeft: "8px",
  paddingRight: "32px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "114%",
  minHeight: "32px !important",
  color: "#000",
  display: "flex",
  gap: "8px",
  "&:last-child": {
    marginBottom: 0,
  },
  "&:hover": {
    backgroundColor: "rgba(80, 158, 227, 0.16)",
    color: theme.palette.customColors.colorBrandeisBlue,
    "&.no-hover": {
      backgroundColor: "inherit",
      color: "#000",
    },
  },
  "& .content": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
  "&.no-padding": {
    paddingLeft: "0",
    paddingRight: "0",
  },
  "&.no-cursor": {
    cursor: "unset",
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: theme.palette.customColors.colorLightBlue200,
}));

export const UniversalMenu: FC<UniversalMenuProps> = ({
  groups,
  triggerElement,
  alignment = "right",
  open,
  anchorEl,
  onClose,
  extraClasses = "",
  extraAttrs = {},
}) => {
  const menuItems = useMemo(() => {
    return groups.flatMap((group, groupIndex) => [
      ...group.items.flatMap((item, itemIndex) => [
        item.divider && (
          <StyledDivider key={`divider-${groupIndex}-${itemIndex}`} />
        ),
        <StyledMenuItem
          key={`item-${groupIndex}-${itemIndex}`}
          onClick={(event) => {
            item.onClick?.(event);
            // Priority is item > group
            if (group.shouldCloseOnClick) {
              if (item.shouldCloseOnClick ?? true) {
                onClose(event);
              }
            }
          }}
          className={`interFont ${item.extraClasses}`}
          {...item.extraAttrs}
        >
          {item.content ? (
            item.content
          ) : (
            <>
              {item.icon && (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "32px",
                    aspectRatio: "1 / 1",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </span>
              )}
              {item.label}
            </>
          )}
        </StyledMenuItem>,
      ]),
      group.divider && <StyledDivider key={`group-divider-${groupIndex}`} />,
    ]);
  }, [groups, onClose]);

  return (
    <>
      <div
        onClick={(event) => event.stopPropagation()}
        style={{ cursor: "pointer" }}
        {...extraAttrs}
      >
        {triggerElement}
      </div>
      <StyledMenu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: alignment === "right" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: alignment === "right" ? "right" : "left",
        }}
        className={extraClasses}
      >
        {menuItems}
      </StyledMenu>
    </>
  );
};
