import { FC } from "react";
import { Avatar } from "@mui/material";

interface UserAvatarProps {
  src?: string;
  width?: number | string;
  height?: number | string;
}

export const UserAvatar: FC<UserAvatarProps> = ({ src, width, height }) => (
  <Avatar
    src={src}
    alt="Аватар пользователя"
    sx={{
      width: width ?? "24px",
      height: height ?? "24px",
    }}
  />
);
