// useAuthForm.ts
import { useForm, SubmitHandler } from "react-hook-form";
import { useContext } from "react";
import { AuthContext } from "@/app/providers/Auth";
import { useHistory } from "react-router-dom";
import { useNotification } from "@/shared/hooks/useNotification";
import { createValidationSchema } from "@/shared/config/validation/authValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginMutation } from "./useLoginMutation";

interface FormValues {
  username: string;
  password: string;
}

export const useAuthForm = () => {
  const validationSchema = createValidationSchema();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    getValues,
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      username: "",
      password: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const { login } = useContext(AuthContext);
  const { replace } = useHistory();
  const { notifyError } = useNotification();
  const { mutate } = useLoginMutation();

  const handleLogin: SubmitHandler<FormValues> = async (data) => {
    mutate(
      { username: data.username, password: data.password },
      {
        onSuccess: (response) => {
          login(response.token);
          replace("/");
        },
        onError: () => {
          notifyError("Ошибка входа. Обратитесь к администратору", {
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            autoHideDuration: 5000,
          });
        },
      }
    );
  };

  return {
    register,
    handleSubmit: handleSubmit(handleLogin),
    errors,
    getValues,
    setValue,
    trigger,
    isSubmitting,
  };
};
