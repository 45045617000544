import { useQuery } from "@tanstack/react-query";

import { fetchBackups } from "../api/fetch-backups";
import { backupKeys } from "../const";

interface UseBackupsParams {
  financeModelId: number;
}

const useBackups = ({ financeModelId }: UseBackupsParams) => {
  return useQuery({
    queryKey: backupKeys.list(financeModelId).queryKey,
    queryFn: () => fetchBackups({ financeModelId }),
  });
};

export { useBackups };
