import { FC } from "react";
import { Spoiler } from "@/shared/ui/Spoiler";
import { useToggleSpoiler } from "@/shared/hooks/useToggleSpoiler";
import { TemplateCard } from "@/entities/Templates/ui/TemplateCard";
import { useTemplates } from "@/entities/Templates";
import { Box, Typography, useTheme } from "@mui/material";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";

interface TemplatesSectionProps {
  onTemplateSelect: (templateId: number) => void;
  isOpen?: boolean;
}

export const TemplatesSection: FC<TemplatesSectionProps> = ({
  onTemplateSelect,
  isOpen: isInitialOpen,
}) => {
  const { data: templates, isLoading } = useTemplates();
  const { isOpen, toggleSpoiler } = useToggleSpoiler({
    initialOpen: isInitialOpen,
  });

  const handleTemplateClick = (templateId: number) => {
    onTemplateSelect(templateId);
  };

  const theme = useTheme();
  return (
    <Box
      sx={{
        padding: "24px 32px",
        border: `1px solid ${theme.palette.customColors.colorArgentinianBlue}`,
        borderRadius: "8px",
        position: "relative",
        marginBottom: "64px",
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Spoiler
          isOpen={isOpen}
          toggleSpoiler={toggleSpoiler}
          labelSlot={
            <Typography
              sx={{ fontSize: "16px", lineHeight: "200%", fontWeight: 500 }}
            >
              Шаблоны
            </Typography>
          }
        >
          {templates?.map((template) => (
            <TemplateCard
              key={template.id}
              title={template.name}
              description={template.description}
              onClick={() => handleTemplateClick(template.id)}
            />
          ))}
        </Spoiler>
      )}
    </Box>
  );
};
