import { api } from "@/shared/config/network";

import { UserAvatarUpdateResponse } from "../model";

const uploadAvatar = async (avatar: File) => {
  const formData = new FormData();
  formData.append("avatar", avatar);

  const response = await api.postForm<UserAvatarUpdateResponse>(
    "/users/me/avatar",
    formData
  );

  return response.data;
};

export { uploadAvatar };
