import { FC, useEffect, useState, Fragment, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Divider,
  styled,
  IconButton,
  useTheme,
  Tooltip,
  Dialog,
} from "@mui/material";
import RestoreIcon from "@/assets/icons/restore.svg?react";
import LockOpenIcon from "@/assets/icons/lockOpen.svg?react";
import LockClosedcon from "@/assets/icons/lockClosed.svg?react";
import MoreIcon from "@/assets/icons/more.svg?react";
import DeleteIcon from "@/assets/icons/delete.svg?react";
import InfoIcon from "@/assets/icons/info.svg?react";
import {
  useBackups,
  useBackupRestore,
  useBackupDelete,
  BackupDto,
} from "@/entities/Backup";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { formatDateWithSeconds } from "@/shared/config/dateFormat";
import { useBackupPreview } from "@/entities/Backup/queries/useBackupPreview";
import { useLuckySheetContext } from "@/contexts/LuckysheetDataContext";
import { useBackupProtect } from "@/entities/Backup/queries/useBackupProtect";
import { useNotification } from "@/shared/hooks/useNotification";
import { SmallAvatar } from "@/shared/ui/SmallAvatar";
import { ModelMenu, ModelMenuProps } from "../ModelMenu";
import styles from "./Backups.module.css";
import { useUserProfile } from "@/entities/User";
import { BackupRestoreCurrentDialogContent } from "./components/BackupRestoreCurrentDialogContent";
import { BackupDeleteDialogContent } from "./components/BackupDeleteDialogContent";
import { BackupInfoDialogContent } from "./components/BackupInfoDialogContent";
import { FinanceModelCreateFromBackup } from "@/features/FinanceModel/FinanceModelCreate/ui/FinanceModelCreateFromBackup";
import { routePaths } from "@/shared/config/routePaths";

interface BackupListProps {
  financeModelId: number;

  onOpenBackup(value: BackupDto | null): void;

  onClose: VoidFunction;
}

// Создаем стилизованный компонент ListItem
const StyledListItem = styled(ListItem)(({ theme }) => ({
  position: "relative",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.customColors.colorAliceBlue,
  },
}));

const BackupList: FC<BackupListProps> = ({
  financeModelId,
  onOpenBackup,
  onClose,
}) => {
  const theme = useTheme();
  const { data: user } = useUserProfile();
  const { notifyInfo, notifySuccess } = useNotification();
  const { push } = useHistory();
  const { setData, setOptions } = useLuckySheetContext();

  const { data: backups = [] } = useBackups({ financeModelId });
  const { mutate: restoreBackup } = useBackupRestore();
  const [previewBackupId, setPreviewBackupId] = useState<number | null>(null);
  const { data: backupPreview } = useBackupPreview(previewBackupId);
  const [pendingBackupId, setPendingBackupId] = useState<number | null>(null);
  const { mutate: protectBackup } = useBackupProtect(financeModelId);
  const { mutate: deleteBackup } = useBackupDelete(financeModelId);
  const [openedBackupDialogType, setOpenedBackupDialogType] = useState<
    "restore_current" | "restore_new" | "delete" | "info" | null
  >(null);

  const { setData: setLsData, setOptions: setLsOptions } =
    useLuckySheetContext();

  const selectedBackup = useMemo(() => {
    return (
      backups.find((backup) => {
        return pendingBackupId === backup.id;
      }) ?? null
    );
  }, [pendingBackupId, backups]);

  useEffect(() => {
    if (backupPreview) {
      const { data, showinfobar, showtoolbar } = backupPreview.jsonData;

      setLsData(data);
      setLsOptions({ allowEdit: false, showinfobar, showtoolbar });
    }
  }, [backupPreview]);

  const handleBackupDialogOpen = (
    id: number,
    type: typeof openedBackupDialogType
  ) => {
    setPendingBackupId(id);
    setOpenedBackupDialogType(type);
  };
  const handleBackupDialogClose = () => {
    setPendingBackupId(null);
    setOpenedBackupDialogType(null);
  };

  const handlePreviewBackup = (id: number) => {
    setPreviewBackupId(id);

    const backup = backups.find((b) => b.id === id);
    onOpenBackup(backup ?? null);
  };
  const handleRestoreCurrentBackup = () => {
    const id = pendingBackupId;
    if (!id) return;

    restoreBackup(
      { versionId: id.toString() },
      {
        onSuccess: () => {
          window.location.reload();
        },
        onSettled: () => {
          setPendingBackupId(null);
        },
      }
    );
  };
  const handleDeleteBackup = () => {
    const id = pendingBackupId;
    if (!id) return;

    deleteBackup(
      {
        id,
      },
      {
        onSuccess: () => {
          notifySuccess("Резервная копия удалена");
          handleBackupDialogClose();
        },
      }
    );
  };
  const handleUpdateBackup = () => {
    const id = pendingBackupId;
    if (!id) return;

    notifyInfo("В будущем можно будет обновить информацию о резервной копии");
  };
  const handleProtect = (id: number, isProtected: boolean) => {
    protectBackup(
      {
        backupId: id,
        isProtected: !isProtected,
      },
      {
        onSuccess: () => {
          notifyInfo(
            isProtected
              ? "Защита от удаления убрана"
              : "Резервная копия защищена от удаления"
          );
        },
      }
    );
  };

  const onRestoreToNewSuccess = (gridKey: string) => {
    handleBackupDialogClose();

    setData(null);
    setOptions({ allowEdit: true, showinfobar: false, showtoolbar: true });

    onOpenBackup(null);
    onClose();

    push(`${routePaths.grid}/${gridKey}`);

    notifySuccess("Данные восстановлены в новую модель");
  };

  const getBackupActionsMenu = (id: number): ModelMenuProps["menuGroups"] => {
    return [
      {
        items: [
          {
            label: "Восстановить в текущую",
            icon: (
              <RestoreIcon
                color={theme.palette.customColors.colorBrandeisBlue}
              />
            ),
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              handleBackupDialogOpen(id, "restore_current");
            },
          },
          {
            label: "Восстановить в новую",
            icon: (
              <RestoreIcon
                color={theme.palette.customColors.colorBrandeisBlue}
              />
            ),
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              handleBackupDialogOpen(id, "restore_new");
            },
          },
          {
            label: "Информация",
            icon: <InfoIcon />,
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              handleBackupDialogOpen(id, "info");
            },
          },
        ],
        divider: true,
      },
      {
        items: [
          {
            label: "Удалить",
            extraClasses: styles.trash,
            icon: <DeleteIcon />,
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              handleBackupDialogOpen(id, "delete");
            },
          },
        ],
      },
    ];
  };

  return (
    <Box>
      <List sx={{ width: "100%" }}>
        {backups.length > 0 ? (
          backups.map((backup, index) => (
            <Fragment key={backup.id}>
              <StyledListItem
                title="Предпросмотр резервной копии"
                onClick={() => handlePreviewBackup(backup.id)}
                sx={{
                  margin: "0 -34px",
                  paddingInline: "34px",
                  width: "calc(100% + 64px)",
                  minHeight: "112px",
                  flexDirection: "column",
                  gap: "16px",
                  cursor: "pointer",
                  backgroundColor: backup.protected
                    ? "#FF855988"
                    : "transparent",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <ListItemText
                    primary={backup.versionName}
                    secondary={backup.description}
                    sx={{
                      "& .MuiListItemText-primary": {
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "150%",
                      },
                      "& .MuiListItemText-secondary": {
                        fontSize: "14px",
                        color: "gray",
                      },
                    }}
                  />

                  {pendingBackupId === backup.id ? (
                    <Spinner withBg={true} />
                  ) : (
                    <>
                      <ModelMenu
                        menuGroups={getBackupActionsMenu(backup.id)}
                        MenuIcon={MoreIcon}
                        extraAttrs={{ className: styles.menu }}
                      />
                      <Tooltip
                        title={
                          backup.protected ? "Убрать защиту" : "Защитить бэкап"
                        }
                        placement="top"
                      >
                        <IconButton
                          onClick={() =>
                            handleProtect(backup.id, backup.protected)
                          }
                          sx={{
                            padding: "7px",
                            height: "max-content",
                            "&:hover": {
                              backgroundColor:
                                theme.palette.customColors.colorLightBlue200,
                            },
                          }}
                        >
                          {backup.protected ? (
                            <LockClosedcon width={24} height={24} />
                          ) : (
                            <LockOpenIcon width={24} height={24} />
                          )}
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: "8px",
                  }}
                >
                  <Box>
                    <SmallAvatar
                      avatarSrc={user?.avatar || ""}
                      fullname={user?.fullname || ""}
                    />
                  </Box>
                  <Box>{formatDateWithSeconds(backup.createdAt)}</Box>
                </Box>
              </StyledListItem>
              {index !== backups.length - 1 && <Divider />}
            </Fragment>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            Нет резервных копий
          </Typography>
        )}
      </List>
      <Dialog open={!!openedBackupDialogType} onClose={handleBackupDialogClose}>
        {openedBackupDialogType === "restore_current" && (
          <BackupRestoreCurrentDialogContent
            handleBackupDialogClose={handleBackupDialogClose}
            handleRestoreBackup={handleRestoreCurrentBackup}
          />
        )}
        {openedBackupDialogType === "delete" && (
          <BackupDeleteDialogContent
            handleBackupDialogClose={handleBackupDialogClose}
            handleDeleteBackup={handleDeleteBackup}
          />
        )}
        {openedBackupDialogType === "info" && selectedBackup && (
          <BackupInfoDialogContent
            backupInfo={selectedBackup}
            handleBackupDialogClose={handleBackupDialogClose}
            handleUpdateBackup={handleUpdateBackup}
          />
        )}
      </Dialog>

      <FinanceModelCreateFromBackup
        backupId={pendingBackupId ?? undefined}
        open={openedBackupDialogType === "restore_new"}
        onClose={handleBackupDialogClose}
        onSuccess={onRestoreToNewSuccess}
      />
    </Box>
  );
};

export { BackupList };
