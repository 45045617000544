// src/shared/hooks/useNotification.ts
import { useSnackbar } from "notistack";

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifySuccess = (message: string, options = {}) => {
    enqueueSnackbar(message, {
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      ...options,
    });
  };

  const notifyError = (message: string, options = {}) => {
    enqueueSnackbar(message, {
      variant: "error",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      ...options,
    });
  };

  const notifyInfo = (message: string, options = {}) => {
    enqueueSnackbar(message, {
      variant: "info",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      ...options,
    });
  };

  const notifyWarning = (message: string, options = {}) => {
    enqueueSnackbar(message, {
      variant: "warning",
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      ...options,
    });
  };

  return { notifySuccess, notifyError, notifyInfo, notifyWarning };
};
