import { api } from "@/shared/config/network";
import type { TemplateDto } from "../model";

interface FetchTemplatesResponse {
  templates: TemplateDto[];
}

const fetchTemplates = async () => {
  const response = await api.get<FetchTemplatesResponse>(`/templates/`);
  return response.data?.templates;
};

export { fetchTemplates };
