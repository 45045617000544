import { type FC } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import GridPage from "@/pages/GridPage";
import ScenarioPage from "@/pages/ScenarioPage";
import SettingsPage from "@/pages/Settings";
import ProfilePage from "@/pages/Profile";
import ModelInfoPage from "@/pages/ModelInfoPage";
import Dashboard from "@/pages/Dashboard";
import AuthPage from "@/pages/Auth";
import { AuthProvider } from "./providers/Auth";
import { qc } from "./providers/QueryClient/const";
import ProtectedRoute from "./components/ProtectedRoute";
import "./initializers";
import { theme } from "@/shared/const/theme";
import { ThemeProvider } from "@mui/material/styles";
import { NotificationProvider } from "./providers/Notification";
import GlobalStyles from "@/shared/styles/index";
import ModelsPage from "@/pages/ModelsPage";
import GridVersionPage from "@/pages/GridVersionPage";
import { useDisableScrollLock } from "@/shared/hooks/useScrollLock";
import { Register } from "@/pages/Register";
import { routePaths } from "@/shared/config/routePaths";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const App: FC = () => {
  useDisableScrollLock();
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <AuthProvider>
        <QueryClientProvider client={qc}>
          <NotificationProvider>
            <Router>
              <Switch>
                <Route path="/login" component={AuthPage} />
                <Route path="/register" component={Register} />
                <ProtectedRoute path="/settings" component={SettingsPage} />
                <ProtectedRoute path="/profile" component={ProfilePage} />
                <ProtectedRoute path="/models" component={ModelsPage} />
                <ProtectedRoute path="/dashboards/:id" component={Dashboard} />
                <ProtectedRoute
                  path="/grid/:gridkey/info"
                  component={ModelInfoPage}
                />
                <ProtectedRoute
                  path={[
                    "/grid/:gridkey/versions/:version",
                    "/grid/:gridkey/versions",
                  ]}
                  component={GridVersionPage}
                />
                <ProtectedRoute
                  path={[
                    "/grid/:gridkey/:modalName",
                    "/grid/:gridkey",
                    "/grid",
                  ]}
                  component={GridPage}
                />
                <ProtectedRoute
                  path={[`${routePaths.scenarios.grid}/:id`]}
                  component={ScenarioPage}
                />

                <Redirect to="/grid" />
              </Switch>
              {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </Router>
          </NotificationProvider>
        </QueryClientProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
