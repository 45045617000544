import { api } from "@/shared/config/network";

const editFinanceModel = async (
  id: number,
  {
    name,
    description,
    sourceCode,
  }: { name?: string; description?: string; sourceCode?: string }
) => {
  const response = await api.put(`/finance-models/${id}`, {
    name,
    description,
    sourceCode,
  });
  return response.data;
};

export { editFinanceModel };
