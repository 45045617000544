import { Box, Typography } from "@mui/material";
import { FC } from "react";

interface ViewModeBadgeProps {
  onBadgeClick: () => void;
}

const ViewModeBadge: FC<ViewModeBadgeProps> = ({ onBadgeClick }) => {
  // TODO: Change color when we have designs
  return (
    <Box
      sx={{
        padding: "4px 8px",
        backgroundColor: "#ff000077",
        cursor: "pointer",
      }}
      onClick={onBadgeClick}
    >
      <Typography variant="body1" color={"textSecondary"}>
        Режим предпросмотра
      </Typography>
    </Box>
  );
};

export { ViewModeBadge };
