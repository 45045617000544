import { api } from "@/shared/config/network";
import type { TemplateUseDto } from "../model";

const templateUse = async ({
  templateId,
  name = "",
  description = "",
  formulaEngine = "hyperformula",
}: {
  templateId: number | undefined | null;
  name: string;
  description: string;
  formulaEngine?: string;
}) => {
  const response = await api.post<TemplateUseDto>(`/templates/use`, {
    templateId,
    name,
    description,
    formulaEngine,
  });
  return response.data;
};

export { templateUse };
