import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Input } from "@/shared/ui/Input/Input";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { useNotification } from "@/shared/hooks/useNotification";
import { type FinanceModelCreateDto } from "@/entities/FinanceModel";
import { useRestoreBackupToNewModel } from "@/entities/Backup";

interface FinanceModelCreateProps {
  open: boolean;
  onClose: VoidFunction;
  backupId?: number;

  onSuccess?(gridKey: string): void;

  onError?(error: unknown): void;

  initialValues?: FinanceModelCreateDto;
}

const FinanceModelCreateFromBackup: FC<FinanceModelCreateProps> = (
  {
    open,
    onClose,
    onSuccess,
    onError,
    initialValues,
    backupId
  }
) => {
  const { notifyError } = useNotification();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors, isSubmitting, isValid }
  } = useForm<FinanceModelCreateDto>({ defaultValues: initialValues });

  const { mutate: restoreBackup } = useRestoreBackupToNewModel();

  const handleModalClose = () => {
    reset();
    onClose();
  };

  const onSubmit: SubmitHandler<FinanceModelCreateDto> = (data) => {
    if (backupId === undefined) {
      notifyError("Не выбрана резервная копия для восстановления");
      return;
    }

    restoreBackup(
      { versionId: backupId, name: data.name, description: data.description },
      {
        onSuccess: ({ gridKey }) => onSuccess?.(gridKey),
        onError
      }
    );
  };

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={"20px"}
        >
          <Typography variant="h6" component="h2">
            Создание модели
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Input
            placeholder="Имя модели"
            fullWidth
            {...register("name", { required: "Имя обязательно" })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
          />
          <Input
            placeholder="Описание"
            rows={4}
            multiline
            fullWidth
            {...register("description", { required: "Описание обязательно" })}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
          />
          <Box mt={2}>
            <Button
              variant="lightBlueV100"
              color="primary"
              type="submit"
              sx={{ mt: 2, width: "100%" }}
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? <Spinner withBg={true} /> : "Создать"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export { FinanceModelCreateFromBackup };
