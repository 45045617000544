import { useMemo } from "react";
import { useUserProfile } from "@/entities/User";
import { Permissions } from "../model";
import { checkUserPermission as check } from "../utils";

const usePermissions = () => {
  const { data: user } = useUserProfile();

  return useMemo(
    () => ({
      createModel: check(user, Permissions.CREATE_FINANCE_MODEL),

      duplicateModel: check(user, Permissions.CREATE_FINANCE_MODEL),

      addDashboard: check(user, Permissions.CREATE_FINANCE_MODEL),

      deleteModel: check(user, Permissions.CREATE_FINANCE_MODEL),

      publishMasterModel: check(user, Permissions.PUBLISH_MASTER_MODEL),

      createNewVersions: check(user, Permissions.CREATE_NEW_VERSION),

      manageBackups: check(user, Permissions.MANAGE_BACKUPS),

      submitFormForApproval: check(user, Permissions.SUBMIT_FORM_FOR_APPROVAL),

      submitForPublication: check(user, Permissions.SUBMIT_FOR_PUBLICATION),

      shareModel: check(user, Permissions.SHARE_MODEL),

      createTemplate: check(user, Permissions.CREATE_TEMPLATE),

      createSync: true,
      deleteSync: true,
    }),
    [user]
  );
};

export { usePermissions };
