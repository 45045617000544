export const routePaths = {
  register: "/register",
  login: "/login",
  settings: "/settings",
  profile: "/profile",
  forgotPassword: "/forgotPassword",
  notFound: "*",
  models: "/models",
  grid: "/grid",
  versions: "/versions",
  modelInfo: "/info",
  scenarios: {
    grid: "/scenarios/grid",
  },
};
