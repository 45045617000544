import { useMutation, useQueryClient } from "@tanstack/react-query";

import { cloneModel } from "../api";
import { financeModelKeys } from "../const";

const useCloneModel = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: cloneModel,
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: financeModelKeys.list().queryKey,
      });
    },
  });
};

export { useCloneModel };
