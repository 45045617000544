import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  ListSubheader,
  Toolbar,
  Button,
  Modal,
  Typography,
  TextField,
} from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { usePermissions } from "@/entities/User";
import {
  type FinanceModelCreateDto,
  useFinanceModels,
  useFinanceModelCreate,
} from "@/entities/FinanceModel";

import SidebarItem from "./SidebarItem";

const drawerWidth = 240;

const Sidebar: React.FC = () => {
  const iCan = usePermissions();
  const [selected, setSelected] = useState<number | null>(null);

  // Используем flatMap для сбора данных из всех страниц
  const { data: financeModelData } = useFinanceModels();
  const data = financeModelData?.pages.flatMap((page) => page.data) || [];

  const firstGridKey = data?.[0]?.gridkey ?? null;

  const { mutate: add } = useFinanceModelCreate();

  const match = useRouteMatch("/grid");
  const { gridkey } = useParams<{ gridkey: string }>();
  const { push } = useHistory();

  const pushToGridRouteEncoded = useCallback(
    (gridkey: string) => push(`/grid/${encodeURIComponent(gridkey)}`),
    [push]
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selected === null && gridkey && data.length) {
      const found = data.find(
        (v: any) => v.gridkey === decodeURIComponent(gridkey)
      )?.id;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      found && setSelected(found);
    }
  }, [selected, data, gridkey]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FinanceModelCreateDto>({
    defaultValues: {
      name: "",
      description: "",
      gridkey: uuid(),
    },
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    reset({ gridkey: uuid() });
    setOpen(false);
  };

  const onSubmit: SubmitHandler<FinanceModelCreateDto> = (data) => {
    add(data);
    handleClose();
  };

  useEffect(() => {
    if (match && !gridkey && firstGridKey) {
      pushToGridRouteEncoded(firstGridKey);
    }
  }, [match, gridkey, firstGridKey]);

  return (
    <Drawer
      variant="permanent"
      sx={{
        gridArea: "2 / 1 / 3 / 2",
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />

      <Box sx={{ py: 3 }}>
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Workbooks
            </ListSubheader>
          }
        >
          {data.map((item: any) => (
            <SidebarItem
              key={item.id}
              selected={item.id === selected}
              onClick={() => {
                setSelected(item.id);
                pushToGridRouteEncoded(item.gridkey);
              }}
              {...item}
            />
          ))}
        </List>

        {iCan.createModel && (
          <Button sx={{ width: "100%" }} onClick={handleOpen}>
            Add Workbook
          </Button>
        )}
      </Box>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Add Financial Model
          </Typography>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              control={control}
              rules={{ required: "Title is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  fullWidth
                  margin="normal"
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ""}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  fullWidth
                  margin="normal"
                />
              )}
            />
            <Controller
              name="gridkey"
              control={control}
              rules={{ required: "Gridkey is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Gridkey"
                  fullWidth
                  margin="normal"
                  error={!!errors.gridkey}
                  helperText={errors.gridkey ? errors.gridkey.message : ""}
                />
              )}
            />
            <Box mt={2}>
              <Button variant="contained" color="primary" type="submit">
                Save
              </Button>
              <Button onClick={handleClose} sx={{ ml: 2 }}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Drawer>
  );
};

export default Sidebar;
