import { useMutation, useQueryClient } from "@tanstack/react-query";
import { templateUse } from "../api";
import { financeModelKeys } from "@/entities/FinanceModel/const";

export const useTemplateForCreateModel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: templateUse,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: financeModelKeys._def });
    },
  });
};
