import { useMutation, useQueryClient } from "@tanstack/react-query";

import { uploadAvatar } from "../api";
import { userKeys } from "../const";
import { UserDto } from "../model";

const useUploadAvatar = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: uploadAvatar,
    onSuccess: (response) => {
      qc.setQueryData(userKeys.user.queryKey, (oldData: UserDto) => {
        return {
          ...oldData,
          avatar: response.avatarUrl,
        };
      });
    },
  });
};

export { useUploadAvatar };
