import { api } from "@/shared/config/network";

import { FinanceModelDto } from "../model";

const publishFinanceModel = async (financeModelId: number) => {
  const response = await api.post<FinanceModelDto>(
    `/finance-models/${financeModelId}/publish`
  );
  return response.data;
};

export { publishFinanceModel };
