import { FinanceModelDto } from "./model";

const checkIfCanViewOnly = (
  user: { id: number } | undefined,
  currentModel: FinanceModelDto
) => {
  // Current user is not an owner
  // and we have permission to view for everyone
  // or for current user
  return (
    user?.id !== currentModel?.ownerId &&
    currentModel.shares?.some((share) => {
      return (
        share.permissions === "view" &&
        (share.sharedWithUserId === null ||
          share.sharedWithUserId === user?.id)
      );
    })
  );
};

export { checkIfCanViewOnly };
