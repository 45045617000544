import { useQuery } from "@tanstack/react-query";

import { fetchSyncedData } from "../api";
import { syncedDataKeys } from "../const";

interface UseSyncedDataParams {
  gridkey: string;
  financeModelId: number;
  enabled?: boolean;
}

const useSyncedData = ({ gridkey, financeModelId, enabled = true }: UseSyncedDataParams) => {
  return useQuery({
    queryKey: syncedDataKeys.details(financeModelId).queryKey,
    queryFn: () => fetchSyncedData(gridkey),
    enabled,
  });
};

export { useSyncedData };
