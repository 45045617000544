import { api } from "@/shared/config/network";

interface FetchInputTabsParams {
  financeModelId: number;
}

interface FetchInputTabsResponseDto {
  inputTabs: string[];
  outputTabs: string[];
  biInputTabs: string[];
  biOutputTabs: string[];
}

const fetchInputTabs = async ({ financeModelId }: FetchInputTabsParams) => {
  const response = await api.get<FetchInputTabsResponseDto>(
    `/data-registry/finance-models/${financeModelId}/tabs`
  );

  return response.data;
};

export { fetchInputTabs, type FetchInputTabsResponseDto };
