import { useMutation, useQueryClient } from "@tanstack/react-query";

import { financeModelKeys } from "../const";
import { deleteFinanceModel } from "../api";

const useDeleteFinanceModel = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: deleteFinanceModel,
    onSuccess: () => {
      qc.invalidateQueries({
        queryKey: financeModelKeys.list().queryKey,
      });
    },
  });
};

export { useDeleteFinanceModel };
