import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateScenario } from "../api";
import { scenarioKeys } from "../const";
import { CreateScenarioRequest, ScenarioDetail } from "../model";
import { isAxiosError } from "axios";
import { useNotification } from "@/shared/hooks/useNotification";

const useUpdateScenario = () => {
  const queryClient = useQueryClient();
  const { notifyError } = useNotification();
  return useMutation<
    ScenarioDetail,
    Error,
    { id: string; dto: CreateScenarioRequest }
  >({
    mutationFn: ({ id, dto }) => updateScenario(id, dto),
    onSuccess: (_, { id }) => {
      console.debug("Инвалидация ключа", id);
      queryClient.invalidateQueries({
        queryKey: scenarioKeys.detail(id).queryKey,
      });
    },
    onError: (err) => {
      if (isAxiosError(err)) {
        const errorResponse: { message: string; error: string } =
          err.response?.data;
        notifyError(`${errorResponse.message}. ${errorResponse.error}`);
      }
    },
  });
};

export { useUpdateScenario };
