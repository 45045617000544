import { FC } from "react";
import { Drawer, IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

interface SidebarProps {
  header: React.ReactNode;
  content: React.ReactNode;
  isOpen: boolean;
  toggleSidebar: (open: boolean) => void;
  hasButton?: boolean;
}

export const RightSidebar: FC<SidebarProps> = ({
  header,
  content,
  isOpen,
  toggleSidebar,
  hasButton = true,
}) => {
  const theme = useTheme();
  return (
    <>
      {hasButton && (
        <IconButton
          onClick={() => toggleSidebar(true)}
          edge="start"
          color="inherit"
          aria-label="menu"
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={() => toggleSidebar(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: "min(100%, 576px)",
            zIndex: theme.zIndex.drawer + 3,
            boxShadow: theme.palette.customShadows.lightBlueFaded,
          },
          "& .MuiModal-backdrop": {
            background: "#ffffff00",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowX: "hidden",
            width: "min(100%, 576px)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "32px",
              color: "#000",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                flexGrow: 1,
              }}
            >
              {header}
            </Box>
            <IconButton
              onClick={() => toggleSidebar(false)}
              sx={{ color: "#000" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ paddingInline: "32px", flexGrow: 1 }}>{content}</Box>
        </Box>
      </Drawer>
    </>
  );
};
