import { useMutation, useQueryClient } from "@tanstack/react-query";

import { deleteSyncedData } from "../api";
import { syncedDataKeys } from "../const";

const useSyncedDataDelete = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: deleteSyncedData,
    onSuccess: () => {
      // Can't invalidate per detail cuz don't know to which finance model it relates
      qc.invalidateQueries({ queryKey: syncedDataKeys._def });
    },
  });
};

export { useSyncedDataDelete };
