import { useQuery } from "@tanstack/react-query";

import { financeModelInputKeys } from "../const";
import { fetchInputTabs } from "../api";

interface UseFinanceModelInputTabsParams {
  financeModelId: number;
}

const useFinanceModelInputTabs = ({ financeModelId }: UseFinanceModelInputTabsParams) => {
  return useQuery({
    queryKey: financeModelInputKeys.tabs(financeModelId).queryKey,
    queryFn: () => fetchInputTabs({ financeModelId })
  });
};

export { useFinanceModelInputTabs };
