import { api } from "@/shared/config/network";
import { FinanceModelDto } from "@/entities/FinanceModel";

export const fetchFinanceModelByGridkey = async (
  gridkey: string
): Promise<FinanceModelDto> => {
  const response = await api.get<FinanceModelDto>(
    `/finance-models/gridkey/${gridkey}`
  );
  return response.data;
};
