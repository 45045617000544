import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createInputs } from "../api";
import { financeModelInputKeys } from "../const";

const useFinanceModelInputsCreate = () => {
  const qc = useQueryClient();

  return useMutation({
    mutationFn: createInputs,
    onSuccess: (...[, { financeModelId }]) => {
      [financeModelInputKeys.inputs, financeModelInputKeys.tabs].forEach(
        (keyFn) =>
          qc.invalidateQueries({
            queryKey: keyFn(financeModelId).queryKey,
          })
      );
    },
  });
};

export { useFinanceModelInputsCreate };
