import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FC, useEffect } from "react";
import { Input } from "@/shared/ui/Input/Input";
import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import { useTemplateCreateModal } from "../../hooks";

interface TemplateCreateModalProps {
  open: boolean;
  handleClose: () => void;
  financeModelId: number;
  onSuccess?: (template: any) => void;
  onError?: (error: any) => void;
}

export const TemplateCreateModal: FC<TemplateCreateModalProps> = ({
  open,
  handleClose,
  financeModelId,
  onSuccess,
  onError,
}) => {
  const {
    register,
    handleSubmit,
    errors,
    isSubmitting,
    isValid,
    reset,
    setValue,
  } = useTemplateCreateModal(onSuccess, onError);

  const handleModalClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (financeModelId) {
      setValue("financeModelId", financeModelId);
    }
  }, [financeModelId, setValue]);

  return (
    // TODO: надо будет задать общий layout Для всех модальных окон, стили повторяются.
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(479px, 100%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: "32px",
          borderRadius: "8px",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={"20px"}
        >
          <Typography variant="h6" component="h2">
            Создание шаблона
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          style={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Input
            placeholder="Имя шаблона"
            fullWidth
            {...register("name", { required: "Имя обязательно" })}
            error={!!errors.name}
            helperText={errors.name ? errors.name.message : ""}
          />
          <Input
            placeholder="Описание"
            rows={4}
            multiline
            fullWidth
            {...register("description", { required: "Описание обязательно" })}
            error={!!errors.description}
            helperText={errors.description ? errors.description.message : ""}
          />

          <Box sx={{ visibility: "hidden", height: 0 }}>
            <Input
              type="hidden"
              value={financeModelId}
              {...register("financeModelId", { required: true })}
            />
          </Box>

          <Box mt={2}>
            <Button
              variant="lightBlueV100"
              color="primary"
              type="submit"
              sx={{ mt: 2, width: "100%" }}
              disabled={isSubmitting || !isValid}
            >
              {isSubmitting ? <Spinner withBg={true} /> : "Создать"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};
