import { Avatar, Typography, Box } from "@mui/material";
import { FC } from "react";
import { styled } from "@mui/material/styles";

interface SmallAvatarProps {
  avatarSrc: string;
  fullname?: string;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 32,
  height: 32,
  backgroundColor: theme.palette.customColors.colorLightBlue200,
  "& .MuiAvatar-fallback": {
    margin: 0,
  },
}));

export const SmallAvatar: FC<SmallAvatarProps> = ({ avatarSrc, fullname }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {avatarSrc ? (
        <Avatar
          alt="Owner Avatar"
          src={avatarSrc}
          sx={{
            width: 32,
            height: 32,
          }}
        />
      ) : (
        <StyledAvatar />
      )}
      {fullname && (
        <Typography
          sx={{
            ml: 1,
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "171%",
            color: "#000",
            display: "flex",
            alignItems: "center",
          }}
        >
          {fullname}
        </Typography>
      )}
    </Box>
  );
};
