import { FC, useEffect } from "react";
import { Box, Button, Typography, Modal, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import { TreeItem } from "@mui/x-tree-view/TreeItem";

import Spinner from "@/shared/ui/Spinner/ui/Spinner";
import {
  FinanceModelSelectSheetsPreview,
  ImportFileResponse,
} from "@/entities/FinanceModel";
import { useFinanceModelCreateFromSheets } from "../../hooks/useFinanceModelCreateFromSheets";
import HotTable from "@handsontable/react";
import { convertCellDataToSimpleValue } from "../utils/converter";

interface FinanceModelCreateProps {
  open: boolean;
  handleClose: VoidFunction;

  onSuccess(model: ImportFileResponse): void;

  onError(error: unknown): void;

  initialValues?: FinanceModelSelectSheetsPreview;
}

const StyledHotTable = styled(HotTable)(() => ({
  margin: "10px 0",
}));

const StyledTreeItem = styled(TreeItem)(() => ({
  "& .MuiTreeItem-content.Mui-selected": {
    backgroundColor: "transparent",
  },
}));

const FinanceModelSelectSheetsImport: FC<FinanceModelCreateProps> = ({
  open,
  handleClose,
  onSuccess,
  onError,
  initialValues,
}) => {
  const { handleSubmit, isSubmitting, isValid, watch, reset, setValue } =
    useFinanceModelCreateFromSheets(onSuccess, onError);

  const selectedSheetIds = watch("sheetIds");

  const handleModalClose = () => {
    reset();
    handleClose();
  };

  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          maxHeight: "80vh",
          overflowX: "auto",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "fit-content",
          minWidth: "min(50%, 100%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={"20px"}
        >
          <Typography variant="h6" component="h2">
            Выберите листы для импорта
          </Typography>
          <IconButton onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          style={{ display: "flex", flexDirection: "column", gap: "8px" }}
        >
          <Box
            display="flex"
            gap="36px"
            justifyContent="space-between"
            marginBottom={"20px"}
          >
            {initialValues?.sheets && (
              <SimpleTreeView
                sx={{
                  width: "100%",
                }}
                defaultSelectedItems={initialValues.sheetIds}
                onSelectedItemsChange={(_, sheetIds) =>
                  setValue("sheetIds", sheetIds)
                }
                onSelect={console.log}
                multiSelect
                checkboxSelection
              >
                {[...initialValues.sheets]?.map((sheet) => (
                  <StyledTreeItem
                    key={sheet.index}
                    itemId={sheet.index}
                    label={sheet.name}
                  >
                    <StyledHotTable
                      data={convertCellDataToSimpleValue(sheet.celldata)}
                      height="auto"
                      width="auto"
                      autoWrapRow
                      autoWrapCol
                      autoColumnSize
                      stretchH="none"
                      licenseKey="non-commercial-and-evaluation"
                      disableVisualSelection
                      readOnly
                    />
                  </StyledTreeItem>
                ))}
              </SimpleTreeView>
            )}
          </Box>
          <Box mt={2}>
            <Button
              variant="lightBlueV100"
              color="primary"
              type="submit"
              sx={{ mt: 2, width: "200px" }}
              disabled={
                selectedSheetIds?.length === 0 || isSubmitting || !isValid
              }
            >
              {isSubmitting ? <Spinner withBg={true} /> : "Создать"}
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export { FinanceModelSelectSheetsImport };
