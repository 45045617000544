import { useQuery } from "@tanstack/react-query";
import { getScenarios } from "../api";
import { scenarioKeys } from "../const";
import { ScenarioDTO } from "../model";

const useScenarios = () => {
  return useQuery<ScenarioDTO[], Error>({
    queryKey: scenarioKeys.list().queryKey,
    queryFn: getScenarios,
  });
};

export { useScenarios };
