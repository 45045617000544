import { useInfiniteQuery } from "@tanstack/react-query";
import { financeModelKeys } from "../const";
import { fetchFinanceModels } from "../api";
import type { FinanceModelDto } from "../model";

const useFinanceModels = (sortBy: string = "updatedAt") => {
  return useInfiniteQuery<{
    data: FinanceModelDto[];
    currentPage: number;
    totalPages: number;
  }>({
    queryKey: financeModelKeys.list(sortBy).queryKey,
    queryFn: ({ pageParam = 1 }) =>
      fetchFinanceModels(sortBy, pageParam as number),
    getNextPageParam: (lastPage) => {
      const nextPage =
        lastPage.currentPage < lastPage.totalPages
          ? lastPage.currentPage + 1
          : undefined;

      return nextPage;
    },
    initialPageParam: 1,
  });
};

export { useFinanceModels };
