import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  FinanceModelSheetPurpose,
  Sheet,
  SyncResultDto,
  useSyncedDataPersist,
} from "@/entities/SyncedData";
import { useFinanceModelInputsCreate } from "@/entities/FinanceModelInput";
import { financeModelInputKeys } from "@/entities/FinanceModelInput/const";
import { syncedDataKeys } from "../const";
import { AxiosError } from "axios";

interface UseSyncedDataCreateParams {
  financeModelId: number;
}

interface UseSyncedDataCreateMutationParams {
  formInputs?: { sheet: Sheet }[];
  formOutputs?: { sheet: Sheet }[];
  biInputs?: { sheet: Sheet }[];
  biOutputs?: { sheet: Sheet }[];
  biAndFormOutputs?: { sheet: Sheet }[];
}

const useCreateSyncedData = ({ financeModelId }: UseSyncedDataCreateParams) => {
  const qc = useQueryClient();

  const { mutateAsync: createInput } = useFinanceModelInputsCreate();
  const { mutateAsync: createOutput } = useSyncedDataPersist();

  return useMutation({
    mutationFn: async ({
      formInputs,
      formOutputs,
      biOutputs,
      biInputs,
      biAndFormOutputs,
    }: UseSyncedDataCreateMutationParams) => {
      const mapInput = async (
        sheet: Sheet,
        purpose: FinanceModelSheetPurpose
      ): Promise<SyncResultDto> => {
        if (sheet.celldata && sheet.name && sheet.index) {
          try {
            await createInput({
              financeModelId,
              data: sheet.celldata,
              tabName: sheet.name,
              purpose,
            });

            return { sheet: sheet, sheetPurpose: purpose, sheetType: "input" };
          } catch (e) {
            console.error(e);

            return {
              error:
                e instanceof AxiosError
                  ? e.response?.data.message
                  : "Something went wrong with persisting this sheet during saving (backend)",
              sheet: sheet,
              sheetPurpose: purpose,
              sheetType: "input",
            };
          }
        } else {
          console.error(
            "Something went wrong with persisting this sheet",
            sheet
          );

          return {
            error:
              "Something went wrong with persisting this sheet during validation (frontend). Did you load sheet's data?",
            sheet: sheet,
            sheetPurpose: purpose,
            sheetType: "input",
          };
        }
      };

      const mapOutput = async (
        sheet: Sheet,
        purpose: FinanceModelSheetPurpose
      ): Promise<SyncResultDto> => {
        if (sheet.data && sheet.name && sheet.index) {
          try {
            const createdOutput = await createOutput({
              financeModelId,
              data: sheet.data,
              tabName: sheet.name,
              purpose,
            });

            return {
              sheet: sheet,
              sheetPurpose: purpose,
              sheetType: "output",
              tableName: createdOutput.tableName,
            };
          } catch (e) {
            console.error(e);

            return {
              sheet: sheet,
              sheetPurpose: purpose,
              sheetType: "output",
              error:
                e instanceof AxiosError
                  ? e.response?.data.message
                  : "Something went wrong with persisting this sheet during saving (backend)",
            };
          }
        } else {
          console.error(
            "Something went wrong with persisting this sheet",
            sheet
          );

          return {
            sheet: sheet,
            sheetPurpose: purpose,
            sheetType: "output",
            error:
              "Something went wrong with persisting this sheet during validation (frontend). Did you load sheet's data?",
          };
        }
      };

      return await Promise.allSettled([
        ...(formInputs?.map(({ sheet }) => mapInput(sheet, "forms")) ?? []),
        ...(biInputs?.map(({ sheet }) => mapInput(sheet, "bi")) ?? []),
        ...(formOutputs?.map(({ sheet }) => mapOutput(sheet, "forms")) ?? []),
        ...(biOutputs?.map(({ sheet }) => mapOutput(sheet, "bi")) ?? []),
        ...(biAndFormOutputs?.map(({ sheet }) =>
          mapOutput(sheet, "biandforms")
        ) ?? []),
      ]);
    },

    onError: () => {
      qc.invalidateQueries({
        queryKey: [
          syncedDataKeys.details(financeModelId).queryKey,
          financeModelInputKeys.tabs(financeModelId).queryKey,
        ],
      });
    },
  });
};

export { useCreateSyncedData };
